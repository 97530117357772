// The reducer for the act items branch of the global state
// ---------------------------------------------------------------

import { omit } from 'underscore'

import * as actions from '../actions'

const actItems = (actItems, action) => {
  if (actItems === undefined)
    return {}

  switch (action.type) {
    case actions.CHANGE_AUDIT_ACTION_STATUS:
      return {
        ...actItems,
        auditActions: {
          ...actItems.auditActions,
          [action.id]: {
            ...actItems.auditActions[action.id],
            status: action.status,
            changed: true
          }
        }
      }
    case actions.CHANGE_DISPLAYED_ACT_STATUS:
      return {
        ...actItems,
        currentActStatus: action.status,
      }
    case actions.REQUEST_QUOTE:
      return {
        ...actItems,
        quoteModalOpen: true,
      }
    case actions.CANCEL_QUOTE:
      return {
        ...actItems,
        quoteModalOpen: false,
      }
    case actions.UPDATE_QUOTE_REQUEST:
      return {
        ...actItems,
        quoteRequest: action.quoteRequest,
      }
    case actions.CREATE_QUOTE_REQUEST:
      return {
        ...actItems,
        quoteRequestRequested: true,
      }
    case actions.ACK_QUOTE_REQUEST:
      return {
        ...actItems,
        quoteRequestReceived: true,
        quotedOffers: (actItems.quotedOffers || []).concat(action.offerId),
        quoteModalOpen: false
      }
    case actions.QUOTE_REQUEST_VALIDATION_ERROR:
      return {
        ...actItems,
        quoteRequestValidationErrors: action.validationErrors
      }
    case actions.UNSET_QUOTE_REQUEST_VALIDATION_ERRORS:
      return {
        ...omit(actItems, 'quoteRequestValidationErrors'),
      }
    case actions.STORE_OFFER_LINK_WINDOW_REF:
      return {
        ...actItems,
        offerLinkWindowRefs: {
          ...actItems.offerLinkWindowRefs,
          [action.name]: action.ref,
        }
      }
    default:
      return actItems
  }
}

export default actItems
