/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { makeStyles } from 'tss-react/mui'
import { getHeadlineData } from './api'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import BarChartIcon from '@mui/icons-material/BarChart'
import StorageIcon from '@mui/icons-material/Storage'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PublicIcon from '@mui/icons-material/Public'
import AccountTreeIcon from '@mui/icons-material/AccountTree'

import { Grid, Card, CardContent, Typography } from '@mui/material'

const mapStateToProps = ({ me: { teams } }) => ({
  teams,
})

const useStyles = makeStyles()(theme => ({
  icons: {
    fontSize: theme.spacing(8),
    color: theme.palette.secondary.main,
  },
  grid: {
    display: 'flex',
  },
}))

function HeadlineData(props) {
  const { classes } = useStyles()
  const { dispatch, currentScreen, team } = props

  useEffect(() => {
    if (!team.auditsSummary || !team.projectsSummary || !team.actionsSummary) {
      dispatch(getHeadlineData(team.id))
    }
  }, [team.id])

  return (
    <Card style={{ marginBottom: 12 }}>
      <CardContent>
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">

          {currentScreen === 'audit' && <React.Fragment>
            <Grid item xs className={classes.grid} container direction="column">
              <Typography variant='h6' display='inline' align="center">{team.auditsSummary}</Typography>
              <Grid item align="center">
                <HomeWorkIcon className={classes.icons} />
              </Grid>
              <Typography variant='subtitle2' display='inline' align="center">Profiles</Typography>
            </Grid>
            <Grid item xs className={classes.grid} container direction="column">
              <Typography variant='h6' display='inline' align="center">{team.auditsWithResults}</Typography>
              <Grid item align="center">
                <BarChartIcon className={classes.icons} />
              </Grid>
              <Typography variant='subtitle2' display='inline' align="center">Results</Typography>
            </Grid>
            <Grid item xs className={classes.grid} container direction="column">
              <Typography variant='h6' display='inline' align="center">{team.auditsWithPlans}</Typography>
              <Grid item align="center">
                <StorageIcon className={classes.icons} />
              </Grid>
              <Typography variant='subtitle2' display='inline' align="center">Plans</Typography>
            </Grid>
          </React.Fragment>
          }

          {currentScreen === 'action' && <React.Fragment>
            <Grid item xs className={classes.grid} container direction="column">
              <Typography variant='h6' display='inline' align="center">{team.actionsSummary}</Typography>
              <Grid item align="center">
                <CheckBoxIcon className={classes.icons} />
              </Grid>
              <Typography variant='subtitle2' display='inline' align="center">Actions</Typography>
            </Grid>
            <Grid item xs className={classes.grid} container direction="column">
              <Typography variant='h6' display='inline' align="center">{Math.round(team.totalCarbonSavings / 1000)} tonnes / year</Typography>
              <Grid item align="center">
                <PublicIcon className={classes.icons} />
              </Grid>
              <Typography variant='subtitle2' display='inline' align="center">Carbon Savings</Typography>
            </Grid>
            <Grid item xs className={classes.grid} container direction="column">
              <Typography variant='h6' display='inline' align="center">${team.totalBillSavings || 0} / year</Typography>
              <Grid item align="center">
                <AttachMoneyIcon className={classes.icons} />
              </Grid>
              <Typography variant='subtitle2' display='inline' align="center">Bill Savings</Typography>
            </Grid>
          </React.Fragment>
          }

          {currentScreen === 'project' && <React.Fragment>
            <Grid item xs className={classes.grid} container direction="column">
              <Typography variant='h6' display='inline' align="center">{team.projectsSummary}</Typography>
              <Grid item align="center">
                <AccountTreeIcon className={classes.icons} />
              </Grid>
              <Typography variant='subtitle2' align="center">Projects</Typography>
            </Grid>
          </React.Fragment>
          }
        </Grid>
      </CardContent>
    </Card>
  )
}
export default connect(mapStateToProps)(HeadlineData)
