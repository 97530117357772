import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { withStyles } from 'tss-react/mui'
import CloseIcon from '@mui/icons-material/Close'
import { toggleAuditSelector } from '../../actions'
import AuditSelector from './audit-selector'

import { IconButton } from '@mui/material'
import { withWidth } from '../../styles/with-width'

const styles = (theme) => ({
  selectorContainer: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    },
    transition: theme.transitions.create('all'),
  },
  auditSelectorOpen: {
    marginLeft: 0,
    marginRight: 0,
  },
})

const CollapsibleSelector = (props) => {
  const {
    audit,
    audits,
    auditSelectorOpen,
    dispatch,
    classes,
  } = props

  return (
    <React.Fragment>
      {auditSelectorOpen &&
        <IconButton
          aria-label='Audit Search'
          onClick={() => dispatch(toggleAuditSelector())}
          size="large">

          <CloseIcon />
        </IconButton>
      }

      <div className={classNames(classes.selectorContainer, {
        [classes.auditSelectorOpen]: auditSelectorOpen,
      })}>
        {(auditSelectorOpen) &&
          <AuditSelector
            {...{
              audit,
              audits,
              dispatch,
            }}
            // `key` resets the state when audit changes
            key={audit.code}
          />
        }
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = ({ auditSelectorOpen }) => ({ auditSelectorOpen })
export default withStyles(withWidth()(connect(mapStateToProps)(CollapsibleSelector)), styles)
