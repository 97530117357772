// Redux Action types
// ------------------
export const PROFILE_SAVE_BUTTON_ACTIVE = "PROFILE_SAVE_BUTTON_ACTIVE"
export const TEAM_PROFILE_SAVE_BUTTON_ACTIVE = "TEAM_PROFILE_SAVE_BUTTON_ACTIVE"

export const OPEN_CHECKOUT_DIALOG = "OPEN_CHECKOUT_DIALOG"
export const CLOSE_CHECKOUT_DIALOG = "CLOSE_CHECKOUT_DIALOG"

export const OPEN_ACCOUNT_ALERT_DIALOG = "OPEN_ACCOUNT_ALERT_DIALOG"
export const CLOSE_ACCOUNT_ALERT_DIALOG = "CLOSE_ACCOUNT_ALERT_DIALOG"

export const SELECTED_CREDITS_TO_BUY = "SELECTED_CREDITS_TO_BUY"

export const PROCESS_STRIPE_PAYMENT = "PROCESS_STRIPE_PAYMENT"
export const REQUEST_PROCESS_STRIPE_PAYMENT_ERROR =
  "REQUEST_PROCESS_STRIPE_PAYMENT_ERROR"

export const REQUEST_STRIPE_CHECKOUT_SESSION = "REQUEST_STRIPE_CHECKOUT_SESSION"
export const RECEIVE_STRIPE_CHECKOUT_SESSION = "RECEIVE_STRIPE_CHECKOUT_SESSION"
export const REQUEST_STRIPE_CHECKOUT_SESSION_ERROR = "REQUEST_STRIPE_CHECKOUT_SESSION_ERROR"

export const REQUEST_PASSWORD_RESET_EMAIL = "REQUEST_PASSWORD_RESET_EMAIL"
export const RECEIVE_PASSWORD_RESET_EMAIL_SUCCESS = "RECEIVE_PASSWORD_RESET_EMAIL_SUCCESS"
export const REQUEST_PASSWORD_RESET_EMAIL_ERROR =
  "REQUEST_PASSWORD_RESET_EMAIL_ERROR"

export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS"
export const REQUEST_UPDATE_USER_DETAILS = "REQUEST_UPDATE_USER_DETAILS"
export const RECEIVE_UPDATED_USER_DETAILS = "RECEIVE_UPDATED_USER_DETAILS"
export const REQUEST_UPDATE_USER_DETAILS_ERROR =
  "REQUEST_UPDATE_USER_DETAILS_ERROR"

export const UPDATE_TEAM_DETAILS = "UPDATE_TEAM_DETAILS"
export const REQUEST_UPDATE_TEAM_DETAILS = "REQUEST_UPDATE_TEAM_DETAILS"
export const RECEIVE_UPDATED_TEAM_DETAILS = "RECEIVE_UPDATED_TEAM_DETAILS"
export const REQUEST_UPDATE_TEAM_DETAILS_ERROR =
  "REQUEST_UPDATE_TEAM_DETAILS_ERROR"

export const REQUEST_EDIT_TEAM_MEMBER_DETAILS =
  "REQUEST_EDIT_TEAM_MEMBER_DETAILS"
export const RECEIVE_EDIT_TEAM_MEMBER_DETAILS =
  "RECEIVE_EDIT_TEAM_MEMBER_DETAILS"
export const REQUEST_EDIT_TEAM_MEMBER_DETAILS_ERROR =
  "REQUEST_EDIT_TEAM_MEMBER_DETAILS_ERROR"

export const REQUEST_DELETE_TEAM_MEMBER = "REQUEST_DELETE_TEAM_MEMBER"
export const RECEIVE_DELETE_TEAM_MEMBER = "RECEIVE_DELETE_TEAM_MEMBER"
export const REQUEST_DELETE_TEAM_MEMBER_ERROR =
  "REQUEST_DELETE_TEAM_MEMBER_ERROR"

export const REQUEST_UPLOAD_IMAGE = "REQUEST_UPLOAD_IMAGE"
export const REQUEST_UPLOAD_IMAGE_ERROR = "REQUEST_UPLOAD_IMAGE_ERROR"
export const RECEIVE_UPLOAD_IMAGE_SUCCESS = "RECEIVE_UPLOAD_IMAGE_SUCCESS"

export const REQUEST_UPDATE_REPORT_TEXT = "REQUEST_UPDATE_REPORT_TEXT"
export const RECEIVE_UPDATE_REPORT_TEXT_SUCCESS = "RECEIVE_UPDATE_REPORT_TEXT_SUCCESS"
export const REQUEST_UPDATE_REPORT_TEXT_ERROR = "REQUEST_UPDATE_REPORT_TEXT_ERROR"

export const REQUEST_UPDATE_TEAM_REPORT_OPTIONS = "REQUEST_UPDATE_TEAM_REPORT_OPTIONS"
export const RECEIVE_UPDATE_TEAM_REPORT_OPTIONS = "RECEIVE_UPDATE_TEAM_REPORT_OPTIONS"
export const REQUEST_UPDATE_TEAM_REPORT_OPTIONS_ERROR = "REQUEST_UPDATE_TEAM_REPORT_OPTIONS_ERROR"

export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER"
export const REQUEST_ADD_TEAM_MEMBER = "REQUEST_ADD_TEAM_MEMBER"
export const RECEIVE_ADD_TEAM_MEMBER = "RECEIVE_ADD_TEAM_MEMBER"
export const REQUEST_ADD_TEAM_MEMBER_ERROR = "REQUEST_ADD_TEAM_MEMBER_ERROR"

export const REQUEST_GET_CREDIT_DETAILS = "REQUEST_GET_CREDIT_DETAILS"
export const RECEIVE_GET_CREDIT_DETAILS = "RECEIVE_GET_CREDIT_DETAILS"
export const REQUEST_GET_CREDIT_DETAILS_ERROR = "REQUEST_GET_CREDIT_DETAILS_ERROR"

export const REQUEST_TEAM_DETAILS = "REQUEST_TEAM_DETAILS"
export const RECEIVE_TEAM_DETAILS = "RECEIVE_TEAM_DETAILS"
export const REQUEST_TEAM_DETAILS_ERROR = "REQUEST_TEAM_DETAILS_ERROR"

export const REQUEST_CREATE_TEAM_INVITE = "REQUEST_CREATE_TEAM_INVITE"
export const RECEIVE_CREATE_TEAM_INVITE = "RECEIVE_CREATE_TEAM_INVITE"
export const REQUEST_CREATE_TEAM_INVITE_ERROR = "REQUEST_CREATE_TEAM_INVITE_ERROR"

export const REQUEST_CANCEL_TEAM_INVITE = "REQUEST_CANCEL_TEAM_INVITE"
export const RECEIVE_CANCEL_TEAM_INVITE = "RECEIVE_CANCEL_TEAM_INVITE"
export const REQUEST_CANCEL_TEAM_INVITE_ERROR = "REQUEST_CANCEL_TEAM_INVITE_ERROR"

export const REQUEST_APPLY_TEAM_INVITE = "REQUEST_APPLY_TEAM_INVITE"
export const RECEIVE_APPLY_TEAM_INVITE = "RECEIVE_APPLY_TEAM_INVITE"
export const REQUEST_APPLY_TEAM_INVITE_ERROR = "REQUEST_APPLY_TEAM_INVITE_ERROR"

export const REQUEST_RESEND_TEAM_INVITE = "REQUEST_RESEND_TEAM_INVITE"
export const RECEIVE_RESEND_TEAM_INVITE = "RECEIVE_RESEND_TEAM_INVITE"
export const REQUEST_RESEND_TEAM_INVITE_ERROR = "REQUEST_RESEND_TEAM_INVITE_ERROR"


// Redux Action creators
// ---------------------

export const profileSaveButtonActive = currentStatus => {
  return {
    type: PROFILE_SAVE_BUTTON_ACTIVE,
    currentStatus,
  }
}

export const openCheckoutDialog = () => ({
  type: OPEN_CHECKOUT_DIALOG,
})

export const closeCheckoutDialog = () => ({ type: CLOSE_CHECKOUT_DIALOG })


export const openAccountAlertDialog = (message) => ({
  type: OPEN_ACCOUNT_ALERT_DIALOG,
  message,
})

export const closeAccountAlertDialog = () => ({ type: CLOSE_ACCOUNT_ALERT_DIALOG })

export const selectedCreditsToBuy = creditSelection => {
  return {
    type: SELECTED_CREDITS_TO_BUY,
    creditSelection,
  }
}

// STRIPE PAYMENTS
export const processStripePayment = () => {
  return {
    type: PROCESS_STRIPE_PAYMENT,
  }
}

export const requestProcessStripePaymentError = error => {
  return {
    type: REQUEST_PROCESS_STRIPE_PAYMENT_ERROR,
    error,
  }
}

export const requestStripeCheckoutSession = () => {
  return {
    type: REQUEST_STRIPE_CHECKOUT_SESSION,
  }
}

export const receiveStripeCheckoutSession = (sessionId) => {
  return {
    type: RECEIVE_STRIPE_CHECKOUT_SESSION,
    sessionId,
  }
}

export const requestStripeCheckoutSessionError = error => {
  return {
    type: REQUEST_STRIPE_CHECKOUT_SESSION_ERROR,
    error,
  }
}

// Trigger password reset email to be sent to user
export const requestPasswordResetEmail = () => {
  return {
    type: REQUEST_PASSWORD_RESET_EMAIL,
  }
}

export const receivePasswordResetEmail = () => {
  return {
    type: RECEIVE_PASSWORD_RESET_EMAIL_SUCCESS,
  }
}

export const requestPasswordResetEmailError = error => {
  return {
    type: REQUEST_PASSWORD_RESET_EMAIL_ERROR,
    error,
  }
}

// User details
export const updateUserDetails = userDetails => {
  return {
    type: UPDATE_USER_DETAILS,
    userDetails,
  }
}

export const requestUpdateUserDetails = () => {
  return {
    type: REQUEST_UPDATE_USER_DETAILS,
  }
}

export const receiveUpdatedUserDetails = userDetails => {
  return {
    type: RECEIVE_UPDATED_USER_DETAILS,
    userDetails,
  }
}

export const requestUpdateUserDetailsError = error => {
  return {
    type: REQUEST_UPDATE_USER_DETAILS_ERROR,
    error,
  }
}

// Update team details
export const updateTeamDetails = (teamIndex, teamDetails) => {
  return {
    type: UPDATE_TEAM_DETAILS,
    teamIndex,
    teamDetails,
  }
}

export const requestUpdateTeamDetails = () => {
  return {
    type: REQUEST_UPDATE_TEAM_DETAILS,
  }
}

export const receiveUpdatedTeamDetails = teamDetails => {
  return {
    type: RECEIVE_UPDATED_TEAM_DETAILS,
    teamDetails,
  }
}

export const requestUpdateTeamDetailsError = error => {
  return {
    type: REQUEST_UPDATE_TEAM_DETAILS_ERROR,
    error,
  }
}

// Images
export const requestUploadImage = (imageTarget) => {
  return {
    type: REQUEST_UPLOAD_IMAGE,
    imageTarget,
  }
}

export const requestUploadImageError = (error, image) => {
  return {
    type: REQUEST_UPLOAD_IMAGE_ERROR,
    error,
    image,
  }
}

export const receiveUploadImageSuccess = (data, teamId, image) => {
  return {
    type: RECEIVE_UPLOAD_IMAGE_SUCCESS,
    data,
    teamId,
    image,
  }
}

//Report text
export const requestUpdateReportText = (newText) => {
  return {
    type: REQUEST_UPDATE_REPORT_TEXT,
    newText,
  }
}

export const requestUpdateReportTextError = (error) => {
  return {
    type: REQUEST_UPDATE_REPORT_TEXT_ERROR,
    error,
  }
}

export const receiveUpdateReportText = (reportIntro, reportTrailing, teamId) => {
  return {
    type: RECEIVE_UPDATE_REPORT_TEXT_SUCCESS,
    reportIntro,
    reportTrailing,
    teamId,
  }
}


//Report render options
export const requestUpdateTeamReportOptions = (values) => {
  return {
    type: REQUEST_UPDATE_TEAM_REPORT_OPTIONS,
    values,
  }
}

export const requestUpdateTeamReportOptionsError = (error) => {
  return {
    type: REQUEST_UPDATE_TEAM_REPORT_OPTIONS_ERROR,
    error,
  }
}

export const receiveUpdateTeamReportOptions = (values, showOffers, showSavingsPayback, reportDefaultType, teamId) => {
  return {
    type: RECEIVE_UPDATE_TEAM_REPORT_OPTIONS,
    values,
    showOffers,
    showSavingsPayback,
    reportDefaultType,
    teamId,
  }
}

// Add Team Member
export const addTeamMember = newMemberDetails => {
  return {
    type: ADD_TEAM_MEMBER,
    newMemberDetails,
  }
}

export const requestAddTeamMember = () => {
  return {
    type: "REQUEST_ADD_TEAM_MEMBER",
  }
}

export const receiveAddTeamMember = (newMemberId, username, role, email, teamId) => {
  return {
    type: RECEIVE_ADD_TEAM_MEMBER,
    newMemberId,
    username,
    role,
    email,
    teamId,
  }
}

export const requestAddTeamMemberError = error => {
  return {
    type: REQUEST_ADD_TEAM_MEMBER_ERROR,
    error,
  }
}

// Edit Team Member
export const requestEditTeamMemberDetails = () => {
  return {
    type: REQUEST_EDIT_TEAM_MEMBER_DETAILS,
  }
}

export const receiveEditTeamMemberDetails = (userId, roleValue, teamId) => {
  return {
    type: RECEIVE_EDIT_TEAM_MEMBER_DETAILS,
    userId,
    roleValue,
    teamId,
  }
}

export const requestEditTeamMemberDetailsError = error => {
  return {
    type: REQUEST_EDIT_TEAM_MEMBER_DETAILS_ERROR,
    error,
  }
}

// Delete Team Member
export const requestDeleteTeamMember = () => {
  return {
    type: REQUEST_DELETE_TEAM_MEMBER,
  }
}

export const receiveDeleteTeamMember = (userId, teamId) => {
  return {
    type: RECEIVE_DELETE_TEAM_MEMBER,
    userId,
    teamId,
  }
}

export const requestDeleteTeamMemberError = error => {
  return {
    type: REQUEST_DELETE_TEAM_MEMBER_ERROR,
    error,
  }
}

// Team Invites

// Create Invite
export const requestCreateTeamInvite = () => {
  return {
    type: REQUEST_CREATE_TEAM_INVITE,
  }
}

export const receiveCreateTeamInvite = (newMemberId, username, role, email, teamId) => {
  return {
    type: RECEIVE_CREATE_TEAM_INVITE,
    newMemberId,
    username,
    role,
    email,
    teamId,
  }
}

export const requestCreateTeamInviteError = error => {
  return {
    type: REQUEST_CREATE_TEAM_INVITE_ERROR,
    error,
  }
}

// CANCEL Invite
export const requestCancelTeamInvite = (code) => {
  return {
    type: REQUEST_CANCEL_TEAM_INVITE,
    code,
  }
}

export const receiveCancelTeamInvite = (code, teamId) => {
  return {
    type: RECEIVE_CANCEL_TEAM_INVITE,
    code,
    teamId,
  }
}

export const requestCancelTeamInviteError = error => {
  return {
    type: REQUEST_CANCEL_TEAM_INVITE_ERROR,
    error,
  }
}

// Apply Invite
export const requestApplyTeamInvite = (code) => {
  return {
    type: REQUEST_APPLY_TEAM_INVITE,
    code,
  }
}

export const receiveApplyTeamInvite = (code, teamId) => {
  return {
    type: RECEIVE_APPLY_TEAM_INVITE,
    code,
    teamId,
  }
}

export const requestApplyTeamInviteError = error => {
  return {
    type: REQUEST_APPLY_TEAM_INVITE_ERROR,
    error,
  }
}

// Resend Invite
export const requestResendTeamInvite = (teamId, code) => {
  return {
    type: REQUEST_RESEND_TEAM_INVITE,
    code,
  }
}

export const receiveResendTeamInvite = (code, teamId) => {
  return {
    type: RECEIVE_RESEND_TEAM_INVITE,
    code,
    teamId,
  }
}

export const requestResendTeamInviteError = error => {
  return {
    type: REQUEST_RESEND_TEAM_INVITE_ERROR,
    error,
  }
}

// Credit details
export const requestGetCreditDetails = () => {
  return {
    type: REQUEST_GET_CREDIT_DETAILS,
  }
}

export const receiveGetCreditDetails = (teamId, data) => {
  return {
    type: RECEIVE_GET_CREDIT_DETAILS,
    teamId,
    data,
  }
}

export const requestGetCreditDetailsError = error => {
  return {
    type: REQUEST_GET_CREDIT_DETAILS_ERROR,
    error,
  }
}


// Team details
export const requestTeamDetails = () => {
  return {
    type: REQUEST_TEAM_DETAILS,
  }
}

export const receiveTeamDetails = (teamId, data) => {
  return {
    type: RECEIVE_TEAM_DETAILS,
    teamId,
    data,
  }
}

export const requestTeamDetailsError = error => {
  return {
    type: REQUEST_TEAM_DETAILS_ERROR,
    error,
  }
}
