import Logger from './logger'

const scriptLoader = scriptUrl => {
  return new Promise((res, rej) => {
    const script = document.createElement('script')
    script.src = scriptUrl
    script.type = 'text/javascript'
    script.async = true
    script.onError = rej
    script.onload = res
    script.addEventListener('error', rej)
    script.addEventListener('load', res)
    document.head.appendChild(script)
  })
}

const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
const url = `https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=${key}&v=3`

const loadGoogleMaps = () => {
  if (window.google && window.google.maps) return

  scriptLoader(url).then(
    () => setTimeout(loadGoogleMaps, 300),
    error => {
      Logger.warn(error)
      setTimeout(loadGoogleMaps, 300)
    }
  )
}

loadGoogleMaps()
