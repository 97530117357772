import { generateAnswerId } from './multiset-sections'
import { isEmpty, isObject, isArray } from 'underscore'
import Logger from '../../common/lib/logger'

// Given the root state tree and a section, return an array of questions
// with applied defaults and answers.
export function getQuestions(state, section) {
  let questions = section.questions.map((questionCode) => {
    return state.questions[questionCode]
  })

  questions = addDisplayedResponses(questions, state)
  questions = resolveDependencies(questions, state)

  return questions
}

// Return only questions for this step which should be rendered.
export function getVisibleQuestions(state, section) {
  const questions = getQuestions(state, section)

  // Multisets only specify dependencies on the first question (`type`), so if we
  // return a filtered array, we'll show the section in spite of unmet dependencies
  if (section.isSectionSet && questions[0] && questions[0].hasUnmetDependency) return []

  return questions.filter((question) => {
    return !question.hasUnmetDependency
  })
}

// Given the normalized question data from the process response as an Array,
// and the state tree, add displayed responses to the questions.
// The displayedResponse property captures the current answer as it will be
// displayed to the user, possibly consisting of multiple values.  This
// property is a result of a merging of the answer and default answer for the
// question.  It is the representation in the DOM of this merged result, and
// may be split amongst several inputs which are all children of the question.
const addDisplayedResponses = (questions, state) => {
  return questions.map((question) => {
    if (state.answers[question.id]) {
      const answer = state.answers[question.id]
      const def = state.defaults[question.id]

      let displayedResponse
      // Display the answer as the displayed response for this question, if one
      // has been recorded.

      const valueIsEmptyObject = isObject(answer.value) && isEmpty(answer.value)

      if (answer.value !== null && !valueIsEmptyObject)
        displayedResponse = answer.value
      // If the answer value is an empty array, this is likely checkbox,
      // so set displayed response to empty array (ie show no answer; see insulation:insulation_type)
      else if (answer.value !== null && valueIsEmptyObject && isArray(answer.value))
        displayedResponse = answer.value
      // If there hasn't yet been any answer recorded for this question,
      // display the default answer as the displayedResponse.
      // First handle empty objects (ie for lighting:fixture_wattages)
      else if (answer.value !== null && valueIsEmptyObject && (def && def.value !== null))
        displayedResponse = def.value
      // Then handle others
      else if (answer.value === null && (def && def.value !== null))
        displayedResponse = def.value
      // If both the answer and the default are null, set the displayed
      // response to an empty string.  This informs React that we want a
      // "controlled" input.
      else
        displayedResponse = ''

      let validationErrors = answer.validationErrors
      return {
        ...question,
        displayedResponse,
        validationErrors
      }
    } else if (state.defaults[question.id] && state.defaults[question.id]['isChildOfMultiset']) {
      // Handle defaulting values for nested multisets
      return {
        ...question,
        displayedResponse: state.defaults[question.id]['value']
      }
    } else {
      return {
        ...question,
        displayedResponse: ''
      }
    }
  })
}

// Given the normalized question data from the process response as an Array,
// with displayed responses, and the state tree, resolve the dependencies.
const resolveDependencies = (questions, state) => {
  return questions.map((question) => {
    if (question.dependencies && question.dependencies.length) {
      for (let dependency of question.dependencies) {
        if (unmetDependency(dependency, question.sectionCode, question.id, state)) {
          return {
            ...question,
            hasUnmetDependency: true
          }
        }
      }
    }

    return question
  })
}

// Is the given dependency unmet?
const unmetDependency = (dependency, sectionCode, id, state) => {
  return !resolveDependency(dependency, sectionCode, id, state)
}

// Resolve the given dependency to a boolean value.
const resolveDependency = (dependency, sectionCode, id, state) => {
  let dependencyId = dependency[0]
  let dependencyOperator = dependency[1]
  let dependencyValue = dependency[2]
  let answer = null

  const is_multiset = state.sections[sectionCode].isSectionSet

  // If the dependency id has no prefix, it refers to the current section.
  if (is_multiset && (dependencyId.match(/^:.*$/))) {
    const array_id = id.split(":")
    if (array_id.length === 3) {
      dependencyId = `${array_id[0]}:${array_id[1]}${dependencyId}`
    } else if (array_id.length === 2) {
      dependencyId = `${array_id[0]}${dependencyId}`
    }
  } else if (dependencyId.match(/^:.*$/)) {
    dependencyId = `${sectionCode}${dependencyId}`
  }

  // Retrieve the answer.
  answer = state.answers[dependencyId]
  // If we don't have any answer data yet, dependency may be unmet.
  if (!answer) return false

  // Use the default value if the answer's value is null.
  const defaultValue = answer.isChildOfMultiset && dependency[0].match(/^:.*$/) ? state.defaults[`${sectionCode}_new${dependency[0]}`].value : state.defaults[dependencyId].value

  let valueToCompare = answer.value === null ? defaultValue : answer.value
  // Evaluate dependency, avoiding eval to prevent security holes.
  switch (dependencyOperator) {
    case '==':
      return valueToCompare === dependencyValue
    case '!=':
      return valueToCompare !== dependencyValue
    case 'in':
      return dependencyValue.includes(valueToCompare)
    case 'contains':
      return valueToCompare.includes(dependencyValue)
    default:
      Logger.warn("Question dependancy operator not found")
  }
}

// Given a state and a multi-set section, return an array of question sets.
export function getMultisetQuestions(state, multisetSection) {
  let sets = []

  let i = 0
  let leadAnswerId = generateAnswerId(
    multisetSection.code,
    i,
    multisetSection.choiceQuestion
  )
  let answerForLeadQuestion = state.answers[leadAnswerId]

  while (answerForLeadQuestion) {
    // Add the set to the question array.
    sets.push(getQuestionSet(multisetSection, i, undefined, state))

    // Get the next answer for the lead question, if it exists.
    i++
    leadAnswerId = generateAnswerId(
      multisetSection.code,
      i,
      multisetSection.choiceQuestion
    )
    answerForLeadQuestion = state.answers[leadAnswerId]
  }

  return {
    sets
  }
}

// Given a state and a multi-set section, return a lookup map of multiset
// questions.  In order to render its child questions, a multiset section
// requires access to the sets of questions, ordered by the value of the
// lead question (type).
export function getMultisetQuestionsByLeadValue(state, multisetSection) {
  let sets = {}

  // The lead question is usually the type question, and consists of the
  // choices that group the answers, which each have a set of level two
  // detail questions.
  const leadQuestionId =
    `${multisetSection.code}:${multisetSection.choiceQuestion}`
  const leadQuestion = {
    ...state.questions[leadQuestionId]
  }

  // Loop through the possible indexes, gathering all sets together by lead
  // question value (type).

  let i = 0
  let leadAnswerId = generateAnswerId(
    multisetSection.code,
    i,
    multisetSection.choiceQuestion
  )
  let answerForLeadQuestion = state.answers[leadAnswerId]

  while (answerForLeadQuestion) {
    // The lead value is generally the value of the `type` question.  This
    // is the unique key for the base questions which will be cloned for
    // each set.
    let leadValue = answerForLeadQuestion.value

    sets[leadValue] = sets[leadValue] || []

    // Insert the array of questions into the lead value's array.
    sets[leadValue].push(getQuestionSet(multisetSection, i, undefined, state))

    // Get the next answer for the lead question, if it exists.
    i++
    leadAnswerId = generateAnswerId(
      multisetSection.code,
      i,
      leadQuestion.code
    )
    answerForLeadQuestion = state.answers[leadAnswerId]
  }

  // The displayed response for the lead question has to be gathered here
  // from the number of sets for each lead value.  There is no default for
  // the lead question.
  leadQuestion.displayedResponse = {}

  for (let leadValue in sets) {
    leadQuestion.displayedResponse[leadValue] = sets[leadValue].length
  }

  return {
    leadQuestion,
    sets
  }
}

// Return a nested array of questions for multisets with a nested data
// structure for the answer.
export function getNestedMultisetQuestions(state, multisetSection) {
  let questionCode = multisetSection.choiceQuestion
  let nestedSets = []
  let i = 0
  let ii = 0
  let answerId = null
  let answer = null

  // A local function to generate answer ids with the local counters, as we
  // iterate through the questions for this multiset section.
  const getAnswerId = () => {
    return generateAnswerId(multisetSection.code, i, questionCode, ii)
  }

  answerId = getAnswerId()
  answer = state.answers[answerId]

  // Iterate through the top level of the nested answers.
  while (answer) {
    let nestedAnswer = answer

    let sets = []

    // Iterate through the second level of the nested answers, gathering each
    // question set and inserting it into the array.
    while (nestedAnswer) {
      sets.push(getQuestionSet(multisetSection, i, ii, state))

      ii++
      questionCode = multisetSection.choiceQuestion

      answerId = getAnswerId()
      nestedAnswer = state.answers[answerId]
    }

    // Insert the sets into the top level array.
    nestedSets.push({
      sets
    })

    i++
    ii = 0
    questionCode = multisetSection.choiceQuestion

    answerId = getAnswerId()
    answer = state.answers[answerId]
  }
  return nestedSets
}

// Get a set of questions for a 2D index of the nested array.
function getQuestionSet(multisetSection, i, ii, state) {
  let questionCode = null

  // Here we loop through the questions in the order they are returned in
  // the process schema response, not the order which is returned in the
  // audit answers response.
  let set = multisetSection.questions.map((questionId) => {
    questionCode = state.questions[questionId].code

    return {
      ...state.questions[questionId],
      id: generateAnswerId(
        multisetSection.code,
        i,
        questionCode,
        ii
      )
    }
  })
  set = addDisplayedResponses(set, state)
  set = resolveDependencies(set, state)

  return set
}
