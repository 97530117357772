import { logrocketIdentify } from '../lib/logrocket-config'
import { auditCodeFromUrl } from './util'

export const segmentIdentify = (me, sub) => {
  const userTraits = me ? {
    name: `${me.firstName} ${me.lastName}`,
    email: me.email,
    user_id: sub,
    professional: me.professional,
    firstName: me.firstName,
    lastName: me.lastName,
    unsubscribed: me.professional,
  } : {}
  if (window.analytics) {
    window.analytics.identify(sub, userTraits)
  }
}

const getSegmentEventData = (eventData, isPro) => {
  const env = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV
  const auditHash = auditCodeFromUrl(window.location)
  const updatedEventData = Object.assign({}, eventData, {
    environment: env,
    isPro: isPro,
    auditHash: auditHash ? auditHash : eventData.auditHash,
  })
  return updatedEventData
}

export const getAnonymousIdFromParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const analyticsParam = urlParams.get("_ga")
  return analyticsParam?.split(/-(.+)/)[1]
}

export const overrideSegmentAnonymousId = () => {
  if (window.analytics) {
    const anonId = getAnonymousIdFromParams()
    if (anonId) {
      window.analytics.setAnonymousId(anonId)
    }
  }
}

export const segmentEvent = (eventName, eventData = {}) => {
  return (dispatch, getState) => {
    const isPro = getState().me?.professional
    if (window.analytics) window.analytics.track(eventName, getSegmentEventData(eventData, isPro))
  }
}

export const segmentPageEvent = (pagePath, eventData = {}) => {
  return (dispatch, getState) => {
    const isPro = getState().me?.professional
    const pageProperties = getSegmentEventData(eventData, isPro)
    if (window.analytics) {
      window.analytics.page({
        name: pagePath,
        ...pageProperties
      })
    }
  }
}

export const identifyUserToAddons = (me) => {
  return (dispatch, getState) => {
    const sub = getState().sub
    if (me) {
      logrocketIdentify(me)
      segmentIdentify(me, sub)
    }
  }
}
