export const AssignAudit = /* GraphQL */ `
  mutation AssignAudit($auditCode: String!, $ownerId: Int!) {
    assignAudit(auditCode: $auditCode, ownerId: $ownerId) {
      result {
        __typename
        ... on Success {
          id
        }
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`
