export const GetCheckoutSession = /* GraphQL */ `
  query GetCheckoutSession($teamId: Int!, $productSku: String!, $returnToUrl: String!) {
    team(teamId: $teamId) {
      id
      newCheckoutSessionId(productSku: $productSku, returnToUrl: $returnToUrl)
    }
}
`

export const GetCreditDetails = /* GraphQL */ `
  query GetCreditDetails {
    creditProducts {
      id
      sku
      name
      displayName
      price
    }
    me {
      id
      teams {
        id
        availableCredits
        creditSummary {
          date
          count
        }
      }
    }
  }
`

export const GetTeamDetails = /* GraphQL */ `
  query GetTeamDetails($teamId: Int!, $expired: Boolean, $accepted: Boolean, $declined: Boolean, $cancelled: Boolean) {
    team(teamId: $teamId) {
      logo
      myRole
      reportHeader
      reportFooter
      reportHero
      reportIntro
      reportTrailing
      reportSections
      reportIncludeOffers
      reportIncludeSavingsPayback
      reportDefaultType
      credentials {
        name
        accessKey
        created
      }
      invites(expired: $expired, accepted: $accepted, declined: $declined, cancelled: $cancelled) {
        code
        email
        state
        role
        expires
      }
      labels {
        audit {
          id
          name
          colour
          group
          order
        }
        auditAction {
          id
          name
          colour
          group
          order
        }
        quoteRequest {
          id
          name
          colour
          group
          order
        }
      }
      members {
        userId
        username
        firstName
        lastName
        role
      }
    }
  }
`
