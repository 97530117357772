export const REQUEST_HEADLINE_DATA = "REQUEST_HEADLINE_DATA"
export const RECEIVE_HEADLINE_DATA = "RECEIVE_HEADLINE_DATA"
export const REQUEST_HEADLINE_DATA_ERROR = "REQUEST_HEADLINE_DATA_ERROR"
export const CLEAR_HEADLINE_DATA = "CLEAR_HEADLINE_DATA"
export const REQUEST_ACTIONS = "REQUEST_ACTIONS"
export const RECEIVE_ACTIONS = "RECEIVE_ACTIONS"
export const REQUEST_ACTIONS_ERROR = "REQUEST_ACTIONS_ERROR"
export const CLEAR_ACTIONS = "CLEAR_ACTIONS"
export const REQUEST_PROJECTS = "REQUEST_PROJECTS"
export const RECEIVE_PROJECTS = "RECEIVE_PROJECTS"
export const REQUEST_PROJECTS_ERROR = "REQUEST_PROJECTS_ERROR"
export const CLEAR_PROJECTS = "CLEAR_PROJECTS"
export const REQUEST_TEAM_DATA = "REQUEST_TEAM_DATA"
export const RECEIVE_TEAM_DATA = "RECEIVE_TEAM_DATA"
export const REQUEST_TEAM_DATA_ERROR = "REQUEST_TEAM_DATA_ERROR"
export const REQUEST_AUDITS = "REQUEST_AUDITS"
export const RECEIVE_AUDITS = "RECEIVE_AUDITS"
export const RECEIVE_AUDITS_FOR_MAP = "RECEIVE_AUDITS_FOR_MAP"
export const REQUEST_AUDITS_ERROR = "REQUEST_AUDITS_ERROR"
export const CLEAR_AUDITS = "CLEAR_AUDITS"
export const REQUEST_MAP_DATA = "REQUEST_MAP_DATA"
export const REQUEST_MAP_DATA_ERROR = "REQUEST_MAP_DATA_ERROR"

export const REQUEST_TEAM_FILTER_OPTIONS = "REQUEST_TEAM_FILTER_OPTIONS"
export const RECEIVE_TEAM_FILTER_OPTIONS = "RECEIVE_TEAM_FILTER_OPTIONS"
export const REQUEST_TEAM_FILTER_OPTIONS_ERROR = "REQUEST_TEAM_FILTER_OPTIONS_ERROR"

export const REQUEST_LIST_REPORT = "REQUEST_LIST_REPORT"
export const RECEIVE_LIST_REPORT = "RECEIVE_LIST_REPORT"
export const REQUEST_LIST_REPORT_ERROR = "REQUEST_LIST_REPORT_ERROR"

export const UPDATE_FILTER_LIST = "UPDATE_FILTER_LIST"
export const CLEAR_FILTER_LIST = "CLEAR_FILTER_LIST"

export const CLEAR_TABLE_STATE = "CLEAR_TABLE_STATE"

export const REQUEST_APPLY_LABEL = 'REQUEST_APPLY_LABEL'
export const RECEIVE_APPLY_LABEL = 'RECEIVE_APPLY_LABEL'
export const REQUEST_APPLY_LABEL_ERROR = 'REQUEST_APPLY_LABEL_ERROR'

export const REQUEST_DELETE_LABEL = 'REQUEST_DELETE_LABEL'
export const RECEIVE_DELETE_LABEL = 'RECEIVE_DELETE_LABEL'
export const REQUEST_DELETE_LABEL_ERROR = 'REQUEST_DELETE_LABEL_ERROR'

export const OPEN_LIST_LABEL_DIALOG = "OPEN_LIST_LABEL_DIALOG"
export const CLOSE_LIST_LABEL_DIALOG = "CLOSE_LIST_LABEL_DIALOG"


export const clearTableState = () => ({ type: CLEAR_TABLE_STATE })

export const updateDisplayFilterList = (filterList, list) => ({
  type: UPDATE_FILTER_LIST,
  filterList,
  list
})
export const clearDisplayFilterList = () => ({ type: CLEAR_FILTER_LIST })

export const requestTeamFilterOptions = () => ({ type: REQUEST_TEAM_FILTER_OPTIONS })
export const receiveTeamFilterOptions = (filterOptions, teamId) => ({
  type: RECEIVE_TEAM_FILTER_OPTIONS,
  filterOptions,
  teamId
})
export const requestTeamFilterOptionsError = error => ({
  type: REQUEST_TEAM_FILTER_OPTIONS_ERROR,
  error
})


export const requestHeadlineData = () => ({ type: REQUEST_HEADLINE_DATA })
export const receiveHeadlineData = (headlineData, teamId) => ({
  type: RECEIVE_HEADLINE_DATA,
  headlineData,
  teamId
})
export const requestHeadlineDataError = error => ({
  type: REQUEST_HEADLINE_DATA_ERROR,
  error
})
export const clearHeadlineData = () => ({ type: CLEAR_HEADLINE_DATA })

export const requestMapData = (map_type) => ({
  type: REQUEST_MAP_DATA,
  map_type
})

export const requestMapDataError = (error, map_type) => ({
  type: REQUEST_MAP_DATA_ERROR,
  error,
  map_type
})

export const receiveAuditsForMap = (audits) => ({
  type: RECEIVE_AUDITS_FOR_MAP,
  audits,
})

export const requestActions = () => ({ type: REQUEST_ACTIONS })
export const receiveActions = actions => ({
  type: RECEIVE_ACTIONS,
  actions
})
export const requestActionsError = error => ({
  type: REQUEST_ACTIONS_ERROR,
  error
})
export const clearActions = () => ({ type: CLEAR_ACTIONS })

export const requestProjects = () => ({ type: REQUEST_PROJECTS })
export const receiveProjects = projects => ({
  type: RECEIVE_PROJECTS,
  projects
})
export const requestProjectsError = error => ({
  type: REQUEST_PROJECTS_ERROR,
  error
})
export const clearProjects = () => ({ type: CLEAR_PROJECTS })

export const requestTeamData = () => ({ type: REQUEST_TEAM_DATA })
export const receiveTeamData = teams => ({
  type: RECEIVE_TEAM_DATA,
  teams
})
export const requestTeamDataError = error => ({
  type: REQUEST_TEAM_DATA_ERROR,
  error
})

export const requestListReport = (list_type) => ({ type: REQUEST_LIST_REPORT, list_type })
export const receiveListReport = (list_type) => ({
  type: RECEIVE_LIST_REPORT,
  list_type,
})
export const requestListReportError = (list_type, error) => ({
  type: REQUEST_LIST_REPORT_ERROR,
  list_type,
  error
})

export const requestAudits = () => ({ type: REQUEST_AUDITS })

export const receiveAudits = (teamId, audits, totalCount, pageStart, pageSize) => ({
  type: RECEIVE_AUDITS,
  teamId,
  audits,
  totalCount,
  pageStart,
  pageSize,
})
export const requestAuditsError = error => ({
  type: REQUEST_AUDITS_ERROR,
  error
})
export const clearAudits = () => ({ type: CLEAR_AUDITS })

export const openListLabelDialog = (contentType, id) => ({
  type: OPEN_LIST_LABEL_DIALOG,
  contentType,
  id
})

export const closeListLabelDialog = () => ({ type: CLOSE_LIST_LABEL_DIALOG })

export const requestApplyLabel = (contentType, labelId, id) => ({ type: REQUEST_APPLY_LABEL, contentType, labelId, id })
export const receiveApplyLabel = (contentType, labelId, id) => ({ type: RECEIVE_APPLY_LABEL, contentType, labelId, id })
export const requestApplyLabelError = (contentType, labelId, id, error) => ({
  type: REQUEST_APPLY_LABEL_ERROR,
  contentType,
  labelId,
  id,
  error,
})

export const requestDeleteLabel = (contentType, labelId, id) => ({ type: REQUEST_DELETE_LABEL, contentType, labelId, id })
export const receiveDeleteLabel = (contentType, labelId, id) => ({ type: RECEIVE_DELETE_LABEL, contentType, labelId, id })
export const requestDeleteLabelError = (contentType, labelId, id, error) => ({
  type: REQUEST_DELETE_LABEL_ERROR,
  contentType,
  labelId,
  id,
  error,
})
