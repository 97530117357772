import React from 'react'

const EcologicLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230 60" {...props}>
  <path d="m 36.401222,31.84653 q 0,0.1524 -0.1524,2.1336 h -20.6756 q 0.5588,2.54 2.6416,4.0132 2.0828,1.4732 5.1816,1.4732 2.1336,0 3.7592,-0.6096 1.6764,-0.6604 3.0988,-2.032 l 4.2164,4.572 q -3.8608,4.4196 -11.2776,4.4196 -4.6228,0 -8.1788,-1.778 -3.556,-1.8288 -5.4863995,-5.0292 -1.9304,-3.2004 -1.9304,-7.2644 0,-4.0132 1.8796,-7.2136 1.9303995,-3.2512 5.2323995,-5.0292 3.3528,-1.8288 7.4676,-1.8288 4.0132,0 7.2644,1.7272 3.2512,1.7272 5.08,4.9784 1.8796,3.2004 1.8796,7.4676 z m -14.1732,-8.1788 q -2.6924,0 -4.5212,1.524 -1.8288,1.524 -2.2352,4.1656 h 13.462 q -0.4064,-2.5908 -2.2352,-4.1148 -1.8288,-1.5748 -4.4704,-1.5748 z" />
  <path d="m 54.885279,45.81653 q -4.3688,0 -7.874,-1.778 -3.4544,-1.8288 -5.4356,-5.0292 -1.930399,-3.2004 -1.930399,-7.2644 0,-4.064 1.930399,-7.2644 1.9812,-3.2004 5.4356,-4.9784 3.5052,-1.8288 7.874,-1.8288 4.318,0 7.5184,1.8288 3.2512,1.778 4.7244,5.1308 l -6.1468,3.302 q -2.1336,-3.7592 -6.1468,-3.7592 -3.0988,0 -5.1308,2.032 -2.032,2.032 -2.032,5.5372 0,3.5052 2.032,5.5372 2.032,2.032 5.1308,2.032 4.064,0 6.1468,-3.7592 l 6.1468,3.3528 q -1.4732,3.2512 -4.7244,5.08 -3.2004,1.8288 -7.5184,1.8288 z" />
  <path d="m 84.348488,45.81653 q -4.318,0 -7.7724,-1.778 -3.4036,-1.8288 -5.334,-5.0292 -1.9304,-3.2004 -1.9304,-7.2644 0,-4.064 1.9304,-7.2644 1.9304,-3.2004 5.334,-4.9784 3.4544,-1.8288 7.7724,-1.8288 4.318,0 7.7216,1.8288 3.4036,1.778 5.334,4.9784 1.9304,3.2004 1.9304,7.2644 0,4.064 -1.9304,7.2644 -1.9304,3.2004 -5.334,5.0292 -3.4036,1.778 -7.7216,1.778 z m 0,-6.5024 q 3.048,0 4.9784,-2.032 1.9812,-2.0828 1.9812,-5.5372 0,-3.4544 -1.9812,-5.4864 -1.9304,-2.0828 -4.9784,-2.0828 -3.048,0 -5.0292,2.0828 -1.9812,2.032 -1.9812,5.4864 0,3.4544 1.9812,5.5372 1.9812,2.032 5.0292,2.032 z" />
  <path d="m 105.52849,8.08362 h 3.6068 v 37.6936 h -3.6068 z" />
  <path d="m 130.14268,46.03122 q -3.8608,0 -6.9596,-1.7272 -3.0988,-1.778 -4.8768,-4.8768 -1.778,-3.0988 -1.778,-7.0104 0,-3.9116 1.778,-7.0104 1.778,-3.0988 4.8768,-4.826 3.0988,-1.7272 6.9596,-1.7272 3.8608,0 6.9596,1.7272 3.0988,1.7272 4.826,4.826 1.778,3.0988 1.778,7.0104 0,3.9116 -1.778,7.0104 -1.7272,3.0988 -4.826,4.8768 -3.0988,1.7272 -6.9596,1.7272 z m 0,-3.2004 q 2.8448,0 5.08,-1.27 2.286,-1.3208 3.556,-3.7084 1.27,-2.3876 1.27,-5.4356 0,-3.048 -1.27,-5.4356 -1.27,-2.3876 -3.556,-3.6576 -2.2352,-1.3208 -5.08,-1.3208 -2.8448,0 -5.1308,1.3208 -2.2352,1.27 -3.556,3.6576 -1.27,2.3876 -1.27,5.4356 0,3.048 1.27,5.4356 1.3208,2.3876 3.556,3.7084 2.286,1.27 5.1308,1.27 z" />
  <path d="m 175.80949,19.05642 v 23.4696 q 0,6.8072 -3.3528,10.0584 -3.302,3.302 -10.0076,3.302 -3.7084,0 -7.06119,-1.1176 -3.302,-1.0668 -5.3848,-2.9972 l 1.8288,-2.7432 q 1.9304,1.7272 4.6736,2.6924 2.79399,0.9652 5.84199,0.9652 5.08,0 7.4676,-2.3876 2.3876,-2.3368 2.3876,-7.3152 v -3.4036 q -1.6764,2.54 -4.4196,3.8608 -2.6924,1.3208 -5.9944,1.3208 -3.75919,0 -6.85799,-1.6256 -3.048,-1.6764 -4.826,-4.6228 -1.7272,-2.9972 -1.7272,-6.7564 0,-3.7592 1.7272,-6.7056 1.778,-2.9464 4.826,-4.572 3.048,-1.6256 6.85799,-1.6256 3.4036,0 6.1468,1.3716 2.7432,1.3716 4.4196,3.9624 v -5.1308 z m -13.6652,22.5044 q 2.8956,0 5.2324,-1.2192 2.3368,-1.27 3.6068,-3.5052 1.3208,-2.2352 1.3208,-5.08 0,-2.8448 -1.3208,-5.0292 -1.27,-2.2352 -3.6068,-3.4544 -2.286,-1.27 -5.2324,-1.27 -2.8956,0 -5.23239,1.2192 -2.286,1.2192 -3.6068,3.4544 -1.27,2.2352 -1.27,5.08 0,2.8448 1.27,5.08 1.3208,2.2352 3.6068,3.5052 2.33679,1.2192 5.23239,1.2192 z" />
  <path d="m 185.89568,19.05642 h 3.6068 v 26.7208 h -3.6068 z m 1.8288,-5.842 q -1.1176,0 -1.8796,-0.762 -0.762,-0.762 -0.762,-1.8288 0,-1.016 0.762,-1.778 0.762,-0.762 1.8796,-0.762 1.1176,0 1.8796,0.762 0.762,0.7112 0.762,1.7272 0,1.1176 -0.762,1.8796 -0.762,0.762 -1.8796,0.762 z" />
  <path d="m 210.66226,46.03122 q -3.9624,0 -7.112,-1.7272 -3.0988,-1.7272 -4.8768,-4.826 -1.778,-3.1496 -1.778,-7.0612 0,-3.9116 1.778,-7.0104 1.778,-3.0988 4.8768,-4.826 3.1496,-1.7272 7.112,-1.7272 3.4544,0 6.1468,1.3716 2.7432,1.3208 4.318,3.9116 l -2.6924,1.8288 q -1.3208,-1.9812 -3.3528,-2.9464 -2.032,-1.016 -4.4196,-1.016 -2.8956,0 -5.2324,1.3208 -2.286,1.27 -3.6068,3.6576 -1.27,2.3876 -1.27,5.4356 0,3.0988 1.27,5.4864 1.3208,2.3368 3.6068,3.6576 2.3368,1.27 5.2324,1.27 2.3876,0 4.4196,-0.9652 2.032,-0.9652 3.3528,-2.9464 l 2.6924,1.8288 q -1.5748,2.5908 -4.318,3.9624 -2.7432,1.3208 -6.1468,1.3208 z" />
  </svg>
)

export default EcologicLogo
