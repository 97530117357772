import React, { FunctionComponent, ReactNode } from 'react'
import { makeStyles } from 'tss-react/mui'
import { ComparisonCommentaryType, CompositionCommentaryType } from "../types"
import ComparisonText from './comparison-text'
import CompositionText from './composition-text'
import GenericText, { GenericBodyType } from './generic-text'
import SectionTitleBody from './section-title-body'

import { Paper, Grid, Divider } from '@mui/material'

const useStyles = makeStyles()({
  section: {
    padding: 20,
    marginBottom: 20,
  },
  divider: {
    marginLeft: 20,
    marginRight: 20
  }
})

type Props = {
  title: ReactNode
  body: Array<ReactNode>
  pane?: ReactNode
  footer?: ReactNode
  hasSuggestions: boolean
} & (
  | {
      type: 'comparison'
      customBody: ComparisonCommentaryType | null
    }
  | {
      type: 'composition'
      customBody: CompositionCommentaryType | null
    }
  | {
      type: 'generic'
      customBody: GenericBodyType
    }
)

const Section: FunctionComponent<Props> = (props: Props) => {
  const { classes } = useStyles()
  const { title, body, hasSuggestions, type, pane, footer } = props

  const renderCustomBody = () => {
    if (!props.customBody) {
      return null
    }
    switch (type) {
      case 'comparison':
        return <ComparisonText body={props.customBody} />
      case 'composition':
        return <CompositionText body={props.customBody} />
      case 'generic':
        return <GenericText body={props.customBody} />
    }
  }
  return (
    <React.Fragment>
      <Paper>
        <SectionTitleBody
          title={title}
          body={body}
          hasSuggestions={hasSuggestions}
        />
        <Grid container spacing={2} className={classes.section}>
          {pane && (
            <React.Fragment>
              <Grid item xs={12}>
                {pane}
              </Grid>
              {!!props.customBody && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Divider light />
                  </Grid>
                  {renderCustomBody()}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {footer && (
            <Grid item xs={12}>
              {footer}
            </Grid>
          )}
        </Grid>
      </Paper>
    </React.Fragment>
  )
}

export default Section
