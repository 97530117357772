import icon from '../../common/icons/electricity'
import { TemplateType } from "./types"

export const Electricity: TemplateType = {
  icon,
  comparison: {
    title: 'How your electricity consumption compares',
    body: [
      'The chart below provides a comparison of your electricity use to similar properties and an indication of your overall savings potential.',
      'The ‘typical’ property has building features and appliances that are typical to your local area while the ‘efficient’ property has an affordable suite of energy saving measures installed.',
      'Both scenarios have the same building area, occupants and climate as your building to provide a fair comparison.',
    ],
  },
  composition: {
    title: 'How you use electricity',
    body: [
      'The chart below provides a breakdown of how you currently use electricity in terms of individual appliances.',
      'The larger slices represent the appliances that cost the most energy and money for your unique situation.',
      'The larger slices can also represent your best opportunity for reducing your bill.',
    ],
  },
  units: 'kWh',
  period: 'day',
  multiplier: 1,
}
