import * as api from "../common/lib/api-service"
import * as actions from "./actions"
import {
  GetProjects,
  GetActions,
  GetHeadlineData,
  GetAuditsForMap,
  GetAudits,
  GetTeams,
  GetAuditsReport,
  GetProjectsReport,
  GetActionsReport
} from "./queries"
import * as mutations from "./mutations"
import Logger from '../common/lib/logger'
import { fetchAuditDetails } from '../common/lib/api-service'
import { startNewAudit } from '../audit/lib/api-service'
import { enhanceAuditsForMap } from '../audit/lib/enhance-app-data'
import { auditCodeFromUrl, isProSupplierSignup } from "../common/lib/util"

export const getTeamsData = () => {
  return (dispatch, getState) => {
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: GetTeams })
    })

    dispatch(actions.requestTeamData())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then(response => response.json())
      .then(json => {
        dispatch(actions.receiveTeamData(json.data.me.teams))
      })
      .catch(
        api.catchApiErrorWith(error => {
          dispatch(actions.requestTeamDataError(error.message))
        })
      )
  }
}

export const getProjects = teamId => {
  return (dispatch, getState) => {
    const variables = { teamId }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: GetProjects, variables })
    })

    dispatch(actions.requestProjects())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then(response => response.json())
      .then(json => {
        dispatch(actions.receiveProjects(json))
      })
      .catch(
        api.catchApiErrorWith(error => {
          dispatch(actions.requestProjectsError(error.message))
        })
      )
  }
}

export const getActions = teamId => {
  return (dispatch, getState) => {
    const variables = { teamId }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: GetActions, variables })
    })

    dispatch(actions.requestActions())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then(response => response.json())
      .then(json => {
        dispatch(actions.receiveActions(json))
      })
      .catch(
        api.catchApiErrorWith(error => {
          dispatch(actions.requestActionsError(error.message))
        })
      )
  }
}

export const getAuditsForMap = (search = null) => {
  return async (dispatch, getState) => {
    const limit = 10000
    const variables = { search, limit }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: GetAuditsForMap, variables })
    })

    dispatch(actions.requestMapData("audits"))
    let results = []
    await fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then(response => response.json())
      .then(json => {
        results = enhanceAuditsForMap(json.data.allAudits.results)
        dispatch(actions.receiveAuditsForMap(results))
      })
      .catch(
        api.catchApiErrorWith(error => {
          dispatch(actions.requestMapDataError(error.message, "audits"))
        })
      )
    return results
  }
}

export const getAudits = (teamId = null, history = null, search = null, offset = 0, limit = 10, sortBy) => {
  return (dispatch, getState) => {
    const variables = { search, offset, limit, orderBy: sortBy }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: GetAudits, variables })
    })

    dispatch(actions.requestAudits())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then(response => response.json())
      .then(json => {
        dispatch(actions.receiveAudits(teamId, json.data.allAudits.results, json.data.allAudits.totalCount, json.data.allAudits.offset, json.data.allAudits.limit))
        if (!getState().auditDetailReceived) {
          const firstAudit = json.data.allAudits.results[0]
          let options = {}
          if (isProSupplierSignup()) {
            // We need to create a new audit as the rest of the code relies on having at least one audit,
            // but we don't want to redirect users to the audit/simulation page,
            // so we silently create it in the background
            options = {
              silent: true,
              retryGetAudits: () => dispatch(getAudits(teamId, history, search, offset, limit, sortBy))
            }
          }
          firstAudit
            ? dispatch(fetchAuditDetails(auditCodeFromUrl(window.location) || firstAudit.code))
            : dispatch(startNewAudit(history, null, options))
        }
      })
      .catch(
        api.catchApiErrorWith(error => {
          dispatch(actions.requestAuditsError(error.message))
        })
      )
  }
}

export const getHeadlineData = teamId => {
  return (dispatch, getState) => {
    const variables = { teamId }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: GetHeadlineData, variables })
    })

    dispatch(actions.requestHeadlineData())
    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then(response => response.json())
      .then(json => {
        dispatch(actions.receiveHeadlineData(json.data.teamSummaryData, teamId))
      })
      .catch(
        api.catchApiErrorWith(error => {
          dispatch(actions.requestHeadlineDataError(error.message))
        })
      )
  }
}

export const getAuditsReport = () => {
  return (dispatch, getState) => {
    const teamId = getState().listState.currentTeamId
    const variables = { teamId }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: GetAuditsReport, variables })
    })

    dispatch(actions.requestListReport("audit"))

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then(response => response.json())
      .then(json => {
        window.open(json.data.team.reports.auditsReportUrl)
        dispatch(actions.requestListReport("audit"))

      })
      .catch(
        api.catchApiErrorWith(error => {
          dispatch(actions.requestListReportError("audit", error.message))
        })
      )
  }
}

export const getProjectsReport = teamId => {
  return (dispatch, getState) => {
    const variables = { teamId }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: GetProjectsReport, variables })
    })

    dispatch(actions.requestListReport("projects"))

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then(response => response.json())
      .then(json => {
        window.open(json.data.team.reports.quoteRequestsReportUrl)
        dispatch(actions.requestListReport("projects"))

      })
      .catch(
        api.catchApiErrorWith(error => {
          dispatch(actions.requestListReportError("projects", error.message))
        })
      )
  }
}

export const getActionsReport = () => {
  return (dispatch, getState) => {
    const teamId = getState().listState.currentTeamId
    const variables = { teamId }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: GetActionsReport, variables })
    })

    dispatch(actions.requestListReport("actions"))

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then(response => response.json())
      .then(json => {
        window.open(json.data.team.reports.actionsReportUrl)
        dispatch(actions.requestListReport("actions"))

      })
      .catch(
        api.catchApiErrorWith(error => {
          dispatch(actions.requestListReportError("actions", error.message))
        })
      )
  }
}

export const deleteLabel = (contentType, itemId, labelId) => {
  return (dispatch, getState) => {
    let variables
    let options
    const teamId = getState().listState.currentTeamId
    switch (contentType) {
      case "audit":
        variables = { auditCode: itemId, labelId }
        options = Object.assign({}, api.defaultOptions(getState().token), {
          body: JSON.stringify({ query: mutations.RemoveAuditLabel, variables })
        })
        break
      case "action":
        variables = { teamId, auditActionId: itemId, labelId }
        options = Object.assign({}, api.defaultOptions(getState().token), {
          body: JSON.stringify({ query: mutations.RemoveAuditActionLabel, variables })
        })
        break
      case "project":
        variables = { teamId, quoteRequestId: itemId, labelId }
        options = Object.assign({}, api.defaultOptions(getState().token), {
          body: JSON.stringify({ query: mutations.RemoveQuoteRequestLabel, variables })
        })
        break
      default:
        Logger.warn("DeleteLabel received unknown contentType")
    }

    dispatch(actions.requestDeleteLabel())
    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then(response => response.json())
      .then(json => {
        dispatch(actions.receiveDeleteLabel(contentType, labelId, itemId))
        if (contentType === "audit") {
          dispatch(getAudits(getState().listState.currentTeamId, null, null, getState().listState.auditList.listPageStart, getState().listState.auditList.listPageSize))
        } else if (contentType === "action") {
          dispatch(getActions(getState().listState.currentTeamId))
        } else if (contentType === "project") {
          dispatch(getProjects(getState().listState.currentTeamId))
        }
      })
      .catch(
        api.catchApiErrorWith(error => {
          dispatch(actions.requestDeleteLabelError(error.message))
        })
      )
  }
}

export const applyLabel = (contentType, itemId, labelId) => {
  return (dispatch, getState) => {
    let variables
    let options
    const teamId = getState().listState.currentTeamId
    switch (contentType) {
      case "audit":
        variables = { auditCode: itemId, labelId }
        options = Object.assign({}, api.defaultOptions(getState().token), {
          body: JSON.stringify({ query: mutations.ApplyAuditLabel, variables })
        })
        break
      case "action":
        variables = { teamId, auditActionId: itemId, labelId }
        options = Object.assign({}, api.defaultOptions(getState().token), {
          body: JSON.stringify({ query: mutations.ApplyAuditActionLabel, variables })
        })
        break
      case "project":
        variables = { teamId, quoteRequestId: itemId, labelId }
        options = Object.assign({}, api.defaultOptions(getState().token), {
          body: JSON.stringify({ query: mutations.ApplyQuoteRequestLabel, variables })
        })
        break
      default:
        Logger.warn("applyLabel received unknown content type")
    }

    dispatch(actions.requestApplyLabel(contentType, labelId, itemId))
    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then(response => response.json())
      .then(json => {
        dispatch(actions.receiveApplyLabel(contentType, labelId, itemId))
        if (contentType === "audit") {
          dispatch(getAudits(getState().listState.currentTeamId, null, getState().listState.auditList.listSearchTerm, getState().listState.auditList.listPageStart, getState().listState.auditList.listPageSize))
        } else if (contentType === "action") {
          dispatch(getActions(getState().listState.currentTeamId))
        } else if (contentType === "project") {
          dispatch(getProjects(getState().listState.currentTeamId))
        }
      })
      .catch(
        api.catchApiErrorWith(error => {
          dispatch(actions.requestApplyLabelError(error.message))
        })
      )
  }
}

