// Redux Action types
// ------------------

export const REQUEST_ACT_DATA = 'REQUEST_ACT_DATA'
export const RECEIVE_ACT_DATA = 'RECEIVE_ACT_DATA'
export const PURGE_ACT_DATA = 'PURGE_ACT_DATA'
export const REQUEST_ACT_DATA_ERROR = 'REQUEST_ACT_DATA_ERROR'
export const REQUEST_UPDATE_AUDIT_ACTION_STATUS = 'REQUEST_UPDATE_AUDIT_ACTION_STATUS'
export const RECEIVE_UPDATE_AUDIT_ACTION_STATUS = 'RECEIVE_UPDATE_AUDIT_ACTION_STATUS'
export const REQUEST_UPDATE_AUDIT_ACTION_STATUS_ERROR = 'REQUEST_UPDATE_AUDIT_ACTION_STATUS_ERROR'
export const CHANGE_AUDIT_ACTION_STATUS = 'CHANGE_AUDIT_ACTION_STATUS'
export const CHANGE_DISPLAYED_ACT_STATUS = 'CHANGE_DISPLAYED_ACT_STATUS'
export const REQUEST_QUOTE = 'REQUEST_QUOTE'
export const CANCEL_QUOTE = 'CANCEL_QUOTE'
export const UPDATE_QUOTE_REQUEST = 'UPDATE_QUOTE_REQUEST'
export const CREATE_QUOTE_REQUEST = 'CREATE_QUOTE_REQUEST'
export const ACK_QUOTE_REQUEST = 'ACK_QUOTE_REQUEST'
export const CREATE_QUOTE_REQUEST_ERROR = 'CREATE_QUOTE_REQUEST_ERROR'
export const QUOTE_REQUEST_VALIDATION_ERROR = 'QUOTE_REQUEST_VALIDATION_ERROR'
export const UNSET_QUOTE_REQUEST_VALIDATION_ERRORS = 'UNSET_QUOTE_REQUEST_VALIDATION_ERRORS'
export const REQUEST_OFFER_LINK = 'REQUEST_OFFER_LINK'
export const RECEIVE_OFFER_LINK = 'RECEIVE_OFFER_LINK'
export const REQUEST_OFFER_LINK_ERROR = 'REQUEST_OFFER_LINK_ERROR'
export const STORE_OFFER_LINK_WINDOW_REF = 'STORE_OFFER_LINK_WINDOW_REF'

// Redux Action creators
// ---------------------

export const requestActData = () => {
  return {
    type: REQUEST_ACT_DATA
  }
}

export const receiveActData = (actData) => {
  return {
    type: RECEIVE_ACT_DATA,
    actData
  }
}

export const purgeActData = () => {
  return {
    type: PURGE_ACT_DATA
  }
}

export const requestActDataError = (error) => {
  return {
    type: REQUEST_ACT_DATA_ERROR,
    error
  }
}

export const changeAuditActionStatus = (id, status) => {
  return {
    type: CHANGE_AUDIT_ACTION_STATUS,
    id,
    status
  }
}

export const requestUpdateAuditActionStatus = () => {
  return {
    type: REQUEST_UPDATE_AUDIT_ACTION_STATUS
  }
}

export const receiveUpdateAuditActionStatus = (actData) => {
  return {
    type: RECEIVE_UPDATE_AUDIT_ACTION_STATUS,
    actData
  }
}

export const requestUpdateAuditActionStatusError = (error) => {
  return {
    type: REQUEST_UPDATE_AUDIT_ACTION_STATUS_ERROR,
    error
  }
}

export const changeCurrentActStatus = (status) => {
  return {
    type: CHANGE_DISPLAYED_ACT_STATUS,
    status
  }
}

export const requestQuote = () => {
  return {
    type: REQUEST_QUOTE
  }
}

export const cancelQuote = () => {
  return {
    type: CANCEL_QUOTE
  }
}

export const updateQuoteRequest = (quoteRequest) => {
  return {
    type: UPDATE_QUOTE_REQUEST,
    quoteRequest
  }
}

export const createQuoteRequest = () => {
  return {
    type: CREATE_QUOTE_REQUEST,
  }
}

export const acknowledgeQuoteRequest = (offerId) => {
  return {
    type: ACK_QUOTE_REQUEST,
    offerId
  }
}

export const createQuoteRequestError = (error) => {
  return {
    type: CREATE_QUOTE_REQUEST_ERROR,
    error
  }
}

export const quoteRequestValidationError = (validationErrors) => {
  return {
    type: QUOTE_REQUEST_VALIDATION_ERROR,
    validationErrors
  }
}

export const unsetQuoteRequestValidationErrors = () => {
  return {
    type: UNSET_QUOTE_REQUEST_VALIDATION_ERRORS,
  }
}

export const requestOfferLink = () => ({ type: REQUEST_OFFER_LINK })
export const receiveOfferLink = () => ({ type: RECEIVE_OFFER_LINK })
export const requestOfferLinkError = (error) => ({ type: REQUEST_OFFER_LINK_ERROR, error })

export const storeWindowRef = (name, ref) => {
  return {
    type: STORE_OFFER_LINK_WINDOW_REF,
    name,
    ref,
  }
}
