/* eslint-disable eqeqeq */
import { omit } from 'underscore'
import {
  AuditResultType,
  AuditType,
  Maybe,
  MyTeamType,
  TeamMemberType,
  TeamRoles,
  UserType,
} from '../../__generated__/graphql-v1'

export const enhanceMember = (member: TeamMemberType | UserType) => {
  const { firstName, lastName } = member
  // @ts-expect-error
  const id = member.id || member.userId
  const displayName = [firstName, lastName].join(' ').trim()
  return {
    ...omit(member, 'userId'),
    id,
    displayName,
  }
}

const hasResult = (result?: Maybe<AuditResultType>) => result?.simulation?.status === 'Complete'

export const enhanceTeam = (meId: string, existingData: Record<string, any>, team: MyTeamType) => {
  const teamMembers = (team.members || []) as TeamMemberType[]
  const enhancedTeamMembers = teamMembers.map(enhanceMember)

  let canEdit = false
  if (team.myRole) {
    canEdit = permissionToEditByRole(team.myRole)
  } else if (team.members) {
    canEdit = permissionToEditByMembers(teamMembers, meId)
  }

  const newTeamData = {
    members: enhancedTeamMembers,
    labels: team.labels,
    canEdit,
  }

  if (team.hasOwnProperty('reportSections')) {
    const reportData = {
      ...omit(team, 'reportSections', 'members', 'labels', 'reportDefaultType'),
      reportSections: team.reportSections || [],
      reportDefaultType: team.reportDefaultType?.toLowerCase(),
    }
    return { ...existingData, ...newTeamData, ...reportData }
  }

  return { ...existingData, ...newTeamData }
}

export const enhanceAudits = (allAudits: AuditType[]) => {
  const enhancedAllAudits = allAudits.map((audit) => {
    const { displayName } = enhanceMember(audit.owner as UserType)
    return {
      ...audit,
      ownerDisplay: displayName,
      hasResult: hasResult(audit.result),
    }
  })
  return {
    auditListResults: enhancedAllAudits,
  }
}

export const enhanceAuditsForMap = (allAudits: AuditType[]) => {
  const mapAudits = allAudits ? allAudits.filter((audit) => audit.address) : []
  return mapAudits.map((audit) => {
    return {
      ...audit,
      hasResult: hasResult(audit.result),
    }
  })
}

export const permissionToEditByMembers = (members: TeamMemberType[], id: string) => {
  const myTeamRole = members.find((member) => member.userId == id)
  const role = permissionMapping(myTeamRole && myTeamRole['role'])
  return permissionToEditByRole(role)
}

export const permissionToEditByRole = (role?: string): boolean => {
  switch (role) {
    case 'Founder':
    case 'Admin':
      return true
    default:
      return false
  }
}

export const permissionMapping = (
  permission: string | number | undefined | null
): TeamRoles | undefined => {
  const entry = Object.entries(PermissionRoles).find(([name, role]: [string, number]) => {
    return role.toString() === permission?.toString()
  }) as [TeamRoles, number] | undefined
  return entry ? entry[0] : undefined
}

export const PermissionRoles: Record<TeamRoles, number> = {
  Member: 0,
  Viewer: 10,
  Manager: 20,
  Admin: 30,
  Founder: 40,
}
