import { makeStyles } from 'tss-react/mui'
import { IStyledSetOverlap, VennDiagram } from '@upsetjs/venn.js'
import { select } from 'd3'
import { scaleLinear } from 'd3-scale'
import { FunctionComponent, useEffect } from 'react'
import electricityIcon from '../../common/icons/electricity'
import solarIcon from '../../common/icons/solar'
import { ElectricityBalance } from '../templates/types'
import { Icon } from '../types'
import { chartGreen, chartOrange } from './chart-colors'

type SetType = readonly IStyledSetOverlap[]

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
})

const SolarVenn: FunctionComponent<{
  electricityBalance: ElectricityBalance
}> = ({ electricityBalance }) => {
  const { classes } = useStyles()

  useEffect(() => {
    const { consumed, produced, offsetted } = electricityBalance.composition
    const chart = VennDiagram({
      colorScheme: [chartOrange, chartGreen],
      textFill: '#000',
    })
    const rootSelector = '.solar-venn-chart'
    const labelConsumed = 'Consumed'
    const labelProduced = 'Produced'

    const sets: SetType = [
      { sets: [labelConsumed], size: consumed },
      { sets: [labelProduced], size: produced },
      { sets: [labelConsumed, labelProduced], size: offsetted },
    ]
    const chartContainer = select<HTMLElement, SetType>(rootSelector)
    // Clear out any paths from last time, as the library will throw an error when it tries to transition old content
    chartContainer.selectAll('*').remove()
    chartContainer.datum(sets).call(chart)
    const chartSvg = chartContainer.select('svg')
    const chartBbox = (chartSvg.node() as HTMLElement).getBoundingClientRect()
    const scaler = scaleLinear().domain([0, Math.max(consumed, produced)])

    function drawIcon(label: string, value: number, icon: Icon, baseScale = 1) {
      const group = chartContainer.select(`.venn-circle[data-venn-sets="${label}"]`)
      const circlePath = group.select(`path`)
      if (!circlePath.node()) {
        // Circle doesn't exist, probably because data was 0
        return
      }
      circlePath.style('stroke', 'white')
      circlePath.style('stroke-width', '4px')

      const circleBox = (circlePath.node() as HTMLElement).getBoundingClientRect()
      const scale = baseScale * Math.max(scaler(value) || 0, 0.3)
      const width = icon.width * scale
      const height = icon.height * scale
      const iconX = circleBox.x - chartBbox.x + circleBox.width / 2 - width / 2
      const iconY = circleBox.y - chartBbox.y + circleBox.height / 2 - height / 2

      const path = chartSvg.append('path')
      path.attr('d', icon.path)
      path.attr('width', width)
      path.attr('height', height)
      path.attr('fill', 'white')
      path.style('transform', `translate(${iconX}px, ${iconY}px) scale(${scale})`)
      path.style('fill-opacity', 0.8)
    }

    drawIcon(labelConsumed, consumed, electricityIcon)
    drawIcon(labelProduced, produced, solarIcon, 3.5)
  })

  return <div className={`solar-venn-chart ${classes.container}`} />
}

export default SolarVenn
