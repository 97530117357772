import icon from '../../common/icons/water'
import { TemplateType } from "./types"

export const Water: TemplateType = {
  icon,
  comparison: {
    title: 'How your water consumption compares',
    body: [
      'The chart below provides a comparison of your water usage to an equivalent typical and efficient property.',
    ],
  },
  composition: {
    title: 'How you use water',
    body: [
      'The chart below provides a breakdown of the estimated water usage of your property.',
    ],
  },
  units: 'L',
  period: 'day',
  multiplier: 1,
}
