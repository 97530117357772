export const auditFragment = /* GraphQL */ `
  fragment AuditFragment on Query {
    audit(code: $code) {
      code
      address
      contactDetails {
        name
        firstName
        lastName
        email
        phone
      }
      result {
        id
        simulation {
          status
          messages
        }
        suggestions {
          text
          advice
        }
        goals
        report {
          electricity {
            comparison
            composition
            commentary
          }
          electricityBalance {
            composition
          }
          gas {
            comparison
            composition
            commentary
          }
          greenhouseGas {
            comparison
            composition
            commentary
          }
          bills {
            comparison
            composition
            commentary
          }
          water {
            comparison
            composition
            commentary
          }
          water {
            comparison
            composition
            commentary
          }
        }
      }
      actions {
        cost
        custom
        displayDescription
        displayIcon
        displaySummary
        displayTitle
        electricity
        electricityBill
        gas
        gasBill
        greenhouseGas
        id
        status
        water
        waterBill
        action {
          code
          id
          offers {
            id
            active
            callToAction
            description
            fulfilment
            images
            offerLogo
            price
            priceDisclaimer
            pricePrefix
            priceRange
            priceSuffix
            summary
            title
            supplier {
              description
              id
              name
              website
            }
          }
        }
      }
    }
  }
`
