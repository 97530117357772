import { filter } from 'underscore'
import { hasVisibleQuestions } from './sections'
import { getStartScreenRoutePrefix } from "../../common/lib/util"

// Generate the step URL for the given audit and step.
export const stepUrl = (auditHash, stepCode, knownUser = true) => {
  if (knownUser) return `/audit/${auditHash}/step/${stepCode}/`
  return `/${getStartScreenRoutePrefix()}/step/${stepCode}`
}

export const filterSteps = (state, steps) => filter(steps, step => anySectionsToRender(state, step["code"]))

export const anySectionsToRender = (state, nextStepCode) => {
  const sections = state.steps[nextStepCode].sections
    .map(sectionCode => state.sections[sectionCode])
    .filter(section => hasVisibleQuestions(state, section))
  return sections.length > 0
}

export const getStepCode = (state, currentStepCode, nextOrPrevious = 'next') => {
  const stepDirection = nextOrPrevious === 'next' ? 1 : -1
  const stepOrder = state.steps[currentStepCode].order + stepDirection
  const stepCode = Object.keys(state.steps).find((stepCode) => {
    return state.steps[stepCode].order === stepOrder
  })

  if (!stepCode) return null

  if (anySectionsToRender(state, stepCode)) {
    return stepCode
  } else {
    return getStepCode(state, stepCode, nextOrPrevious)
  }
}
