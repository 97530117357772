import React from "react"
import { Route, Redirect } from 'react-router-dom'

const ProRoute = (props) => {
  const { component: Component, pro, ...rest } = props
  return (
    <Route
      {...rest}
      render={rest => (
        pro ?
          <Component {...rest} /> :
          <Redirect to='/' />
      )}
    />
  )
}

export default ProRoute
