import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { withStyles } from 'tss-react/mui'

// import { urlFromAuditCode } from '../../lib/util'
import { setAudit } from '../../actions'
import { fetchAuditDetails, fetchAuditSuggestions } from '../../lib/api-service'

import { TextField, Paper, MenuItem, Popper } from '@mui/material'

const styles = theme => ({
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    maxHeight: 300,
    overflow: 'scroll',
  },
  suggestionsContainer: {
    zIndex: theme.zIndex.modal,
  }
})

function renderInputComponent(inputProps) {
  const { classes, inputRef, ref, ...other } = inputProps

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node)
          inputRef(node)
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  )
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(getSuggestionValue(suggestion), query)
  const parts = parse(getSuggestionValue(suggestion), matches)
  return (
    (
      <MenuItem selected={isHighlighted} component='div'>
        <div>
          {parts.map((part, index) => {
            const fontWeight = part.highlight ? 500 : 300
            return (
              <span key={String(index)} style={{ fontWeight }}>
                {part.text}
              </span>
            )
          })}
        </div>
      </MenuItem>
    )
  )
}

function getSuggestionValue(suggestion) {
  return suggestion.address || suggestion.contactName
}

class AuditSelector extends React.Component {
  constructor(props) {
    super(props)

    this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this)
    this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this)
    this.setSuggestions = this.setSuggestions.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this)

    this._isMounted = false

    this.state = {
      input: (props.audit && getSuggestionValue(props.audit)) || '',
      suggestions: [],
    }
  }

  componentDidMount() {
    this._isMounted = true
    this._isMounted && this.setSuggestions()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async setSuggestions() {
    const suggestions = await this.props.dispatch(fetchAuditSuggestions())
    this._isMounted && this.setState({
      suggestions: suggestions
    })
  }

  async handleSuggestionsFetchRequested({ value, reason }) {
    if (reason === 'input-focused') return
    let suggestions = []
    if (this.props.auditSuggestionsReceived && value.length > 2)
      suggestions = await this.props.dispatch(fetchAuditSuggestions(value))
    this.setState({
      suggestions: suggestions
    })
  }

  handleSuggestionsClearRequested() {
    this.setSuggestions()
  }

  shouldRenderSuggestions(value) {
    return value.trim().length > 2
  }

  handleChange(event, { newValue }) {
    this.setState({
      input: newValue
    })
  }

  handleSuggestionSelected(event, { suggestion: { code } }) {
    this.props.dispatch(setAudit(code))
    this.props.audits[code] || this.props.dispatch(fetchAuditDetails(code))
    // const path = urlFromAuditCode(code, window.location)
    // TEMP: always go back to dashboard on audit change
    // allows components to re-mount & re-request needed data
    const path = `/#${code}`
    this.props.history.push(path)
  }

  render() {
    const {
      classes,
    } = this.props

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      shouldRenderSuggestions: this.shouldRenderSuggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      onSuggestionSelected: this.handleSuggestionSelected,
      getSuggestionValue,
      renderSuggestion,
    }

    return (
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          placeholder: 'Select Audit',
          value: this.state.input,
          onChange: this.handleChange,
          inputRef: node => {
            this.popperNode = node
          },
        }}
        theme={{
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        shouldRenderSuggestions={() => true}
        renderSuggestionsContainer={options => (
          <Popper
            anchorEl={this.popperNode}
            open={Boolean(options.children)}
            className={classes.suggestionsContainer}
          >
            <Paper
              {...options.containerProps}
              style={{ width: this.popperNode ? this.popperNode.clientWidth : null }}
            >
              {options.children}
            </Paper>
          </Popper>
        )}
      />
    )
  }
}

const mapStateToProps = ({ auditSuggestionsReceived, auditSuggestionsRequested }) => ({
  auditSuggestionsRequested: auditSuggestionsRequested || false,
  auditSuggestionsReceived: auditSuggestionsReceived || true,
})

export default withRouter(withStyles(connect(mapStateToProps)(AuditSelector), styles))
