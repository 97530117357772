import { siteFragment, meFragment, auditFragment } from '../fragments'

export const AuthCheck = /* GraphQL */ `
  query AuthCheck {
    me {
      id
    }
  }
`

export const AppData = /* GraphQL */ `
  ${meFragment}
  ${siteFragment}
  query AppData {
    ...MeFragment
    ...SiteFragment
    messages {
      level
      text
    }
  }
`

export const AuditDetails = /* GraphQL */ `
  ${auditFragment}
  query AuditDetails($code: String) {
    ...AuditFragment
  }
`

export const AuditSuggestions = /* GraphQL */ `
  query GetAudits($search: String, $offset: Int, $limit: Int) {
    allAudits(search: $search, offset: $offset, limit: $limit) {
      totalCount
      offset
      limit
      results {
        code
        address
        contactName
      }
    }
  }
`
