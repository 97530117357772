import React from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import { SnackbarCloseReason } from "@mui/material/Snackbar/Snackbar"
import CloseIcon from '@mui/icons-material/Close'
import { AuditReportType } from "../../../__generated__/graphql-v1"

import PwaInstallPrompt from '../pwa-install-prompt'
import ShareButtons from './buttons'
import { hideShareBar } from '../../actions'
import { Bills, GreenhouseGas } from '../../../results/templates'
import shareableResults from '../../lib/shareable-results'

import { Snackbar, Slide, IconButton } from '@mui/material'
import { StoreType} from "../../lib/store-types"
import { CustomTheme } from "../../styles/ecologic-theme"

const useStyles = makeStyles()(theme => ({
  snackbar: {
    width: '100%',
    maxWidth: (theme as CustomTheme).contentWidth,
  },
  root: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: (theme as CustomTheme).contentWidth,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  message: {
    width: '100%',
    margin: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: 'x-large',
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: -theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    maxWidth: 800,
    margin: 'auto',
    marginBottom: theme.spacing(1),
  },
  installPrompt: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-evenly',
    marginBottom: theme.spacing(3),
  },
  closeSharePanelButton: {
    float: 'right',
  }
}))

const mapStateToProps = (state: StoreType) => ({
  shareBarOpen: state.shareBarOpen,
  audit: (state.audits && state.audits[state.currentAudit]) || {},
  siteName: state.site && state.site.code,
})

export default function ShareBar() {
  const {
    shareBarOpen,
    audit,
    siteName,
  } = useSelector(mapStateToProps)
  const dispatch = useDispatch()
  const { classes } = useStyles()

  if (!shareableResults(audit)) return null

  const { bills, greenhouseGas } = audit.result.report as Record<keyof AuditReportType, any>
  const dollars = (bills.comparison.You - bills.comparison.Efficient) * Bills.multiplier
  const emissions = (greenhouseGas.comparison.You - greenhouseGas.comparison.Efficient) * GreenhouseGas.multiplier

  const closeHandler = (event: unknown, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") return
    dispatch(hideShareBar())
  }

  const url = `https://${siteName || 'www'}.ecologicapp.com/start/`
  const message = `I just found a way to slash my bill by $${dollars} and reduce my carbon footprint by ${Math.round(emissions)} tonnes using this free online tool. Check it out!`

  const shareContent = (
    <>
      <div className={classes.titleRow}>
        <div id="message-id">
          Help spread the word
        </div>
        <div>
          <IconButton
            className={classes.closeSharePanelButton}
            aria-label='Close Share panel'
            onClick={() => dispatch(hideShareBar())}
            color='inherit'
            size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.iconContainer}>
        <ShareButtons url={url} message={message} dispatch={dispatch} />
      </div>
    </>
  )

  const isOnActionPlanPage = /\/act\/plan\/.+/.test(window.location.pathname)

  const content = (
    <>
      <div className={classes.container}>
        {isOnActionPlanPage && (window as unknown as { deferredPrompt?: Event }).deferredPrompt &&
          <PwaInstallPrompt classes={classes} />
        }
        {shareContent}
      </div>
    </>
  )

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      autoHideDuration={15000}
      open={shareBarOpen}
      onClose={closeHandler}
      TransitionComponent={Slide}
      transitionDuration={2000}
      className={classes.snackbar}
      ContentProps={{
        'aria-describedby': 'message-id',
        classes: {
          root: classes.root,
          message: classes.message,
        },
      }}
      message={content}
    />
  )
}
