/* eslint-disable array-callback-return */
import { omit } from 'underscore'

// Generate a patch from the answers state tree.
export function createPatch(answers) {
  const patch = Object.values(answers)
    .filter((answer) => answer.didChange)
    .reduce((patch, answer) => {
      if (answer.isMultiset) {
        patch[answer.sectionCode] = answer.value
      } else if (answer.code) {
        patch[answer.sectionCode] ||= {}
        patch[answer.sectionCode][answer.code] = answer.value
      }
      return patch
    }, {})
  return patch
}


// Given the answers state, return a new subset object with answers prevously
// flagged as changed now marked as unchanged.
export function markAnswersAsUnchanged(answers, patch) {
  const savedAnswerIds = Object.keys(answers).filter((id) => {
    if (answers[id].didChange === true) return true
  })

  let shouldMakeNewPatchRequest = false

  const savedAnswers = {}
  savedAnswerIds.map((id) => {
    const splitId = idToCleanArray(id)
    const shouldChange = isInPatch(patch, splitId)
    if (shouldChange) {
      savedAnswers[id] = {
        ...answers[id],
        didChange: false
      }
    } else {
      // Patch request did not update
      shouldMakeNewPatchRequest = true
    }
  })
  return { savedAnswers, shouldMakeNewPatchRequest }
}

export function idToCleanArray(id) {
  let splitId = id.split(new RegExp(/\[|:/))
  splitId = splitId.filter(item => item).map((item) => item.replace(/\]/g, ''))
  return splitId
}

export function isInPatch(patch, checkPatchFor, level = 0) {
  if (level < checkPatchFor.length - 1) {
    if (!patch.hasOwnProperty(checkPatchFor[level])) return false
    return isInPatch(patch[checkPatchFor[level]], checkPatchFor, (level + 1))
  } else {
    return patch.hasOwnProperty(checkPatchFor[level])
  }
}

export function mergeValidationErrors(options = {}) {
  // The new defaults object
  let updates = {}
  // A list of all the section codes which are keys of validationErrors
  let sectionKeys = Object.keys(options.validationErrors)

  // Iterate through the sections with validationErrors needing an update.
  for (let sectionKey of sectionKeys) {
    let sectionErrors = options.validationErrors[sectionKey]

    // Iterate through all validationErrors needing an update.
    if (sectionErrors instanceof Array) {
      for (const [index, multisetItem] of sectionErrors.entries()) {
        for (let questionKey in multisetItem) {
          let auditKey = `${sectionKey}[${index}]:${questionKey}`
          updates[auditKey] = {
            ...options.audits[auditKey],
            validationErrors: sectionErrors[index][questionKey]
          }
        }
      }
    } else {
      let questionKeys = Object.keys(sectionErrors)
      for (let questionKey of questionKeys) {
        let auditKey = `${sectionKey}:${questionKey}`
        updates[auditKey] = {
          ...options.audits[auditKey],
          validationErrors: sectionErrors[questionKey]
        }
      }
    }
  }

  return {
    ...options.audits,
    ...updates
  }
}

export function removeValidationErrors(answers) {
  const cleanAnswers = omit(answers, 'shouldMakeNewPatchRequest')
  const updatedAnswers = Object.entries(cleanAnswers)
    .map(([answerCode, answer]) => ({ [answerCode]: omit(answer, 'validationErrors') }))
  return Object.assign({}, cleanAnswers, ...updatedAnswers)
}
