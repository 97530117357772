import React, { Component, Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { Auth0Context } from '../../react-auth0-spa'
import CustomThemeProvider from './CustomThemeProvider'
import ServiceWorkerProvider from './service-worker-provider'
import LoadingScreen from './loading-screen'
import VerticalLayout from './VerticalLayout'
import Layout from './Layout'
import Header from './header/header'
import ShareBar from './sharing/share-bar'
import Footer from './footer'
import GlobalMessageDialog from './global-message-dialog'
import AuthenticatedRoutes from './AuthenticatedRoutes'
import PrivateRoute from './PrivateRoute'
import '../styles/global'
import { isHiddenCompletedSurveyPath, isStartAuditPath } from '../lib/util'

const AuditStartScreen = lazy(() => import('../../audit/components/StartScreen'))
const SurveyComplete = lazy(() => import('./SurveyComplete'))
const PrivacyPolicy = lazy(() => import('./privacy-policy'))
const TermsOfService = lazy(() => import('./terms-of-service'))

class App extends Component {
  static contextType = Auth0Context
  render() {
    const { loading, isAuthenticated } = this.context
    const authIsLoaded = isStartAuditPath() || (!loading && isAuthenticated)

    return (
      <CustomThemeProvider>
        <ServiceWorkerProvider />
        <VerticalLayout>
          {authIsLoaded && !isHiddenCompletedSurveyPath() && <Header {...this.props} />}
          <Layout>
            <Suspense fallback={<LoadingScreen isLoaded={false} />}>
              <Switch>
                <Route path="/start:startType*/" component={AuditStartScreen} />
                <Route path="/survey-complete/" component={SurveyComplete} />
                <Route path="/terms-of-service/" component={TermsOfService} />
                <Route path="/privacy-policy/" component={PrivacyPolicy} />
                <PrivateRoute render={(props) => <AuthenticatedRoutes {...this.props} />} />
              </Switch>
            </Suspense>
          </Layout>
          {authIsLoaded && !isHiddenCompletedSurveyPath() && <Footer authIsLoaded={authIsLoaded} />}
          <ShareBar />
        </VerticalLayout>
        <GlobalMessageDialog />
      </CustomThemeProvider>
    )
  }
}

/**
 * @param {StoreType} state
 */
const mapStateToProps = (state) => {
  const me = state.me || {}
  const myTeam = state.myTeam
  const audits = state.audits || {}
  const currentAudit = state.currentAudit
  const audit = (audits && audits[currentAudit]) || {}
  const auditSelectorOpen = state.auditSelectorOpen || false
  const token = state.token || null
  const site = state.site || null
  return {
    me,
    myTeam,
    audits,
    currentAudit,
    audit,
    auditSelectorOpen,
    token,
    site,
  }
}

const ConnectedApp = connect(mapStateToProps)(App)
export { ConnectedApp as App }
export default ConnectedApp
