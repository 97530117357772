import React, { ErrorInfo, FunctionComponent, PropsWithChildren } from "react"
import Logger from '../lib/logger'

import {
  MapsApiLoadError,
} from '../lib/errors'
import { CSSProperties } from 'react'
import { Link } from '@mui/material'

const containerStyle: CSSProperties = {
  padding: '1em',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '1.1rem',
  fontWeight: 400,
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  lineHeight: '1.2em',
}
const headingStyle: CSSProperties = {
  lineHeight: '1.2em',
  fontSize: '2rem',
  fontWeight: 500,
}
const titleStyle: CSSProperties = {
  ...headingStyle,
  fontSize: '3rem',
  textAlign: 'center',
}

const ExternalLink = ({ children, href }: PropsWithChildren<{ href: string }>) => (<Link underline="always" href={href} target="_blank" rel="noopener noreferrer">{children}</Link>)
const RefreshLink = ({ children }: PropsWithChildren<{}>) => (<Link style={{ fontSize: 'inherit' }} component="button" underline="always" onClick={() => window.location.reload()}>{children}</Link>)

class ErrorBoundary extends React.Component<{}, { hasError: boolean; mapsError?: boolean }> {
  constructor(props: {}) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const mapsError = error instanceof MapsApiLoadError
    this.setState({ hasError: true, mapsError })
    if (mapsError) {
      Logger.warn(error.message, { extra: info as Record<string, any> })
    } else {
      Logger.error(error, { extra: info as Record<string, any> })
    }
  }

  render() {
    if (this.state.hasError && process.env.REACT_APP_SHOW_ERROR_SCREEN) {
      let errorCopy = <p>The error has been logged and our staff will see to it shortly.</p>

      if (this.state.mapsError) {
        errorCopy = (
          <p>
            Our external mapping service failed to load. Please check that you don’t have a browser
            extension that blocks{' '}
            <ExternalLink href="https://maps.googleapis.com/">maps.googleapis.com</ExternalLink>
          </p>
        )
      }

      return (
        <div style={containerStyle}>
          <h1 style={titleStyle}>Sorry! :(</h1>
          <h2 style={headingStyle}>Something isn’t quite working as we’d hoped...</h2>

          {errorCopy}
          <ErrorContact />
        </div>
      )
    }
    return this.props.children
  }
}

export const ErrorContact: FunctionComponent = () => {
  return (
    <p>
      <span>You can try <RefreshLink>refreshing the page</RefreshLink> to see if you can continue, but if
      you’re still seeing this message, </span>
      <ExternalLink href="https://www.getecologic.com/contact/">please contact us</ExternalLink>. :)
    </p>
  )
}

export const LoadingError: FunctionComponent = ({ children }) => {
  return (
    <div style={containerStyle}>
      {children || 'There was a problem loading the data for this page.'}
      <ErrorContact />
    </div>
  )
}

export default ErrorBoundary
