export const meFragment = /* GraphQL */ `
  fragment MeFragment on Query {
    me {
      id
      username
      firstName
      lastName
      email
      professional
      teams {
        id
        isSupplier
        isPartner
        myRole
        name
        reportIncludeSavingsPayback
      }
    }
  }
`
