import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

export const logrocketIdentify = (me) => {
  if (process.env.REACT_APP_LOGROCKET_PROJECT && me) LogRocket.identify(me.email, { username: me.username, professional: me.professional })
}

export const logrocketConfig = () => {
  if (process.env.REACT_APP_LOGROCKET_PROJECT) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT, {
      network: {
        requestSanitizer: request => {
          if (request.headers['Authorization'] && request.headers['Authorization'].startsWith("Bearer")) {
            request.headers['Authorization'] = (request.headers['Authorization']).substring(0, 15) + '--sanitised'
          }
          if (request.headers['authorization'] && request.headers['authorization'].startsWith("Bearer")) {
            request.headers['authorization'] = (request.headers['authorization']).substring(0, 15) + '--sanitised'
          }
          if (request.url.toLowerCase().indexOf(process.env.AUTH0_DOMAIN) !== -1) {
            // ignore the request response pair
            return null
          }

          return request
        },
        responseSanitizer: response => {
          if (response.body.toLowerCase().indexOf('reporturl') !== -1) {
            response.body = 'sanitised'
          }
          return response
        },
      },
    })
    setupLogRocketReact(LogRocket)
  }
}

