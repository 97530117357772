import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withStyles } from 'tss-react/mui'
import HomeIcon from '@mui/icons-material/Home'
import EcologicLogo from './ecologic-logo'
import { removeUrlParams } from '../../lib/util'

import { Slide, Button, IconButton, Box } from "@mui/material"

const styles = () => ({
  logo: {
    height: 40,
  },
  ecologicLogo: {
    display: 'block',
    height: 40,
    fill: '#378F57',
  },
})

const ImageLogo = ({ site, classes }) => (
  <Slide direction='right' in={true} mountOnEnter unmountOnExit>
    <Button to='/' component={Link}>
      {site && site.logo
        ? <img src={removeUrlParams(site.logo)} alt={`${site.name} logo`} className={classes.logo} />
        : <EcologicLogo className={classes.ecologicLogo} />
      }
    </Button>
  </Slide>
)

const nonProTarget = auditCode =>
  /^\/start/.test(window.location.pathname)
    ? `/start`
    : `/#${auditCode}`

const LogoContainer = ({ site, currentAudit, pro, teamAuditScreen, classes }) => (
  <React.Fragment>
    {!pro &&
      <Button to={nonProTarget(currentAudit)} component={Link}>
        {site && site.logo
          ? <img src={removeUrlParams(site.logo)} alt={`${site.name} logo`} className={classes.logo} />
          : <EcologicLogo className={classes.ecologicLogo} />
        }
      </Button>
    }
    {pro &&
      <React.Fragment>
        <Box sx={{ display: teamAuditScreen ? 'block' : { xs: 'none', md: 'block' } }}>
          <ImageLogo site={site} classes={classes} />
        </Box>
        {!teamAuditScreen &&
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Slide direction='right' in={true} mountOnEnter unmountOnExit>
              <IconButton to='/' component={Link} size="large">
                <HomeIcon />
              </IconButton>
            </Slide>
          </Box>
        }
      </React.Fragment>
    }
  </React.Fragment>
)

const mapStateToProps = ({ site, currentAudit }) => ({ site, currentAudit })

export default withStyles(connect(mapStateToProps)(LogoContainer), styles)
