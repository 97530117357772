import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from 'tss-react/mui'
import { Typography, Button, Grid } from '@mui/material'

const SHA = (process.env.REACT_APP_SHA && process.env.REACT_APP_SHA.slice(0, 7)) || ''

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
})

const Spacer = () => (
  <Typography variant='button' display='inline'>|</Typography>
)

const Footer = (props) => {
  const { classes } = props

  return (
    <Grid
      container
      justifyContent='center'
      className={classes.container}
    >
      <Grid id="ecologicFooter" item>
        <Button size='small' href='https://www.getecologic.com/' title={`Version #${SHA}`} target='_blank' rel='noopener'>
          Powered by EcoLogic
        </Button>

        <Spacer />

        <Button size='small' component={Link} to='/terms-of-service/'>
          Terms
        </Button>

        <Spacer />

        <Button size='small' component={Link} to='/privacy-policy/'>
          Privacy
        </Button>
      </Grid>
    </Grid>
  )
}

export default withStyles(Footer, styles)
