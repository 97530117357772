import React from "react"
import { connect } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import ErrorIcon from '@mui/icons-material/Error'
import HelpIcon from '@mui/icons-material/Help'
import { openAdviceDialog } from '../../common/actions'

import {
  Grid,
  Typography,
  Divider,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
} from '@mui/material'

const useStyles = makeStyles()(theme => ({
  panelSummary: {
    paddingTop: 10,
  },
  drawerIcon: {
    display: 'block',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}))

const ExpandControl = ({ hasSuggestions, dispatch }) => {
  const { classes } = useStyles()
  const handleAdviceIcon = (event) => {
    event.stopPropagation()
    dispatch(openAdviceDialog())
  }
  return (
    <React.Fragment>
      <Button disableRipple style={{ boxShadow: "none" }}>
        <Icon classes={{ root: classes.drawerIcon }} aria-label="ExpandIcon" color='primary' >
          info
        </Icon>
      </Button>

      <Button disableRipple style={{ boxShadow: "none" }}>
        {hasSuggestions ?
          <ErrorIcon classes={{ root: classes.drawerIcon }}
            color='error'
            aria-label="Warning"
            onClick={handleAdviceIcon}
            onFocus={handleAdviceIcon}
          />
          :
          <HelpIcon classes={{ root: classes.drawerIcon }}
            color='secondary'
            aria-label="Help"
            onClick={handleAdviceIcon}
            onFocus={handleAdviceIcon}
          />
        }
      </Button>
    </React.Fragment>
  )
}

const SectionTitleBody = ({ title, body, hasSuggestions, dispatch }) => {
  const { classes } = useStyles()

  return (
    <Accordion elevation={0} disabled={!body || body.length < 1}>
      <AccordionSummary
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper': {
            transform: "none"
          }
        }}
        classes={{
          expandIcon: classes.expandIcon,
          expanded: classes.expanded,
        }}
        expandIcon={<ExpandControl hasSuggestions={hasSuggestions} dispatch={dispatch} />}
        aria-label="Expand"
        aria-controls="additional-actions3-content"
        id="additional-actions3-header"
      >
        <Typography
          variant='h5'
          gutterBottom
          aria-label="Label"
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {body && body.map((paragraph, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Typography>
                {paragraph}
              </Typography>
            </Grid>
          ))}
          <Divider light />
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default connect()(SectionTitleBody)

