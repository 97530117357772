// Redux Action types
// ------------------
import safeLocalStorage from '../lib/local-storage'

export const DEBUG_COMBINE_STATE = 'DEBUG_COMBINE_STATE'
export const DEBUG_REPLACE_STATE = 'DEBUG_REPLACE_STATE'
export const SW_INIT = 'SW_INIT'
export const SW_UPDATE = 'SW_UPDATE'
export const CLEAR_SW_STATUS = 'CLEAR_SW_STATUS'
export const SET_SW_OFFER_INSTALL = 'SET_SW_OFFER_INSTALL'

export const SAVE_TOKEN_SUB_TO_STATE = 'SAVE_TOKEN_SUB_TO_STATE'
export const REQUEST_TEMPORARY_TOKEN = 'REQUEST_TEMPORARY_TOKEN'
export const REQUEST_TEMPORARY_TOKEN_ERROR = 'REQUEST_TEMPORARY_TOKEN_ERROR'
export const REQUEST_AUTH_CHECK = 'REQUEST_AUTH_CHECK'
export const RECEIVE_AUTH_CHECK = 'RECEIVE_AUTH_CHECK'
export const REQUEST_AUTH_CHECK_ERROR = 'REQUEST_AUTH_CHECK_ERROR'
export const REQUEST_APP_DATA = 'REQUEST_APP_DATA'
export const RECEIVE_APP_DATA = 'RECEIVE_APP_DATA'
export const RECEIVE_TEAM_DATA_V2 = 'RECEIVE_TEAM_DATA_V2'
export const REQUEST_APP_DATA_ERROR = 'REQUEST_APP_DATA_ERROR'
export const REQUEST_AUDIT_DETAILS = 'REQUEST_AUDIT_DETAILS'
export const RECEIVE_AUDIT_DETAILS = 'RECEIVE_AUDIT_DETAILS'
export const REQUEST_AUDIT_DETAILS_ERROR = 'REQUEST_AUDIT_DETAILS_ERROR'
export const SET_AUDIT = 'SET_AUDIT'
export const TOGGLE_MOBILE_NAV = 'TOGGLE_MOBILE_NAV'
export const TOGGLE_TABLET_NAV = 'TOGGLE_TABLET_NAV'
export const TOGGLE_AUDIT_SELECTOR = 'TOGGLE_AUDIT_SELECTOR'
export const SHOW_DIALOG = 'SHOW_DIALOG'
export const HIDE_DIALOG = 'HIDE_DIALOG'
export const OPEN_ADVICE_DIALOG = 'OPEN_ADVICE_DIALOG'
export const CLOSE_ADVICE_DIALOG = 'CLOSE_ADVICE_DIALOG'
export const SHOW_SHARE_BAR = 'SHOW_SHARE_BAR'
export const HIDE_SHARE_BAR = 'HIDE_SHARE_BAR'
export const SHARE_SEEN = 'SHARE_SEEN'
export const DISPLAY_ERROR_MESSAGE = 'DISPLAY_ERROR_MESSAGE'
export const ACKNOWLEDGE_MESSAGES = 'ACKNOWLEDGE_MESSAGES'
export const CHANGE_AUDIT_ACTION_STATUS_IN_AUDITS = 'CHANGE_AUDIT_ACTION_STATUS_IN_AUDITS'
export const REQUEST_AUDIT_SUGGESTIONS = 'REQUEST_AUDIT_SUGGESTIONS'
export const RECEIVE_AUDIT_SUGGESTIONS = 'RECEIVE_AUDIT_SUGGESTIONS'
export const REQUEST_AUDIT_SUGGESTIONS_ERROR = 'REQUEST_AUDIT_SUGGESTIONS_ERROR'

// Redux Action creators
// ---------------------
export const serviceWorkerInit = () => ({
  type: SW_INIT
})
export const serviceWorkerUpdate = (payload) => ({
  type: SW_UPDATE,
  payload
})
export const clearSWStatus = (statusToClear) => ({
  type: CLEAR_SW_STATUS,
  statusToClear
})
export const setSWOfferInstall = (flag) => ({
  type: SET_SW_OFFER_INSTALL,
  flag
})

export const saveTokenSubToState = (token, sub) => ({
  type: SAVE_TOKEN_SUB_TO_STATE,
  token,
  sub,
})

export const requestTemporaryToken = () => ({
  type: REQUEST_TEMPORARY_TOKEN,
})

export const requestTemporaryTokenError = (error, message) => ({
  type: REQUEST_TEMPORARY_TOKEN_ERROR,
  error,
  message
})

export const requestAuthCheck = () => ({
  type: REQUEST_AUTH_CHECK,
})

export const receiveAuthCheck = ({ data }) => ({
  type: RECEIVE_AUTH_CHECK,
  data,
})
export const requestAuthCheckError = (error, message) => ({
  type: REQUEST_AUTH_CHECK_ERROR,
  error,
  message
})

export const requestAppData = () => ({
  type: REQUEST_APP_DATA,
})

export const receiveAppData = ({ data }) => ({
  type: RECEIVE_APP_DATA,
  data,
})

export const receiveTeamDataV2 = ({ data }) => ({
  type: RECEIVE_TEAM_DATA_V2,
  data,
})

export const requestAppDataError = (message) => ({
  type: REQUEST_APP_DATA_ERROR,
  message,
})

export const requestAuditDetails = () => ({
  type: REQUEST_AUDIT_DETAILS,
})

export const receiveAuditDetails = ({ data }) => ({
  type: RECEIVE_AUDIT_DETAILS,
  ...data,
})

export const requestAuditDetailsError = (message) => ({
  type: REQUEST_AUDIT_DETAILS_ERROR,
  message,
})

export const setAudit = hash => {
  return {
    type: SET_AUDIT,
    hash
  }
}

export const toggleMobileNav = () => {
  return {
    type: TOGGLE_MOBILE_NAV,
  }
}

export const toggleTabletNav = () => ({ type: TOGGLE_TABLET_NAV })
export const toggleAuditSelector = () => ({ type: TOGGLE_AUDIT_SELECTOR })

export const showDialog = (id, props) => {
  return {
    type: SHOW_DIALOG,
    id,
    props
  }
}

export const hideDialog = () => {
  return {
    type: HIDE_DIALOG
  }
}

export const showShareBar = () => ({ type: SHOW_SHARE_BAR })
export const hideShareBar = () => ({ type: HIDE_SHARE_BAR })
export const shareSeen = () => {
  safeLocalStorage.setItem('shareSeen', true)
  return { type: SHARE_SEEN }
}

export const acknowledgeMessages = () => ({ type: ACKNOWLEDGE_MESSAGES })

export const displayErrorMessage = (error) => ({ type: DISPLAY_ERROR_MESSAGE, error })

export const changeAuditActionStatusInAudits = (id, status, code) => {
  return {
    type: CHANGE_AUDIT_ACTION_STATUS_IN_AUDITS,
    id,
    status,
    code
  }
}

export const openAdviceDialog = () => ({
  type: OPEN_ADVICE_DIALOG,
})

export const closeAdviceDialog = () => ({ type: CLOSE_ADVICE_DIALOG })

export const requestAuditSuggestions = (value) => ({
  type: REQUEST_AUDIT_SUGGESTIONS,
  value
})

export const receiveAuditSuggestions = (results) => ({
  type: RECEIVE_AUDIT_SUGGESTIONS,
  results,
})

export const requestAuditSuggestionsError = (message) => ({
  type: REQUEST_AUDIT_SUGGESTIONS_ERROR,
  message,
})
