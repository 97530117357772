import icon from '../../common/icons/greenhouse_gas'
import { TemplateType } from "./types"

export const GreenhouseGas: TemplateType = {
  icon,
  comparison: {
    title: 'How your carbon footprint compares',
    body: [
      'The chart below provides a comparison of your greenhouse gas emissions to an equivalent typical and efficient property.',
      'The estimate includes the direct, indirect and embodied emissions associated with your energy, water and waste (where specified)',
      'For a rough benchmark, a sustainable rate of emissions per person is 3 tonnes per year.',
    ],
  },
  composition: {
    title: 'Your carbon footprint breakdown',
    body: [
      'The chart below provides a breakdown of the estimated greenhouse gas emissions of your property.',
      'Each of the emissions sources has been converted to equivalent carbon dioxide warming potential.',
      'This process is used to provide a comparison of the relative impact of different emission sources.',
    ],
  },
  units: 'Tonnes',
  period: 'year',
  multiplier: 0.365,
}
