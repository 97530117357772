import { MutationsAssignAuditArgs, QueryAuditArgs } from "../__generated__/graphql-v1"
import * as api from '../common/lib/api-service'
import { StoreThunkAction } from "../common/lib/store-types"
import {
  requestAssignAudit,
  receiveAssignAudit,
  requestAssignAuditError,
  updateAuditOwner,
  requestAuditPdf,
  receiveAuditPdf,
  requestAuditPdfError,
} from './actions'
import {
  AssignAudit,
} from './mutations'
import {
  GetAuditReport
} from './queries'

export const assignAudit = (auditCode: string, ownerId: number): StoreThunkAction => {
  return (dispatch, getState) => {
    const variables: MutationsAssignAuditArgs = { auditCode, ownerId }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: AssignAudit, variables }),
    })

    dispatch(requestAssignAudit())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then(api.checkGraphqlResult('assignAudit'))
      .then(() => {
        dispatch(receiveAssignAudit())
        dispatch(updateAuditOwner(auditCode, ownerId))
      })
      .catch(api.catchApiErrorWith((error) => {
        dispatch(requestAssignAuditError(error.message))
      }))
  }
}

export const downloadAuditReport = (code: string): StoreThunkAction => {
  return (dispatch, getState) => {
    const variables: QueryAuditArgs = { code }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: GetAuditReport, variables }),
    })

    dispatch(requestAuditPdf(code))

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then(response => response.json())
      .then(json => {
        window.open(json.data.audit.auditReportUrl)
        dispatch(receiveAuditPdf(code))
      })
      .catch(
        api.catchApiErrorWith(error => {
          dispatch(requestAuditPdfError(code, error))
        })
      )
  }
}
