/*eslint no-console: 0 */
import LogRocket from 'logrocket'

const devLogger = {
  debug: console.log.bind(console),
  error: console.error.bind(console),
  info: console.info.bind(console),
  log: console.log.bind(console),
  warn: console.warn.bind(console),
}

const noop = () => { }
const testLogger = {
  debug: noop,
  error: console.error.bind(console),
  info: noop,
  log: noop,
  warn: noop,
}

const Adapter = {
  debug: LogRocket.captureMessage.bind(LogRocket),
  error: LogRocket.captureException.bind(LogRocket),
  info: LogRocket.captureMessage.bind(LogRocket),
  log: LogRocket.captureMessage.bind(LogRocket),
  warn: LogRocket.captureMessage.bind(LogRocket),
}

let Logger: typeof Adapter
if (process.env.REACT_APP_LOGROCKET_PROJECT) {
  Logger = Adapter
} else if (process.env.NODE_ENV === 'test') {
  Logger = testLogger
} else {
  Logger = devLogger
}

export default Logger
