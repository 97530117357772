import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import MenuIcon from '@mui/icons-material/Menu'
import { withStyles } from 'tss-react/mui'

import { toggleMobileNav, toggleTabletNav } from '../actions'
import StepNav from '../../audit/components/StepNav'
import TeamSubNav, { allTeamDataTypes } from '../../team-dashboard/team-sub-nav'

import { Drawer, IconButton, Slide, Divider, Box } from '@mui/material'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  drawer: {
    width: theme.drawerWidth,
    flexShrink: 0,
    zIndex: theme.zIndex.customAuditDrawer,
  },
  drawerOpen: {
    width: theme.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '57px',
    [theme.breakpoints.up('md')]: {
      width: theme.drawerWidth,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    paddingLeft: theme.spacing(0.5),
    display: 'flex',
  },
  content: {
    flex: '1 1 auto',
    padding: theme.spacing(1),
    maxWidth: theme.contentWidth,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})

class Layout extends Component {
  render() {
    const {
      mobileNavOpen,
      tabletNavOpen,
      currentAudit,
      processReceived,
      auditReceived,
      dispatch,
      classes,
      children,
      me,
    } = this.props
    const auditScreen = RegExp('^/(audit/.+|start.*?)/step/').test(window.location.pathname)
    const teamSubnavKeys = allTeamDataTypes.map((item) => item.key)
    const showTeamSubNav =
      me &&
      me.professional &&
      RegExp(`^/team/.+/(${teamSubnavKeys.join('|')})`).test(window.location.pathname)

    return (
      <div className={classes.root}>
        {auditScreen && processReceived && auditReceived && (
          <React.Fragment>
            {/* toggleable mobile nav */}
            <Drawer
              variant="temporary"
              open={mobileNavOpen}
              onClose={() => {
                dispatch(toggleMobileNav())
              }}
            >
              <StepNav auditHash={currentAudit} />
            </Drawer>

            {/* collapsing tablet/desktop nav */}
            <Drawer
              variant="permanent"
              anchor="left"
              sx={{ display: { xs: 'none', sm: 'block' } }}
              className={classNames(classes.drawer, {
                [classes.drawerOpen]: tabletNavOpen,
                [classes.drawerClose]: !tabletNavOpen,
              })}
              classes={{
                paper: classNames({
                  [classes.drawerOpen]: tabletNavOpen,
                  [classes.drawerClose]: !tabletNavOpen,
                }),
              }}
              open={tabletNavOpen}
            >
              <div className={classes.toolbar}>
                <Box sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
                  <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                    <IconButton onClick={() => this.props.dispatch(toggleTabletNav())} size="large">
                      <MenuIcon />
                    </IconButton>
                  </Slide>
                </Box>
              </div>
              <Divider />
              <StepNav auditHash={currentAudit} />
            </Drawer>
          </React.Fragment>
        )}
        {/* The content of the layout */}
        <main className={classes.content}>
          {showTeamSubNav && <TeamSubNav pathname={window.location.pathname} />}
          {children}
        </main>
      </div>
    )
  }
}

/**
 * @param {StoreType} state
 */
const mapStateToProps = ({
  mobileNavOpen,
  tabletNavOpen,
  currentAudit,
  processReceived,
  auditReceived,
  me,
}) => ({
  mobileNavOpen,
  tabletNavOpen,
  currentAudit,
  processReceived,
  auditReceived,
  me,
})

export default withStyles(connect(mapStateToProps)(Layout), styles)
