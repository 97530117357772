// The reducer for the answers branch of the global state
// ------------------------------------------------------

import Logger from '../../common/lib/logger'

import deepEqual from 'deep-equal'

import * as actions from '../actions/index'
import {
  markAnswersAsUnchanged,
  mergeValidationErrors,
  removeValidationErrors,
} from '../lib/answers'
import {
  shiftSetsDownIfNeeded,
  shiftSetsUpIfNeeded,
  parseAnswerId,
} from '../lib/multiset-sections'
import {
  parseWallAnswersFromDefaults,
  defaultsLackPositionData
} from "../lib/normalize"

function answers(state, action) {
  if (state === undefined)
    return {}

  switch (action.type) {
    case actions.CHANGE_ANSWER:
      if (!state[action.questionId]) {
        Logger.warn("Answer doesn't exist yet so cannot be changed", { action })
        return state
      }
      // If the answer's value is deeply equal to the given value,
      // return the same state.
      if (deepEqual(state[action.questionId].value, action.answerValue))
        return state
      else
        return {
          ...state,
          [action.questionId]: {
            ...state[action.questionId],
            value: action.answerValue,
            didChange: true
          }
        }
    case actions.PATCH_AUDIT_SUCCESS: {
      const { savedAnswers, shouldMakeNewPatchRequest } = markAnswersAsUnchanged(state, action.patch)

      return {
        ...state,
        shouldMakeNewPatchRequest: shouldMakeNewPatchRequest,
        ...savedAnswers,
      }
    }
    case actions.ADD_ANSWER: {
      const shiftedState = shiftSetsUpIfNeeded(action.id, state)

      return {
        ...shiftedState,
        [action.id]: {
          id: action.id,
          sectionCode: action.sectionCode,
          questionCode: action.questionCode,
          value: action.value,
          isChildOfMultiset: true
        },
        [action.sectionCode]: {
          ...shiftedState[action.sectionCode],
          multisetAnswers: [
            ...shiftedState[action.sectionCode].multisetAnswers,
            action.id
          ]
        }
      }
    }
    case actions.REMOVE_ANSWER: {
      const sectionCode = parseAnswerId(action.id).sectionCode
      let newMultisetAnswers = [
        ...state[sectionCode].multisetAnswers
      ]
      const indexOfAnswerId = newMultisetAnswers.indexOf(action.id)

      newMultisetAnswers.splice(indexOfAnswerId, 1)

      let newState = {
        ...state,
        [sectionCode]: {
          ...state[sectionCode],
          multisetAnswers: newMultisetAnswers
        }
      }

      delete newState[action.id]

      const shiftedAnswers = shiftSetsDownIfNeeded(action.id, newState)

      return shiftedAnswers
    }
    case actions.SAVE_WALL_DEFAULTS: {
      let newDefaultValue
      if (!action.wallDefaults || defaultsLackPositionData(action.wallDefaults)) {
        newDefaultValue = []
      } else {
        newDefaultValue = action.wallDefaults
      }

      return {
        ...state,
        walls: {
          ...state["walls"],
          defaultValue: newDefaultValue,
        },
      }
    }
    case actions.SET_WALL_ANSWERS_WITH_DEFAULTS: {
      const { newAnswers, multisetAnswers } = parseWallAnswersFromDefaults(state.walls.defaultValue)

      return {
        ...state,
        walls: {
          ...state["walls"],
          value: state.walls.defaultValue,
          multisetAnswers: multisetAnswers,
          didChange: true
        },
        ...newAnswers
      }
    }
    case actions.SET_ANSWER_ERRORS:
      return mergeValidationErrors({
        audits: state,
        validationErrors: action.validationErrors
      })
    case actions.UNSET_ANSWER_ERRORS:
      return removeValidationErrors(state)

    default:
      return state
  }
}

export default answers
