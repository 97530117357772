export const REQUEST_TEAM_CREDENTIALS = "REQUEST_TEAM_CREDENTIALS"
export const RECEIVE_TEAM_CREDENTIALS = "RECEIVE_TEAM_CREDENTIALS"
export const REQUEST_TEAM_CREDENTIALS_ERROR = "REQUEST_TEAM_CREDENTIALS_ERROR"

export const REQUEST_CREATE_TEAM_CREDENTIALS = "REQUEST_CREATE_TEAM_CREDENTIALS"
export const RECEIVE_CREATE_TEAM_CREDENTIALS = "RECEIVE_CREATE_TEAM_CREDENTIALS"
export const REQUEST_CREATE_TEAM_CREDENTIALS_ERROR = "REQUEST_CREATE_TEAM_CREDENTIALS_ERROR"

export const REQUEST_DELETE_TEAM_CREDENTIALS = "REQUEST_DELETE_TEAM_CREDENTIALS"
export const RECEIVE_DELETE_TEAM_CREDENTIALS = "RECEIVE_DELETE_TEAM_CREDENTIALS"
export const REQUEST_DELETE_TEAM_CREDENTIALS_ERROR = "REQUEST_DELETE_TEAM_CREDENTIALS_ERROR"

export const requestTeamCredentials = (teamId) => {
  return {
    type: REQUEST_TEAM_CREDENTIALS,
    teamId
  }
}

export const receiveTeamCredentials = (teamId, credentials) => {
  return {
    type: RECEIVE_TEAM_CREDENTIALS,
    teamId,
    credentials
  }
}

export const requestTeamCredentialsError = error => {
  return {
    type: REQUEST_TEAM_CREDENTIALS_ERROR,
    error
  }
}

export const requestCreateTeamCredentials = (teamId) => {
  return {
    type: REQUEST_CREATE_TEAM_CREDENTIALS,
    teamId
  }
}

export const receiveCreateTeamCredentials = (teamId, accessKey, secretKey) => {
  return {
    type: RECEIVE_CREATE_TEAM_CREDENTIALS,
    teamId,
    accessKey,
    secretKey
  }
}

export const requestCreateTeamCredentialsError = error => {
  return {
    type: REQUEST_CREATE_TEAM_CREDENTIALS_ERROR,
    error
  }
}


export const requestDeleteTeamCredentials = (teamId, accessKey) => {
  return {
    type: REQUEST_DELETE_TEAM_CREDENTIALS,
    teamId,
    accessKey
  }
}

export const receiveDeleteTeamCredentials = (teamId, accessKey) => {
  return {
    type: RECEIVE_DELETE_TEAM_CREDENTIALS,
    teamId,
    accessKey
  }
}

export const requestDeleteTeamCredentialsError = error => {
  return {
    type: REQUEST_DELETE_TEAM_CREDENTIALS_ERROR,
    error
  }
}

