import React, { useEffect, useState } from "react"
import { Route, withRouter } from "react-router-dom"
import { useAuth0, AUTH0_ID_CLAIM_NAMESPACE } from "../../react-auth0-spa"
import { connect } from "react-redux"
import { getParameterByName, queryCompletedSurvey } from '../lib/util'
import { fetchAuth } from '../lib/api-service'
import { saveTokenSubToState } from '../actions'
import { applyTeamInvite } from '../../account/lib/api-service'
import { segmentEvent, segmentIdentify } from '../lib/addons-config'

const mapStateToProps = (state) => ({
  token: state.token || null,
})

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { token, dispatch, history } = rest
  const {
    loading,
    user,
    isOnline,
    isAuthenticated,
    loginWithRedirect,
    getTokenSilently,
    getIdTokenClaims
  } = useAuth0()

  let offlineWithSW = false
  if (isOnline !== undefined) {
    offlineWithSW = !isOnline && ('serviceWorker' in navigator) && navigator.serviceWorker.controller
  }

  const [inviteApplied, setInviteApplied] = useState(false)

  useEffect(() => {
    const saveToken = async () => {
      const auth_token = await getTokenSilently()
      if (auth_token !== token) {
        await dispatch(saveTokenSubToState(auth_token, user?.sub))
        const inviteCode = getParameterByName('invite')
        const completedSurvey = getParameterByName(queryCompletedSurvey)
        if (inviteCode && !inviteApplied) {
          setInviteApplied(true)
          dispatch(applyTeamInvite(history, inviteCode, auth_token))
        } else {
          dispatch(fetchAuth(history, auth_token, completedSurvey))
        }
        // Identify user to segment
        segmentIdentify(null, user?.sub)
        // Check if new signup
        const claims = await getIdTokenClaims()
        const isNewSignUp = claims && claims[`${AUTH0_ID_CLAIM_NAMESPACE}/new_signup`]
        const tagEvent = isNewSignUp ? 'newSignup' : 'returningUser'
        dispatch(segmentEvent(tagEvent))
      }
    }
    if (offlineWithSW) {
      // dispatch fetch auth anyway - assumption is that response is cached
      dispatch(fetchAuth(history, null))
      return
    } else if (loading) {
      return
    } else if (isAuthenticated) {
      saveToken()
      return
    }
    const fn = async () => {
      const { pathname, search } = window.location
      const action = pathname.includes('register') ? 'signUp' : 'login'
      await loginWithRedirect({
        action: action,
        appState: { returnTo: pathname + search },
      })
    }
    fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, path, isAuthenticated, token, offlineWithSW])

  const renderComponents = isAuthenticated === true || offlineWithSW
  const render = props => renderComponents ? <Component {...props} /> : null

  return <Route render={render} {...rest} />
}

export default withRouter(connect(mapStateToProps)(PrivateRoute))
