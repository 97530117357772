
export const ApplyAuditLabel = /* GraphQL */ `
mutation ApplyAuditLabel($auditCode: String!, $labelId: Int!) {
  applyAuditLabel(auditCode: $auditCode, labelId: $labelId) {
    result {
      __typename
      ... on LabelSuccess {
        labels {
            id
            name
            colour
            group
            order
          }
        }
      ... on Error {
        status
        message
        meta
      }
    }
  }
}
`

export const RemoveAuditLabel = /* GraphQL */ `
mutation RemoveAuditLabel($auditCode: String!, $labelId: Int!) {
  removeAuditLabel(auditCode: $auditCode, labelId: $labelId) {
    result {
      __typename
      ... on LabelSuccess {
        labels  {
          id
          name
          colour
          group
          order
        }
      }
      ... on Error {
        status
        message
        meta
      }
    }
  }
}
`


export const ApplyAuditActionLabel = /* GraphQL */ `
mutation ApplyAuditActionLabel($teamId: Int!, $auditActionId: Int!, $labelId: Int!) {
  applyAuditActionLabel(teamId: $teamId, auditActionId: $auditActionId, labelId: $labelId) {
    result {
      __typename
      ... on LabelSuccess {
        labels {
            id
            name
            colour
            group
            order
          }
        }
      ... on Error {
        status
        message
        meta
      }
    }
  }
}
`

export const RemoveAuditActionLabel = /* GraphQL */ `
mutation RemoveAuditActionLabel($teamId: Int!, $auditActionId: Int!, $labelId: Int!) {
  removeAuditActionLabel(teamId: $teamId, auditActionId: $auditActionId, labelId: $labelId) {
    result {
      __typename
      ... on LabelSuccess {
        labels  {
          id
          name
          colour
          group
          order
        }
      }
      ... on Error {
        status
        message
        meta
      }
    }
  }
}
`


export const ApplyQuoteRequestLabel = /* GraphQL */ `
mutation ApplyQuoteRequestLabel($teamId: Int!, $quoteRequestId: Int!, $labelId: Int!) {
  applyQuoteRequestLabel(teamId: $teamId, quoteRequestId: $quoteRequestId, labelId: $labelId) {
    result {
      __typename
      ... on LabelSuccess {
        labels {
            id
            name
            colour
            group
            order
          }
        }
      ... on Error {
        status
        message
        meta
      }
    }
  }
}
`

export const RemoveQuoteRequestLabel = /* GraphQL */ `
mutation RemoveQuoteRequestLabel($teamId: Int!, $quoteRequestId: Int!, $labelId: Int!) {
  removeQuoteRequestLabel(teamId: $teamId, quoteRequestId: $quoteRequestId, labelId: $labelId) {
    result {
      __typename
      ... on LabelSuccess {
        labels  {
          id
          name
          colour
          group
          order
        }
      }
      ... on Error {
        status
        message
        meta
      }
    }
  }
}
`
