// Redux Action types
// ------------------
import safeLocalStorage from '../../common/lib/local-storage'

export const SHOW_STEP = 'SHOW_STEP'
export const REQUEST_PROCESS = 'REQUEST_PROCESS'
export const RECEIVE_PROCESS = 'RECEIVE_PROCESS'
export const REQUEST_PROCESS_ERROR = 'REQUEST_PROCESS_ERROR'
export const PATCH_AUDIT_REQUEST = 'PATCH_AUDIT_REQUEST'
export const PATCH_AUDIT_SUCCESS = 'PATCH_AUDIT_SUCCESS'
export const PATCH_AUDIT_ERROR = 'PATCH_AUDIT_ERROR'
export const UPDATE_DEFAULTS = 'UPDATE_DEFAULTS'
export const SAVE_WALL_DEFAULTS = 'SAVE_WALL_DEFAULTS'
export const SET_WALL_ANSWERS_WITH_DEFAULTS = 'SET_WALL_ANSWERS_WITH_DEFAULTS'
export const REQUEST_AUDIT = 'REQUEST_AUDIT'
export const RECEIVE_AUDIT = 'RECEIVE_AUDIT'
export const REQUEST_NEW_AUDIT = 'REQUEST_NEW_AUDIT'
export const RECEIVE_NEW_AUDIT = 'RECEIVE_NEW_AUDIT'
export const REQUEST_NEW_AUDIT_ERROR = 'REQUEST_NEW_AUDIT_ERROR'
export const PARSE_AUDIT = 'PARSE_AUDIT'
export const POST_AUDIT = 'POST_AUDIT'
export const RESUME_AUDIT = 'RESUME_AUDIT'
export const SIMULATION_STARTED = 'SIMULATION_STARTED'
export const START_SIMULATION_ERROR = 'START_SIMULATION_ERROR'
export const SERVER_VALIDATION_ERROR = 'SERVER_VALIDATION_ERROR'
export const CLEAR_SERVER_VALIDATION_ERROR = 'CLEAR_SERVER_VALIDATION_ERROR'
export const REQUEST_AUDIT_ERROR = 'REQUEST_AUDIT_ERROR'
export const CHANGE_ANSWER = 'CHANGE_ANSWER'
export const CHANGE_MULTISET_ANSWER = 'CHANGE_MULTISET_ANSWER'
export const TOGGLE_LEVEL_TWO = 'TOGGLE_LEVEL_TWO'
export const TOGGLE_LEVEL_THREE = 'TOGGLE_LEVEL_THREE'
export const TOGGLE_MORE_INFO = 'TOGGLE_MORE_INFO'
export const SHOW_QUESTION_SET = 'SHOW_QUESTION_SET'
export const CHANGE_BUILDING_PHASE = "CHANGE_BUILDING_PHASE"
export const CHANGE_CURRENT_FLOOR_INDEX = "CHANGE_CURRENT_FLOOR_INDEX"
export const CHANGE_EDITABLE_WALLS_VALUE = "CHANGE_EDITABLE_WALLS_VALUE"
export const ADD_ANSWER = 'ADD_ANSWER'
export const REMOVE_ANSWER = 'REMOVE_ANSWER'
export const SET_ANSWER_ERRORS = 'SET_ANSWER_ERRORS'
export const UNSET_ANSWER_ERRORS = 'UNSET_ANSWER_ERRORS'
export const SPLASH_DISMISSED = 'SPLASH_DISMISSED'
export const PURGE_AUDIT_PROCESS = 'PURGE_AUDIT_PROCESS'

// Other constants
// ---------------

export const Steps = {
  LOCATION: 'location',
  OCCUPANTS: 'occupants',
  UTILITIES: 'utilities',
  FIXTURES: 'fixtures',
  KITCHEN: 'kitchen',
  BATHROOM: 'bathroom',
  LAUNDRY: 'laundry',
  ELECTRONICS: 'electronics',
  GARDEN: 'garden',
  SOURCES: 'sources',
  CALCULATE: 'calculate'
}

// Redux Action creators
// ---------------------

export const showStep = (step) => {
  return { type: SHOW_STEP, step: step }
}

export const requestProcess = () => {
  return { type: REQUEST_PROCESS }
}

export const receiveProcess = (process) => {
  return {
    type: RECEIVE_PROCESS,
    process
  }
}

export const requestProcessError = (error) => {
  return {
    type: REQUEST_PROCESS_ERROR,
    error
  }
}

export const patchAudit = (patch) => {
  return {
    type: PATCH_AUDIT_REQUEST,
    patch
  }
}

export const patchAuditSuccess = (patch) => {
  return {
    type: PATCH_AUDIT_SUCCESS,
    patch
  }
}

export const patchAuditError = (error) => {
  return {
    type: PATCH_AUDIT_ERROR,
    error
  }
}

export const updateDefaults = (audit) => {
  return {
    type: UPDATE_DEFAULTS,
    audit
  }
}

export const saveWallDefaults = (wallDefaults) => {
  return {
    type: SAVE_WALL_DEFAULTS,
    wallDefaults
  }
}

export const setWallAnswersWithDefaults = () => {
  return {
    type: SET_WALL_ANSWERS_WITH_DEFAULTS
  }
}

export const requestAudit = () => {
  return {
    type: REQUEST_AUDIT
  }
}

export const receiveAudit = (audit) => {
  return {
    type: RECEIVE_AUDIT,
    audit
  }
}

export const parseAudit = (audit) => {
  return {
    type: PARSE_AUDIT,
    audit
  }
}

export const requestAuditError = (error) => {
  return {
    type: REQUEST_AUDIT_ERROR,
    error
  }
}

export const requestNewAudit = () => {
  return {
    type: REQUEST_NEW_AUDIT
  }
}

export const receiveNewAudit = (audit) => {
  return {
    type: RECEIVE_NEW_AUDIT,
    audit
  }
}

export const requestNewAuditError = (error) => {
  return {
    type: REQUEST_NEW_AUDIT_ERROR,
    error
  }
}

export const postAudit = (auditHash) => {
  return {
    type: POST_AUDIT,
    auditHash
  }
}

export const resumeAudit = () => {
  return {
    type: RESUME_AUDIT
  }
}

export const simulationStarted = (auditHash) => {
  return {
    type: SIMULATION_STARTED,
    auditHash
  }
}

export const startSimulationError = (error) => {
  return {
    type: START_SIMULATION_ERROR,
    error
  }
}

export const serverValidationError = (validationErrors) => {
  return {
    type: SERVER_VALIDATION_ERROR,
    validationErrors
  }
}

export const clearServerValidationError = () => {
  return {
    type: CLEAR_SERVER_VALIDATION_ERROR,
  }
}

export const toggleLevelTwo = (sectionCode) => {
  return {
    type: TOGGLE_LEVEL_TWO,
    sectionCode
  }
}

export const toggleLevelThree = (sectionCode) => {
  return {
    type: TOGGLE_LEVEL_THREE,
    sectionCode
  }
}

export const toggleMoreInfo = (questionCode) => {
  return {
    type: TOGGLE_MORE_INFO,
    questionCode
  }
}

export const showQuestionSet = (sectionCode, leadValue, setIndex) => {
  return {
    type: SHOW_QUESTION_SET,
    sectionCode,
    leadValue,
    setIndex
  }
}

export const changeAnswer = (questionId, answerValue) => {
  return {
    type: CHANGE_ANSWER,
    questionId,
    answerValue
  }
}

export const changeMultisetAnswer = (sectionId) => {
  return {
    type: CHANGE_MULTISET_ANSWER,
    sectionId
  }
}

export const changeBuildingPhase = (phaseNumber) => {
  return {
    type: CHANGE_BUILDING_PHASE,
    phaseNumber
  }
}

export const changeCurrentFloorIndex = (floorIndex) => {
  return {
    type: CHANGE_CURRENT_FLOOR_INDEX,
    floorIndex
  }
}

export const changeEditableWallsValue = (wallType = null) => {
  return {
    type: CHANGE_EDITABLE_WALLS_VALUE,
    wallType
  }
}

export const addAnswer = (id, sectionCode, questionCode, value) => {
  return {
    type: ADD_ANSWER,
    id,
    sectionCode,
    questionCode,
    value
  }
}

export const removeAnswer = (id) => {
  return {
    type: REMOVE_ANSWER,
    id
  }
}

export const setAnswerErrors = (validationErrors) => {
  return {
    type: SET_ANSWER_ERRORS,
    validationErrors
  }
}

export const unsetAnswerErrors = () => {
  return {
    type: UNSET_ANSWER_ERRORS,
  }
}

export const splashDismissed = () => {
  safeLocalStorage.setItem('splashSeen', true)
  return {
    type: SPLASH_DISMISSED,
  }
}

export const purgeAuditProcess = () => {
  return {
    type: PURGE_AUDIT_PROCESS,
  }
}
