import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

import { IconButton, Menu, MenuItem } from '@mui/material'

const NewAuditSelector = ({ teams, requestNewAudit, classes }) => (
  <PopupState variant='popover'>
    {popupState => (
      <React.Fragment>
        <IconButton
          className={classes.iconButton}
          {...bindTrigger(popupState)}
          aria-label='New Audit'
          size="large">
          <AddIcon />
        </IconButton>
        <Menu {...bindMenu(popupState)}>
          {teams.map(team => (
            <MenuItem key={team.id}
              onClick={requestNewAudit(team.id)}
            >
              {team.name}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    )}
  </PopupState>
)

export default NewAuditSelector
