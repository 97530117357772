import { Theme, ThemeOptions } from '@mui/material'
import { lightGreen, orange, grey } from '@mui/material/colors'
import { createTheme } from "@mui/material/styles"

const baseTheme = createTheme()
export const overrideTheme: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: lightGreen,
    secondary: orange,
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard', // Default changed to outlined in MUI v5
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover', // Default changed to always in MUI v5
      },
    },
  },
  // Restore MUI v4 breakpoints - https://mui.com/material-ui/guides/migration-v4/#theme
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
}

export const customThemeProps = {
  palette: {
    text: {
      icon: grey[500],
    },
    subtitle: grey[500],
  },
  contentWidth: 1100,
  drawerWidth: 220,
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none',
      },
    },
  },
  zIndex: {
    customAuditDrawer: baseTheme.zIndex.appBar - 1 // Because it is toggled by the appBar button
  }
}

export type CustomTheme = Theme & typeof customThemeProps
