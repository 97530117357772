// The index for the redux reducer.  After an action is dispatched from
// somewhere in the client, the global state object passes first through here
// and is reduced by individual functions, which are imported here in this file,
// and manage state transitions for branches of the global state object.
// ----------------------------------------------------------------------------

import root from './root'
import sections from '../../audit/reducers/sections'
import defaults from '../../audit/reducers/defaults'
import answers from '../../audit/reducers/answers'
import questions from '../../audit/reducers/questions'
import assessmentItems from '../../assess/reducers/assessment-items'
import actItems from '../../act/reducers/act-items'
import me from '../../account/reducers/account-reducer'
import * as actions from '../../audit/actions'
import safeLocalStorage from '../lib/local-storage'


import { combineReducers } from 'redux'

const initialState = {
  // Start at the location step.
  currentStep: actions.Steps.LOCATION,
  splashSeen: !!safeLocalStorage.getItem('splashSeen'),
  shareSeen: !!safeLocalStorage.getItem('shareSeen'),

  // Initialize state branches as empty objects.
  sections: {},
  defaults: {},
  answers: {},
  questions: {},
  audits: {},
  assessmentItems: {},
  actItems: {},
  me: {}
}

const branchReducers = combineReducers({
  sections,
  defaults,
  answers,
  questions,
  assessmentItems,
  actItems,
  me
})

function ecologic(state, action) {
  // If there's no previous state, create an initial state.
  if (state === undefined)
    return initialState

  // Create a new state from the root reducer, including root-level state
  // attributes and cross-sectional transitions.
  const rootState = root(state, action)

  // Create from this new root state a state including only the branches
  // to be passed to the combined reducer.
  const branchState = {
    sections: rootState.sections,
    defaults: rootState.defaults,
    answers: rootState.answers,
    questions: rootState.questions,
    assessmentItems: rootState.assessmentItems,
    actItems: rootState.actItems,
    me: rootState.me,
  }

  // Return a combination of the cross-sectional, global state reducer
  // and the domain-specific branches.
  return {
    ...rootState,
    ...branchReducers(branchState, action)
  }
}

export default ecologic
