import { storageFactory } from 'storage-factory'

const safeLocalStorage = storageFactory(localStorage)

export const safeLocalStorageIsAvailable = () => {
  try {
    safeLocalStorage.setItem('test', 123)
    safeLocalStorage.getItem('test')
    safeLocalStorage.removeItem('test')
    return true
  } catch (e) {
    console.log('no local storage', e)
    return false
  }
}

export default safeLocalStorage
