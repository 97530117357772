/* eslint-disable array-callback-return */
import { where, reject, sortBy, omit } from 'underscore'

const getAssessmentItems = assessmentItems => {
  const sortedItems = sortAssessmentItems(assessmentItems)
  let items = []
  let parsedParentCodes = []

  sortedItems.map((item) => {
    const parentCode = item.parent_code
    let collection = null

    if (!parentCode) {
      items.push(item)
    } else if (parsedParentCodes.includes(parentCode)) {
      return
    } else {
      collection = getAssessmentItemCollection(sortedItems, parentCode)
      parsedParentCodes.push(parentCode)
      items.push({ collection })
    }
  })
  const unstartedItems = items.filter(i => !['complete', 'in_progress'].includes(i.status))
  return unstartedItems
}

const getAssessmentItemCollection = (assessmentItems, parentCode) => {
  return where(assessmentItems, {
    parent_code: parentCode
  })
}

const toggleItem = (assessmentItems, item) => {
  return {
    ...assessmentItems,
    [item.id]: {
      ...assessmentItems[item.id],
      status: item.status === 'selected' ? '' : 'selected',
      changed: true
    }
  }
}

const sortAssessmentItems = items => {
  const byIdDescending = sortBy(items, (item) => item.id * -1)
  const nullPayback = where(byIdDescending, { payback: null })
  const itemsToSort = reject(byIdDescending, { payback: null })
  const byPayback = sortBy(itemsToSort, (item) => item.payback)

  return byPayback.concat(nullPayback)
}

const withDefaultStatus = (item) => {
  return item.status === 'recommended'
    ? Object.assign({}, item, { status: 'selected', changed: true })
    : item
}

const withDefaultStatuses = (items) => items.map(withDefaultStatus)

const statusOptions = {
  NotSelected: '',
  Recommended: 'recommended',
  Selected: 'selected',
  InProgress: 'in_progress',
  Complete: 'complete',
}

const flattenAssessmentItems = (items) => {
  const max_payback = calculateMaxPayback(items)
  const enhancedItems = items.map((item) => {
    const { action, status, paybackDisplay, displayIcon, displaySummary, displayDescription, displayTitle } = item
    const payback_ratio = calculatePaybackRatio(item, max_payback)
    const payback_colour = calculatePaybackColor(item, payback_ratio)
    return {
      ...omit(item, 'action', 'status', 'paybackDisplay', 'displayIcon', 'displaySummary', 'displayDescription'),
      status: statusOptions[status],
      payback_display: paybackDisplay,
      code: action ? action.code : null,
      icon: displayIcon,
      description: displayDescription,
      summary: displaySummary,
      parent_code: action && action.parent ? action.parent.code : null,
      title: displayTitle,
      payback_ratio: payback_ratio,
      payback_colour: payback_colour
    }
  })
  return enhancedItems
}

const calculateMaxPayback = (items, min_payback = 15) => {
  if (items) {
    let paybacks = [min_payback]
    for (let i = 0; i < items.length; i++) {
      if (items[i].payback) {
        paybacks.push(items[i].payback)
      }
    }
    return Math.max.apply(Math, paybacks)
  }
  return min_payback
}

const calculatePaybackRatio = (item, max_payback) => {
  if (item.payback) {
    return item.payback / max_payback
  }
}

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

const calculatePaybackColor = (item, ratio, colour_start = [112, 180, 28], colour_end = [240, 57, 28]) => {
  let colour = []
  if (ratio) {
    if (ratio < 0.5) {
      colour = [
        (1 - ratio) * colour_start[0] + ratio * colour_end[0],
        colour_start[1],
        colour_start[2]
      ]
    } else {
      colour = [
        colour_end[0],
        colour_start[1] + (colour_end[1] - colour_start[1]) * ratio,
        colour_end[2]
      ]
    }
    return rgbToHex(colour[0], colour[1], colour[2])
  } else {
    return null
  }
}

export {
  flattenAssessmentItems,
  getAssessmentItems,
  getAssessmentItemCollection,
  toggleItem,
  sortAssessmentItems,
  withDefaultStatus,
  withDefaultStatuses,
  statusOptions
}
