import React from 'react'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { overrideTheme, customThemeProps } from '../styles/ecologic-theme'
import { CssBaseline } from '@mui/material'
import deepmerge from "deepmerge"
import { v4DefaultButtons } from "../styles/v4-default-buttons"
import createCache from '@emotion/cache'
import { CacheProvider } from "@emotion/react"

export const muiCache = createCache({
  'key': 'mui',
  'prepend': true,
})

const memoizedMuiTheme = memoize(createTheme)

class CustomThemeProvider extends React.Component {
  render() {
    const muiTheme = memoizedMuiTheme(this.props.theme)
    return (
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            {this.props.children}
          </SnackbarProvider>
        </ThemeProvider>
      </CacheProvider>
    )
  }
}

const unmemoizedMergeTheme = (...themes) => deepmerge.all(themes)
const mergeTheme = memoize(unmemoizedMergeTheme)

const mapStateToProps = ({ site }) => {
  const themes = [overrideTheme, customThemeProps, v4DefaultButtons]
  if (site && site.theme) {
    themes.push(site.theme)
  }
  return {
    theme: mergeTheme(...themes)
  }
}

export default connect(mapStateToProps)(CustomThemeProvider)
