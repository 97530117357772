import React from 'react'
import { makeStyles } from 'tss-react/mui'
import {
  Paper,
  Typography,
  Button,
} from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    width: '50%',
    textAlign: 'center'
  },
  button: {
    marginTop: theme.spacing(2),
  }
}))

const RedirectOptions = () => {
  const { classes } = useStyles()
  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <Typography gutterBottom variant='h5'>
          Oops! We cannot find that partner subdomain.
        </Typography>
        <Typography gutterBottom variant='h6'>
          Please check the spelling of the website address: {window.location.href}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          href="https://www.ecologicapp.com"
        >
          Navigate to www.ecologicapp.com
        </Button>
      </Paper>
    </div>
  )
}

export default RedirectOptions
