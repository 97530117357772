// The reducer for the questions branch of the global state
// --------------------------------------------------------

import * as actions from '../actions/index'

function questions (state, action) {
  if (state === undefined)
    return {}

  switch (action.type) {
    case actions.TOGGLE_MORE_INFO:
      return {
        ...state,
        [action.questionCode]: {
          ...state[action.questionCode],
          moreInfoVisible: !state[action.questionCode].moreInfoVisible
        }
      }
    default:
      return state
  }
}

export default questions
