import { MyTeam, QueryMyTeamArgs } from "../../__generated__/graphql"

export const GetMyTeam = /* GraphQL */ `
  query GetMyTeam($teamId: Int!) {
    myTeam(teamId: $teamId) {
      id
      isPartner
      isPro
      isSupplier
      name
    }
  }
`
export type GetMyTeamArgs = QueryMyTeamArgs
export type GetMyTeamResult = Pick<MyTeam, 'id' | 'isPartner' | 'isPro' | 'isSupplier' | 'name'>
export type GetMyTeamData = {
  myTeam: GetMyTeamResult
}
