import { stepUrl } from '../lib/steps'

const parseValidationErrors = (errors, currentAudit, sections) => {
  return Object.entries(errors).map(([section, questions]) => {
    return Object.entries(questions).map(([question, messages]) => {
      return {
        code: `${section}:${question}`,
        step: sections[section]?.stepCode,
        stepUrl: stepUrl(currentAudit, sections[section]?.stepCode),
        section,
        messages,
      }
    })
  }).reduce((a, b) => a.concat(b), [])
}

export default parseValidationErrors
