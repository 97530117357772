import icon from '../../common/icons/bills'
import { TemplateType } from "./types"

export const Bills: TemplateType = {
  icon,
  comparison: {
    title: 'How your bills compare',
    body: [
      'The chart below provides a comparison of your estimated bills to similar properties.',
      'The estimate includes all bills including electricity bills, gas bills and water bills (where specified).',
      'The estimate is an average day with consideration for all seasons.',
    ],
  },
  composition: {
    title: 'Your bills breakdown',
    body: [
      'The chart below provides a breakdown of the bills your property is likely to receive.',
      'Where no tariffs have been provided we have applied typical prices in your region.',
      '',
    ],
  },
  units: '$',
  period: 'year',
  multiplier: 365,
}
