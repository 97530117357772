// The root reducer responds to actions with changes to the root level
// of the global state object.  It also initializes branches and performs
// cross-sectional changes, i.e. changes across multiple branches of the state.
// ----------------------------------------------------------------------------

import { omit, findWhere } from 'underscore'
import * as auditActions from '../../audit/actions'
import * as assessActions from '../../assess/actions'
import * as actActions from '../../act/actions'
import * as teamAuditActions from '../../team-audits/actions'
import * as teamSubNavActions from "../../team-dashboard/actions"
import * as accountActions from '../../account/actions'
import * as commonActions from '../actions'
import { normalizeProcess, normalizeAudit } from '../../audit/lib/normalize'
import { normalizeAssessmentItems } from '../../assess/lib/normalize'
import { withDefaultStatuses, flattenAssessmentItems } from '../../assess/lib/assessment-items'
import { normalizeActItems } from '../../act/lib/normalize'
import { generateMultisetAnswer } from '../../audit/lib/multiset-sections'
import parseValidationErrors from '../../audit/lib/validation-errors'
import {
  enhanceMember,
  enhanceTeam,
  enhanceAudits,
} from '../../audit/lib/enhance-app-data'
import { auditCodeFromUrl } from '../lib/util'

function root(state, action) {
  switch (action.type) {
    // For use in Redux Devtools
    case commonActions.DEBUG_COMBINE_STATE:
      return {
        ...state,
        ...action.state,
      }
    // For use in Redux Devtools
    case commonActions.DEBUG_REPLACE_STATE:
      return {
        ...action.state
      }
    case commonActions.SET_AUDIT:
      return {
        ...state,
        currentAudit: action.hash
      }
    case auditActions.SPLASH_DISMISSED:
      return {
        ...state,
        splashSeen: true
      }
    case auditActions.SHOW_STEP:
      return {
        ...state,
        currentStep: action.step
      }
    case auditActions.REQUEST_PROCESS:
      return {
        ...state,
        processRequested: true
      }
    case auditActions.RECEIVE_PROCESS: {
      const processEntities = normalizeProcess(action.process).entities

      return {
        ...state,
        processReceived: true,
        ...processEntities
      }
    }
    case auditActions.PURGE_AUDIT_PROCESS: {
      const itemsToPurge = [
        'audit',
        'auditRequested',
        'auditReceived',
        'auditParsed',
        'processRequested',
        'processReceived',
      ]
      return {
        ...omit(state, itemsToPurge),
        currentStep: auditActions.Steps.LOCATION,
        answers: {},
        defaults: {},
        questions: {},
        sections: {},
        steps: {},
      }
    }
    case auditActions.PATCH_AUDIT_REQUEST:
      return {
        ...state,
        savingAudit: true
      }
    case auditActions.PATCH_AUDIT_SUCCESS:
      return {
        ...state,
        savingAudit: false
      }
    case auditActions.REQUEST_AUDIT:
      return {
        ...state,
        auditRequested: true
      }
    case auditActions.RECEIVE_AUDIT:
      return {
        ...state,
        audit: action.audit,
        auditReceived: true
      }
    case auditActions.PARSE_AUDIT: {
      const knownUser = !!state.me?.id

      const auditEntities = normalizeAudit(action.audit, state.sections, knownUser, state.answers).entities

      return {
        ...state,
        auditParsed: true,
        ...auditEntities
      }
    }
    case auditActions.REQUEST_NEW_AUDIT_ERROR: {
      let new_messages = state.messages.slice()
      new_messages.push(action.error)
      return {
        ...state,
        messages: new_messages
      }
    }
    case auditActions.POST_AUDIT:
      return {
        ...state,
        auditPosted: true
      }
    case auditActions.RESUME_AUDIT:
      return {
        ...state,
        auditPosted: false
      }
    case auditActions.SIMULATION_STARTED:
      return {
        ...state,
        simulationStarted: true
      }
    case auditActions.SERVER_VALIDATION_ERROR: {
      const errors = parseValidationErrors(
        action.validationErrors,
        state.currentAudit,
        state.sections,
      )
      return {
        ...state,
        serverValidationErrors: errors
      }
    }
    case auditActions.CLEAR_SERVER_VALIDATION_ERROR:
      return omit(state, 'serverValidationErrors')
    case commonActions.TOGGLE_MOBILE_NAV:
      return {
        ...state,
        mobileNavOpen: !state.mobileNavOpen
      }
    case commonActions.TOGGLE_TABLET_NAV:
      return {
        ...state,
        tabletNavOpen: !state.tabletNavOpen
      }
    case commonActions.TOGGLE_AUDIT_SELECTOR:
      return {
        ...state,
        auditSelectorOpen: !state.auditSelectorOpen
      }
    case commonActions.SHOW_DIALOG:
      return {
        ...state,
        dialog: {
          id: action.id,
          props: action.props
        }
      }
    case commonActions.HIDE_DIALOG:
      return {
        ...state,
        dialog: null
      }
    case commonActions.SHOW_SHARE_BAR:
      return {
        ...state,
        shareBarOpen: true,
      }
    case commonActions.HIDE_SHARE_BAR:
      return {
        ...state,
        shareBarOpen: false,
      }
    case commonActions.SHARE_SEEN:
      return {
        ...state,
        shareSeen: true,
      }
    case commonActions.DISPLAY_ERROR_MESSAGE: {
      let new_messages = state.messages ? state.messages.slice() : []
      new_messages.push(action.error)

      return {
        ...state,
        messages: new_messages
      }
    }
    case commonActions.ACKNOWLEDGE_MESSAGES:
      if (state.messages[0].level === "LOGIN ERROR") {
        const currentUrl = window.location.origin
        // Log user out
        window.location.replace(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/logout?returnTo=${currentUrl}&client_id=${process.env.REACT_APP_AUTH0_CLIENTID}`)
      }
      return {
        ...state,
        messages: [],
      }
    case commonActions.CHANGE_AUDIT_ACTION_STATUS_IN_AUDITS:
      const audit = state.audits[action.code]
      return {
        ...state,
        audits: {
          ...state.audits,
          [action.code]: {
            ...audit,
            actions: audit.actions.map(a => {
              if (a.id !== action.id) return a
              return {
                ...a,
                status: action.status,
                changed: true,
              }
            })
          }
        }
      }
    case auditActions.CHANGE_BUILDING_PHASE:
      return {
        ...state,
        buildingPhase: action.phaseNumber
      }
    case auditActions.CHANGE_CURRENT_FLOOR_INDEX: {
      return {
        ...state,
        currentFloorIndex: action.floorIndex
      }
    }
    case auditActions.CHANGE_EDITABLE_WALLS_VALUE: {
      return {
        ...state,
        currentEditableWalls: action.wallType
      }
    }
    case auditActions.CHANGE_MULTISET_ANSWER: {
      const questions = state.sections[action.sectionId].questions
      const questionCodes = questions.map((id) => state.questions[id].code)
      const isSectionSetArray = state.sections[action.sectionId].isSectionSetArray

      const newMultisetValue = generateMultisetAnswer(
        action.sectionId,
        questionCodes,
        state.answers,
        isSectionSetArray
      )

      return {
        ...state,
        answers: {
          ...state.answers,
          [action.sectionId]: {
            ...state.answers[action.sectionId],
            value: newMultisetValue,
            didChange: true
          }
        }
      }
    }
    case assessActions.RECEIVE_ASSESSMENT_ITEMS: {
      const items = flattenAssessmentItems(action.assessmentItems.assessmentItems)
      const assessmentItemEntities = normalizeAssessmentItems(
        withDefaultStatuses(items)
      ).entities
      return {
        ...state,
        assessmentItemsReceived: true,
        ...assessmentItemEntities
      }
    }
    case assessActions.PURGE_ASSESSMENT_ITEMS: {
      const itemsToPurge = [
        'assessmentItemsReceived',
      ]
      return {
        ...omit(state, itemsToPurge),
        assessmentItems: {},
      }
    }
    case actActions.RECEIVE_ACT_DATA: {
      const actItemEntities = normalizeActItems(
        action.actData.data.audit.actions
      ).entities
      const contactDetails = action.actData.data.audit.contactDetails
      return {
        ...state,
        actDataReceived: true,
        actItems: Object.assign({}, actItemEntities, { customerContactDetails: contactDetails })
      }
    }
    case actActions.PURGE_ACT_DATA: {
      const itemsToPurge = [
        'actDataReceived',
      ]
      return {
        ...omit(state, itemsToPurge),
        actItems: {},
      }
    }
    case commonActions.SW_INIT: {
      return {
        ...state,
        serviceWorkerInitialised: true,
        serviceWorkerUpdated: false,
        serviceWorkerRegistration: null,
      }
    }
    case commonActions.SW_UPDATE: {
      return {
        ...state,
        serviceWorkerUpdated: true,
        serviceWorkerRegistration: action.payload,
      }
    }
    case commonActions.CLEAR_SW_STATUS:
      return {
        ...state,
        serviceWorkerInitialised: action.statusToClear === "initialised" ? null : state.serviceWorkerInitialised,
        serviceWorkerUpdated: action.statusToClear === "updated" ? null : state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.statusToClear === "updated" ? null : state.serviceWorkerRegistration,
      }
    case commonActions.SET_SW_OFFER_INSTALL:
      return {
        ...state,
        serviceWorkerOfferInstall: action.flag
      }
    case commonActions.SAVE_TOKEN_SUB_TO_STATE:
      return {
        ...state,
        token: action.token,
        sub: action.sub,
      }
    case commonActions.REQUEST_AUTH_CHECK:
      return {
        ...state,
        authCheckRequested: true,
      }
    case commonActions.RECEIVE_AUTH_CHECK: {
      const { data } = action
      const id = data && data.me && data.me.id
      return {
        ...state,
        authCheckReceived: true,
        authenticated: !!id,
      }
    }
    case commonActions.REQUEST_AUTH_CHECK_ERROR: {
      const appLoginError = {
        level: action.error,
        text: action.message
      }
      return {
        ...state,
        messages: [appLoginError]
      }
    }
    case commonActions.REQUEST_APP_DATA:
      return {
        ...state,
        appDataRequested: true,
      }
    case commonActions.REQUEST_APP_DATA_ERROR:
      return {
        ...state,
        messages: [
          ...state.messages || [],
          action.message
        ]
      }
    case commonActions.RECEIVE_APP_DATA: {
      const { site, me, messages } = action.data
      let new_messages = state.messages ? (state.messages).concat(messages) : messages
      return {
        ...state,
        site,
        messages: new_messages,
        me,
        // don't reset audit if already set from url hash
        appDataReceived: true,
      }
    }
    case commonActions.RECEIVE_TEAM_DATA_V2: {
      const { myTeam = null } = action.data || {}
      return {
        ...state,
        myTeam
      }
    }
    case commonActions.REQUEST_AUDIT_SUGGESTIONS:
      return {
        ...state,
        auditSuggestionsRequested: true,
        auditSuggestionsReceived: false,
      }
    case commonActions.RECEIVE_AUDIT_SUGGESTIONS:
      return {
        ...state,
        auditSuggestionsRequested: false,
        auditSuggestionsReceived: true,
      }
    case commonActions.REQUEST_AUDIT_DETAILS:
      return {
        ...state,
        auditDetailRequested: true,
        auditDetailReceived: false,
      }
    case commonActions.RECEIVE_AUDIT_DETAILS: {
      const { code } = action.audit
      const audit = { [code]: action.audit }
      return {
        ...state,
        audits: Object.assign({}, state.audits, audit),
        auditDetailRequested: false,
        auditDetailReceived: true,
      }
    }
    case commonActions.OPEN_ADVICE_DIALOG: {
      return {
        ...state,
        openAdviceDialog: true,
      }
    }
    case commonActions.CLOSE_ADVICE_DIALOG: {
      return {
        ...state,
        openAdviceDialog: false,
      }
    }
    case 'RECEIVE_RESULTS': {
      const updatedAudit = action.results.data.audit
      const audit = { [updatedAudit.code]: updatedAudit }
      const audits = Object.assign({}, state.audits, audit)
      return {
        ...state,
        audits,
      }
    }
    case accountActions.REQUEST_APPLY_TEAM_INVITE_ERROR: {
      let new_messages = state.messages ? state.messages.slice() : []
      new_messages.push(action.error)
      return {
        ...state,
        messages: new_messages
      }
    }
    case accountActions.RECEIVE_GET_CREDIT_DETAILS: {
      const teamToUpdate = action.data.me.teams.find(team => team.id === action.teamId)
      return {
        ...state,
        creditProducts: action.data.creditProducts,
        me: {
          ...state.me,
          teams: state.me.teams.map(team => {
            if (team.id !== teamToUpdate.id) return team
            return {
              ...team,
              creditSummary: teamToUpdate.creditSummary,
              availableCredits: teamToUpdate.availableCredits
            }
          })
        }
      }
    }
    case teamSubNavActions.RECEIVE_AUDITS: {
      const auditCode = auditCodeFromUrl(window.location)
      const firstAudit = auditCode || action.audits?.[0]?.code
      const columns = state.listState && state.listState.auditList && state.listState.auditList.listColumns ? state.listState.auditList.listColumns : null

      return {
        ...state,
        currentAudit: state.currentAudit || firstAudit,
        listState: {
          ...state.listState,
          currentTeamId: action.teamId,
          auditList: {
            ...enhanceAudits(action.audits),
            auditListCount: action.totalCount,
            auditListHasLoaded: true,
            listPageStart: action.pageStart,
            listPageSize: action.pageSize,
            listColumns: columns,
          }
        }
      }
    }
    case teamSubNavActions.CLEAR_AUDITS: {
      return {
        ...state,
        listState: {
          ...omit(state.listState, 'auditList'),
        }
      }
    }
    case teamSubNavActions.CLEAR_TABLE_STATE: {
      return {
        ...state,
        listState: {
          ...omit(state.listState, 'auditList', 'actionList', 'projectList', 'currentTeamId'),
        }
      }
    }
    case teamSubNavActions.RECEIVE_TEAM_DATA: {
      return {
        ...state,
        me: {
          ...state.me,
          teams: state.me.teams.map(team => {
            const teamData = findWhere(action.teams, { id: team.id })
            return {
              ...enhanceTeam(state.me.id, team, teamData)
            }
          })
        }
      }
    }
    case teamSubNavActions.RECEIVE_PROJECTS: {
      return {
        ...state,
        listState: {
          ...state.listState,
          projectList: {
            projectListResults: action.projects.data.team.quoteRequests
          }
        }
      }
    }
    case teamSubNavActions.CLEAR_PROJECTS: {
      return {
        ...state,
        listState: {
          ...omit(state.listState, 'projectList'),
          currentTeamId: action.teamId,
        }
      }
    }
    case teamSubNavActions.RECEIVE_ACTIONS: {
      return {
        ...state,
        listState: {
          ...state.listState,
          actionList: {
            actionListResults: action.actions.data.team.actions
          }
        }
      }
    }
    case teamSubNavActions.CLEAR_ACTIONS: {
      return {
        ...state,
        listState: {
          ...omit(state.listState, 'actionList'),
          currentTeamId: action.teamId,
        }
      }
    }
    case teamSubNavActions.RECEIVE_HEADLINE_DATA: {
      return {
        ...state,
        me: {
          ...state.me,
          teams: state.me.teams.map(team => {
            if (team.id !== action.teamId) return team
            return {
              ...team,
              auditsSummary: action.headlineData.totalAudits,
              auditsWithResults: action.headlineData.auditsWithResults,
              auditsWithPlans: action.headlineData.auditsWithPlans,
              actionsSummary: action.headlineData.totalActions,
              totalCarbonSavings: action.headlineData.totalCarbonSavings,
              totalBillSavings: action.headlineData.totalBillSavings,
              projectsSummary: action.headlineData.totalProjects,
            }
          })
        }
      }
    }
    case teamSubNavActions.CLEAR_HEADLINE_DATA: {
      return {
        ...omit(state, 'auditsSummary', 'projectsSummary', 'actionsSummary'),
      }
    }
    case teamSubNavActions.OPEN_LIST_LABEL_DIALOG: {
      return {
        ...state,
        listLabelDialogOpen: true,
        listLabelEditContentType: action.contentType,
        listLabelEditId: action.id
      }
    }
    case teamSubNavActions.CLOSE_LIST_LABEL_DIALOG: {
      return {
        ...state,
        listLabelDialogOpen: false,
      }
    }
    case teamAuditActions.SAVE_LIST_COLUMN_STATE: {
      return {
        ...state,
        listState: {
          ...state.listState,
          auditList: {
            ...state.listState.auditList,
            listColumns: action.columns
          }
        }
      }
    }
    case teamAuditActions.OPEN_ASSIGN_AUDIT_DIALOG: {
      return {
        ...state,
        assignAuditDialogOpen: true,
        assignAuditDialogAudit: action.code,
      }
    }
    case teamAuditActions.CLOSE_ASSIGN_AUDIT_DIALOG: {
      return {
        ...state,
        assignAuditDialogOpen: false,
      }
    }
    case teamAuditActions.REQUEST_ASSIGN_AUDIT_ERROR: {
      return {
        ...state,
        assignAuditDialogOpen: true,
        assignAuditDialogError: action.error,
      }
    }
    case teamAuditActions.UPDATE_AUDIT_OWNER: {
      const owner = state.me.teams
        .flatMap(team => team.members)
        .find(member => member.id === action.ownerId)
      const ownerDisplay = enhanceMember(owner).displayName
      return {
        ...state,
        listState: {
          ...state.listState,
          auditList: {
            ...state.listState.auditList,
            auditListResults: state.listState.auditList.auditListResults.map(audit => {
              if (audit.code !== action.auditCode) return audit
              return {
                ...audit,
                owner,
                ownerDisplay,
              }
            })
          }
        }
      }
    }

    default:
      return state
  }
}

export default root
