// The reducer for the defaults branch of the global state
// -------------------------------------------------------

import * as actions from '../actions/index'
import { mergeDefaults } from '../lib/defaults'

function defaults (state, action) {
  if (state === undefined)
    return {}

  switch (action.type) {
    case actions.UPDATE_DEFAULTS:
      return mergeDefaults({
        defaultsStore: state,
        patch: action.audit.defaults
      })
    default:
      return state
  }
}

export default defaults
