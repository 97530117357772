import React from 'react'
import { connect } from 'react-redux'
import { setSWOfferInstall, hideShareBar } from '../actions'
import { segmentEvent } from '../../common/lib/addons-config'
import GetAppIcon from '@mui/icons-material/GetApp'
import { Button } from '@mui/material'

const PwaInstallPrompt = (props) => {
  const { dispatch, classes, serviceWorkerOfferInstall } = props

  if (!serviceWorkerOfferInstall || !window.deferredPrompt) return null

  const handleInstallClick = () => {
    dispatch(setSWOfferInstall(false))
    dispatch(hideShareBar())
    // Trigger install app prompt
    window.deferredPrompt.prompt()
    // See prompt response
    window.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        dispatch(segmentEvent('userAcceptedInstallPrompt'))
      } else {
        dispatch(segmentEvent('userDismissedInstallPrompt'))
      }
    })
  }

  return (
    <>
      <div className={classes.installPrompt}>
        <Button
          size="small" variant={'contained'}
          color={'secondary'}
          onClick={() => handleInstallClick()}>
          INSTALL APP ON YOUR DEVICE <GetAppIcon />
        </Button>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const serviceWorkerOfferInstall = state.serviceWorkerOfferInstall || false
  return {
    serviceWorkerOfferInstall,
  }
}

export default connect(mapStateToProps)(PwaInstallPrompt)
