import React from 'react'
import { withRouter } from 'react-router'
import { withStyles } from 'tss-react/mui'
import AddIcon from '@mui/icons-material/Add'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { downloadAuditReport } from '../../../team-audits/api'
import { startNewAudit } from '../../../audit/lib/api-service'
import CollapsibleSelector from './collapsible-selector'
import NewAuditSelector from './new-audit-selector'
import AuditListSelector from './audit-list-selector'
import SearchIcon from '@mui/icons-material/Search'
import { toggleAuditSelector } from '../../actions'
import { IconButton } from '@mui/material'

const styles = (theme) => ({
  iconContainer: {
    marginLeft: 'auto',
  },
  iconButton: {
    padding: theme.spacing(1),
  }
})

const NewAudit = ({ team, requestNewAudit, classes }) => (
  <IconButton
    className={classes.iconButton}
    aria-label='New Audit'
    onClick={requestNewAudit(team.id)}
    size="large">
    <AddIcon />
  </IconButton>
)


const ProControls = (props) => {
  const {
    audit,
    audits,
    auditSelectorOpen,
    teams,
    myTeam,
    dispatch,
    classes,
    history
  } = props

  const showAuditActionButtons = new RegExp('^/(audit|assess|act)/').test(window.location.pathname)

  const downloadReport = code => (event) => {
    event.preventDefault()
    dispatch(downloadAuditReport(code))
  }

  const requestNewAudit = (teamId) => (event) => {
    event.preventDefault()
    dispatch(startNewAudit(history, teamId))
  }

  return (
    <React.Fragment>
      {showAuditActionButtons &&
        <CollapsibleSelector
          {...{
            audit,
            audits,
            dispatch,
          }}
        />
      }
      {!auditSelectorOpen &&
        <span className={classes.iconContainer}>
          <React.Fragment>
            {showAuditActionButtons &&
              <IconButton
                className={classes.iconButton}
                aria-label='Audit Search'
                onClick={() => dispatch(toggleAuditSelector())}
                size="large">
                <SearchIcon />
              </IconButton>
            }
            {showAuditActionButtons && audit.result && audit.result.report &&
              <IconButton
                className={classes.iconButton}
                aria-label='Audit Report'
                href={`/report/${audit.code}/pdf/`}
                onClick={downloadReport(audit.code)}
                size="large">
                <PictureAsPdfIcon />
              </IconButton>
            }

            {teams.length === 1 && (!myTeam || myTeam?.isPro) && (
              <NewAudit classes={classes} team={teams[0]} requestNewAudit={requestNewAudit} />
            )}
            {teams.length > 1 && (
              <NewAuditSelector classes={classes} teams={teams} requestNewAudit={requestNewAudit} />
            )}

            <React.Fragment>
              {teams.length >= 1 &&
                <AuditListSelector classes={classes} teams={teams} />
              }
            </React.Fragment>
          </React.Fragment>
        </span>
      }
    </React.Fragment >
  )
}

export default withStyles(withRouter(ProControls), styles)
