import { Middleware } from '@reduxjs/toolkit'
import { removeUrlParams } from './util'

const imageSignatureStripper = (data: string): string => {
  if (/.+s3\.amazonaws\.com.+(png|jpg|gif|jpeg)/.test(data)) {
    data = removeUrlParams(data)
  }
  return data
}

export function actionDataIterator(data: Record<string, any>) {
  if (typeof data === 'object') {
    Object.keys(data).forEach((k) => {
      if (data[k] !== null && typeof data[k] === 'object') {
        return actionDataIterator(data[k])
      }
      data[k] = imageSignatureStripper(data[k])
    })
  }
  return data
}

export const imageSignatureStripperMiddleware: Middleware = () => (next) => (action) => {
  if (/^RECEIVE_TEAM_DETAILS$|^RECEIVE_TEAM_DATA$|^RECEIVE_ACT_DATA$/.test(action.type)) {
    actionDataIterator(action.data)
  }
  next(action)
}
