const auditCodeLength = 36
const codePattern = RegExp(`/[0-9a-fA-F-]{${auditCodeLength}}(/|$)`)

// Common path pieces
export const prefixStartSurvey = 'start-survey'
export const pathSurveyComplete = '/survey-complete'
export const queryCompletedSurvey = 'completedSurvey'

export function getStartScreenRoutePrefix(url: string = window.location.pathname): string {
  const match = /(start.*?)(\/|$)/.exec(url)
  return match && match.length > 1 ? match[1] : 'start'
}

export function isStartAuditPath(url: string = window.location.pathname): boolean {
  return url.startsWith('/start')
}

export function isCompletedSurveyPath(): boolean {
  return window.location.search.includes(queryCompletedSurvey)
}
export function isHiddenCompletedSurveyPath() {
  return window.location.pathname.includes(pathSurveyComplete) || window.location.search.includes(prefixStartSurvey)
}


export const auditCodeFromUrl = (location: Pick<Location, 'hash' | 'pathname'>): string | boolean => {
  const pathCode = location.pathname.split('/')
    .find(s => s.length === auditCodeLength)
  if (pathCode) return pathCode

  const hashCode = location.hash.slice(1)
  if (hashCode.length === auditCodeLength) return hashCode

  return false
}

export const urlFromAuditCode = (code: string, location: Pick<Location, 'hash' | 'pathname'>): string => {
  const dashboard = `/#${code}`
  if (location.pathname === '/') {
    return dashboard
  } else {
    const newPath = location.pathname.replace(codePattern, `/${code}/`)
    return location.pathname === newPath ? dashboard : newPath
  }
}

/**
 * @deprecated Use URLSearchParams instead
 */
export const getParameterByName = (name: string, url: string): string | null => {
  if (!url) url = window.location.href
  name = name.replace(/[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const removeUrlParams = (url: string): string => {
  return url.split('?')[0]
}

export function isRoleAdmin(role: number): boolean {
  return role >= 30
}

export function isProSupplierSignup(): boolean {
  return /^\/register-/.test(window.location.pathname)
}

export function sum(values: number[]) {
  return values.reduce((a, b) => a + b, 0)
}

export function balanceRoundedPercentages(numbers: number[], max = 100) {
  const roundedNumbers = numbers.map(roundPercent)
  while (
    sum(roundedNumbers) > max &&
    roundedNumbers.some((val, i) => roundedNumbers[i] > numbers[i])
  ) {
    for (let i = 0; i < roundedNumbers.length; i++) {
      if (roundedNumbers[i] > numbers[i]) {
        roundedNumbers[i] -= 1
        break
      }
    }
  }
  return roundedNumbers
}

export function formatBalanceRoundedPercentages(numbers: number[], max = 100) {
  return balanceRoundedPercentages(numbers, max).map((n) => `${n}%`)
}

function roundPercent(value: number) {
  return Math.round(value * 100)
}

export function safeInt(value: number | string | undefined | null) {
  if (typeof value === 'string') {
    return parseInt(value)
  }
  return value
}
