export const GetTeams = /* GraphQL */ `
  query GetTeams {
    me {
      teams {
        id
        myRole
        labels {
          audit {
            id
            name
            colour
            group
            order
          }
          auditAction {
            id
            name
            colour
            group
            order
          }
          quoteRequest {
            id
            name
            colour
            group
            order
          }
        }
        members {
          userId
          username
          firstName
          lastName
          role
        }
      }
    }
  }
`

export const GetProjects = /* GraphQL */ `
  query GetProjects($teamId: Int!) {
    team(teamId: $teamId) {
      id
      quoteRequests {
        id
        auditAction {
          id
          audit {
            code
            team {
              name
            }
            address
            latitude
            longitude
          }
          action {
            code
            title
          }
        }
        labels {
          id
          name
          colour
          group
          order
        }
        firstName
        lastName
        replyEmail
        replyPhone
        street
        price
        priceSuffix
        created
        offer {
          id
          supplier {
            name
          }
          title
          active
          title
          summary
          fulfilment
          pricePrefix
          price
          priceRange
          priceSuffix
        }
      }
    }
  }
`

export const GetActions = /* GraphQL */ `
  query GetActions($teamId: Int!) {
    team(teamId: $teamId) {
      id
      actions {
        id
        audit {
          code
          address
          latitude
          longitude
          hasPlan
        }
        labels {
          id
          name
          colour
          group
          order
        }
        action {
          title
        }
        status
        electricity
        electricityBill
        gas
        gasBill
        water
        waterBill
        greenhouseGas
      }
    }
  }
`

export const GetHeadlineData = /* GraphQL */ `
  query GetHeadlineData($teamId: Int!) {
    teamSummaryData(teamId: $teamId) {
      totalAudits
      auditsWithResults
      auditsWithPlans
      totalActions
      totalCarbonSavings
      totalBillSavings
      totalProjects
    }
  }
`

export const GetAudits = /* GraphQL */ `
  query GetAudits($search: String, $offset: Int, $limit: Int, $orderBy: String) {
    allAudits(search: $search, offset: $offset, limit: $limit, orderBy: $orderBy) {
      totalCount
      offset
      limit
      results {
        code
        team {
          id
        }
        contactName
        address
        latitude
        longitude
        modified
        created
        country
        state
        postcode
        labels {
          id
          name
          colour
          group
          order
        }
        owner {
          id
          username
          firstName
          lastName
        }
        result {
          simulation {
            status
          }
        }
        hasPlan
      }
    }
  }
`

export const GetAuditsForMap = /* GraphQL */ `
  query GetAuditsForMap($search: String, $offset: Int, $limit: Int) {
    allAudits(search: $search, offset: $offset, limit: $limit) {
      totalCount
      offset
      limit
      results {
        code
        address
        longitude
        latitude
        hasPlan
        contactName
        result {
          simulation {
            status
          }
        }
      }
    }
  }
`

export const GetAuditsReport = /* GraphQL */ `
  query GetAuditsReport($teamId: Int!) {
    team(teamId: $teamId) {
      reports {
        auditsReportUrl
      }
    }
  }
`

export const GetProjectsReport = /* GraphQL */ `
  query GetProjectsReport($teamId: Int!) {
    team(teamId: $teamId) {
      reports {
        quoteRequestsReportUrl
      }
    }
  }
`

export const GetActionsReport = /* GraphQL */ `
  query GetActionsReport($teamId: Int!) {
    team(teamId: $teamId) {
      reports {
        actionsReportUrl
      }
    }
  }
`
