import React, { FunctionComponent } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Grid, Typography } from '@mui/material'
import { ComparisonCommentaryType } from '../types'

const useStyles = makeStyles()({
  label: {
    fontWeight: 550,
  },
})

type Props = {
  body: ComparisonCommentaryType
}

const ComparisonText: FunctionComponent<Props> = ({ body }) => {
  const { classes } = useStyles()
  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <Typography display="inline">Your current {body.resource} rating is </Typography>
        <Typography display="inline" className={classes.label}>
          {body.rating}{' '}
        </Typography>
        <Typography display="inline">
          compared to similar buildings with a similar number of occupants.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography display="inline">Your {body.resource} saving potential is </Typography>
        <Typography display="inline" className={classes.label}>
          {body.savings_rating}
        </Typography>
        {body.savings_percent > 0 && (
          <React.Fragment>
            <Typography display="inline"> with estimated potential savings of </Typography>
            <Typography display="inline" className={classes.label}>
              {body.savings_percent}%.
            </Typography>
          </React.Fragment>
        )}
      </Grid>
    </>
  )
}

export default ComparisonText
