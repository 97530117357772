// The reducer for the sections branch of the global state
// -------------------------------------------------------

import * as actions from '../actions/index'

function sections (state, action) {
  if (state === undefined)
    return {}

  switch (action.type) {
    case actions.TOGGLE_LEVEL_TWO:
      return {
        ...state,
        [action.sectionCode]: {
          ...state[action.sectionCode],
          levelTwoVisible: !state[action.sectionCode].levelTwoVisible
        }
      }
    case actions.TOGGLE_LEVEL_THREE:
      return {
        ...state,
        [action.sectionCode]: {
          ...state[action.sectionCode],
          levelThreeVisible: !state[action.sectionCode].levelThreeVisible
        }
      }
    case actions.SHOW_QUESTION_SET: {
      let currentQuestionSet = state[action.sectionCode].currentQuestionSet

      const shouldToggle = currentQuestionSet &&
        currentQuestionSet.leadValue === action.leadValue &&
        currentQuestionSet.setIndex === action.setIndex

      if (shouldToggle) {
        currentQuestionSet = undefined
      } else {
        currentQuestionSet = {
          leadValue: action.leadValue,
          setIndex: action.setIndex
        }
      }

      return {
        ...state,
        [action.sectionCode]: {
          ...state[action.sectionCode],
          currentQuestionSet
        }
      }
    }
    default:
      return state
  }
}

export default sections
