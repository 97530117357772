// TEAMS
import {
  AddTeamResult,
  CommonResult,
  MutationAddTeamArgs,
  MutationTeamArgs,
  MyTeam,
  TeamMutationUpgradeToPartnerArgs,
  TeamMutationUpgradeToSupplierArgs,
} from '../../__generated__/graphql'
import { GraphqlResponse } from '../../common/lib/api-service'

export const CreateTeam = /* GraphQL */ `
  mutation AddTeam($name: String!) {
    addTeam(name: $name) {
      record {
        id
      }
      errors
      status
    }
  }
`
export type CreateTeamArgs = MutationAddTeamArgs
export type CreateTeamResult = Pick<AddTeamResult, 'status'> & {
  record: Pick<MyTeam, 'id'>
  errors: Record<string, string[]>
}
export type CreateTeamResponse = GraphqlResponse<{ addTeam: CreateTeamResult }>

export const UpgradeTeamPro = /* GraphQL */ `
  mutation UpgradeTeamSupplier($teamId: Int!) {
    team(teamId: $teamId) {
      upgradeToPro {
        errors
        status
      }
    }
  }
`
export type UpgradeTeamProArgs = MutationTeamArgs
export type UpgradeTeamProResult = CommonResult
export type UpgradeTeamProResponse = GraphqlResponse<{
  team: { upgradeToPro: UpgradeTeamSupplierResult }
}>

export const UpgradeTeamSupplier = /* GraphQL */ `
  mutation UpgradeTeamSupplier($teamId: Int!) {
    team(teamId: $teamId) {
      upgradeToSupplier {
        errors
        status
      }
    }
  }
`
export type UpgradeTeamSupplierArgs = MutationTeamArgs & TeamMutationUpgradeToSupplierArgs
export type UpgradeTeamSupplierResult = CommonResult
export type UpgradeTeamSupplierResponse = GraphqlResponse<{
  team: { upgradeToSupplier: UpgradeTeamSupplierResult }
}>

export const UpgradeTeamPartner = /* GraphQL */ `
  mutation UpgradeTeamPartner($teamId: Int!, $partnerCode: String!) {
    team(teamId: $teamId) {
      upgradeToPartner(partnerCode: $partnerCode) {
        errors
        status
      }
    }
  }
`
export type UpgradeTeamPartnerArgs = MutationTeamArgs & TeamMutationUpgradeToPartnerArgs
export type UpgradeTeamPartnerResult = CommonResult
export type UpgradeTeamPartnerResponse = GraphqlResponse<{
  team: { upgradeToPartner: UpgradeTeamSupplierResult }
}>
