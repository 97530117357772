import React, { FunctionComponent } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Grid, Typography } from '@mui/material'
import { CompositionCommentaryType } from '../types'

const useStyles = makeStyles()({
  label: {
    fontWeight: 550,
  },
})

type Props = {
  body: CompositionCommentaryType
}

const CompositionText: FunctionComponent<Props> = ({ body }) => {
  const { classes } = useStyles()
  return (
    <React.Fragment>
      {body.appliances && body.appliances[0] && (
        <Grid item xs={12} sm={6} md={4}>
          <Typography display="inline">
            {body.appliances[0].share} of your {body.consumption} is attributable to your{' '}
          </Typography>
          <Typography display="inline" className={classes.label}>
            {body.appliances[0]['name']} ({body.appliances[0]['percent']}%).
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={4}>
        {body.appliances &&
          body.appliances.map((appliance, index) => (
            <React.Fragment key={index}>
              {body.appliances.length > 1 && index === 1 && (
                <React.Fragment>
                  <Typography display="inline">This is followed by your </Typography>
                  <Typography display="inline" className={classes.label}>
                    {appliance['name']} ({appliance['percent']}%)
                  </Typography>
                </React.Fragment>
              )}
              {body.appliances.length > 1 && index > 1 && (
                <Typography display="inline">,</Typography>
              )}
              {body.appliances.length > 2 && index === body.appliances.length - 1 && (
                <Typography display="inline"> and</Typography>
              )}
              {body.appliances.length > 2 && index > 1 && (
                <React.Fragment>
                  <Typography display="inline"> your </Typography>
                  <Typography display="inline" className={classes.label}>
                    {appliance['name']} ({appliance['percent']}%)
                  </Typography>
                </React.Fragment>
              )}
              {index === body.appliances.length - 1 && <Typography display="inline">.</Typography>}
            </React.Fragment>
          ))}
      </Grid>
    </React.Fragment>
  )
}

export default CompositionText
