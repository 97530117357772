import { GraphQL, GraphQLCacheValue } from 'graphql-react'
import _ from 'underscore'
import {
  AuditReportSection,
  AuditReportVersion,
  MutationsApplyTeamInviteArgs,
  MutationsCancelTeamInviteArgs,
  MutationsConfirmCreditPurchaseStatusArgs,
  MutationsCreateTeamInviteArgs,
  MutationsRemoveTeamMemberArgs,
  MutationsResendTeamInviteArgs,
  MutationsUpdateTeamLogoArgs,
  MutationsUpdateTeamMemberArgs,
  MutationsUpdateTeamReportFooterArgs,
  MutationsUpdateTeamReportHeaderArgs,
  MutationsUpdateTeamReportHeroArgs,
  MutationsUpdateTeamReportOptionsArgs,
  MutationsUpdateTeamReportTextArgs,
  MutationsUpdateUserDetailsArgs,
  MyTeamTypeInvitesArgs,
  QueryTeamArgs,
} from '../../__generated__/graphql-v1'
import { permissionMapping } from '../../audit/lib/enhance-app-data'
import {
  StoreThunkAction,
  StoreThunkDispatch,
} from '../../common/lib/store-types'
import { RouterHistory } from '../../common/lib/util-types'
import * as actions from '../actions'
import * as api from '../../common/lib/api-service'
import {
  UpdateUserDetailsDb,
  UpdateTeamLogoDb,
  UpdateTeamReportHeaderDb,
  UpdateTeamReportFooterDb,
  UpdateTeamReportHeroDb,
  UpdateTeamReportTextDb,
  UpdateTeamReportOptions,
  RemoveTeamMemberDb,
  UpdateTeamMemberDb,
  StripeConfirmPaymentStatus,
  CreateTeamInvite,
  CancelTeamInvite,
  ApplyTeamInvite,
  ResendTeamInvite,
} from './mutations'
import { GetCreditDetails, GetTeamDetails, GetCheckoutSession } from './queries'
import { graphqlUploadOptions } from '../../common/lib/api-service'

export const stripeConfirmPaymentStatus = (
  teamId: number,
  productSku: string,
  checkoutSessionId: string,
  status: string
): StoreThunkAction => {
  return (dispatch, getState) => {
    const variables: MutationsConfirmCreditPurchaseStatusArgs = {
      teamId,
      productSku,
      checkoutSessionId,
      status,
    }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: StripeConfirmPaymentStatus, variables }),
    })
    dispatch(actions.closeCheckoutDialog())
    dispatch(actions.processStripePayment())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => {
        const message = status === 'success' ? 'Payment Successful' : 'Payment cancelled'
        dispatch(actions.openAccountAlertDialog(message))
      })
      .then(() => {
        dispatch(getCreditDetails(teamId))
      })
      .catch(
        api.catchApiErrorWith((error) => {
          dispatch(actions.openAccountAlertDialog('Payment Error: ' + error.message)) // These are mostly caught by Stripe payment modal
          dispatch(actions.requestProcessStripePaymentError(error.message))
        })
      )
  }
}

export const getNewCheckOutSession = (teamId: number, productSku: string): StoreThunkAction => {
  return (dispatch, getState) => {
    const returnToUrl = window.location.href
    const variables = {
      teamId,
      productSku,
      returnToUrl,
    }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: GetCheckoutSession, variables }),
    })

    dispatch(actions.requestStripeCheckoutSession())

    return fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => {
        dispatch(actions.receiveStripeCheckoutSession(json.data.team.newCheckoutSessionId))
        return json.data.team.newCheckoutSessionId
      })
      .catch(
        api.catchApiErrorWith((error) => {
          dispatch(actions.requestStripeCheckoutSessionError(error.message))
        })
      )
  }
}

export const triggerPasswordResetEmail = (auth_email: string): StoreThunkAction => {
  return (dispatch) => {
    const auth0Endpoint = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        client_id: `${process.env.AUTH0_CLIENTID}`,
        email: auth_email,
        connection: 'EcologicUsers',
      }),
      json: true,
    }

    dispatch(actions.requestPasswordResetEmail())

    fetch(auth0Endpoint, options)
      .then(api.checkStatus)
      .then((response) => {
        dispatch(actions.receivePasswordResetEmail())
        dispatch(
          actions.openAccountAlertDialog(
            'Please check your email for instructions to reset your password.'
          )
        )
      })
      .catch(
        api.catchApiErrorWith((error) => {
          dispatch(actions.requestPasswordResetEmailError(error))
          dispatch(
            actions.openAccountAlertDialog(
              'There was an error triggering the email to reset your password. Please try again.'
            )
          )
        })
      )
  }
}

export const updateUserDetailsDb = (values: MutationsUpdateUserDetailsArgs): StoreThunkAction => {
  return (dispatch, getState) => {
    const { firstName, lastName, email } = values

    const variables: MutationsUpdateUserDetailsArgs = { firstName, lastName, email }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: UpdateUserDetailsDb, variables }),
    })

    dispatch(actions.requestUpdateUserDetails())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => {
        dispatch(actions.receiveUpdatedUserDetails(json))
        dispatch(actions.updateUserDetails(values))
      })
      .catch(
        api.catchApiErrorWith((error) => {
          dispatch(actions.requestUpdateUserDetailsError(error.message))
        })
      )
  }
}

export const updateTeamReportTextDb = (
  text: Pick<MutationsUpdateTeamReportTextArgs, 'introText' | 'trailingText'>,
  teamId: number
): StoreThunkAction => {
  return (dispatch, getState) => {
    const introText = text.introText
    const trailingText = text.trailingText

    const variables: MutationsUpdateTeamReportTextArgs = { introText, trailingText, teamId }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: UpdateTeamReportTextDb, variables }),
    })

    dispatch(actions.requestUpdateReportText())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => {
        dispatch(actions.receiveUpdateReportText(introText, trailingText, teamId))
      })
      .catch(
        api.catchApiErrorWith((error) => {
          dispatch(actions.requestUpdateReportTextError(error.message))
        })
      )
  }
}

export const updateTeamReportOptions = (
  allSections: Record<AuditReportSection, boolean>,
  showOffers: boolean,
  showSavingsPayback: boolean,
  reportDefaultTypeStr: string,
  teamId: number
): StoreThunkAction => {
  return (dispatch, getState) => {
    const sections = _.keys(
      _.pick(allSections, (section) => {
        return section === true
      })
    ) as AuditReportSection[]
    const reportDefaultType = reportDefaultTypeStr.toUpperCase() as AuditReportVersion
    const variables: MutationsUpdateTeamReportOptionsArgs = {
      sections,
      showOffers,
      showSavingsPayback,
      reportDefaultType,
      teamId,
    }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: UpdateTeamReportOptions, variables }),
    })

    dispatch(actions.requestUpdateTeamReportOptions())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => {
        dispatch(
          actions.receiveUpdateTeamReportOptions(
            sections,
            showOffers,
            showSavingsPayback,
            reportDefaultType,
            teamId
          )
        )
      })
      .catch(
        api.catchApiErrorWith((error) => {
          dispatch(actions.requestUpdateTeamReportOptionsError(error.message))
        })
      )
  }
}

export const updateTeamLogoDb = (file: File, teamId: number): StoreThunkAction => {
  return (dispatch, getState) => {
    const graphql = new GraphQL()
    const variables: MutationsUpdateTeamLogoArgs = { file, teamId }

    dispatch(actions.requestUploadImage('logo'))

    graphql.operate({
      operation: { query: UpdateTeamLogoDb, variables },
      fetchOptionsOverride: (options) =>
        Object.assign(options, graphqlUploadOptions(getState().token)),
    })

    graphql.on('cache', reportCacheErrors(dispatch, teamId, 'logo'))
  }
}

export const updateTeamReportFooterDb = (file: File, teamId: number): StoreThunkAction => {
  return (dispatch, getState) => {
    const graphql = new GraphQL()
    const variables: MutationsUpdateTeamReportFooterArgs = { file, teamId }
    dispatch(actions.requestUploadImage('footer'))

    graphql.operate({
      operation: { query: UpdateTeamReportFooterDb, variables },
      fetchOptionsOverride: (options) =>
        Object.assign(options, graphqlUploadOptions(getState().token)),
    })

    graphql.on('cache', reportCacheErrors(dispatch, teamId, 'footer'))
  }
}

export const updateTeamReportHeaderDb = (file: File, teamId: number): StoreThunkAction => {
  return (dispatch, getState) => {
    const graphql = new GraphQL()
    const variables: MutationsUpdateTeamReportHeaderArgs = { file, teamId }
    dispatch(actions.requestUploadImage('header'))

    graphql.operate({
      operation: { query: UpdateTeamReportHeaderDb, variables },
      fetchOptionsOverride: (options) =>
        Object.assign(options, graphqlUploadOptions(getState().token)),
    })

    graphql.on('cache', reportCacheErrors(dispatch, teamId, 'header'))
  }
}

export const updateTeamReportHeroDb = (file: File, teamId: number): StoreThunkAction => {
  return (dispatch, getState) => {
    const graphql = new GraphQL()
    const variables: MutationsUpdateTeamReportHeroArgs = { file, teamId }
    dispatch(actions.requestUploadImage('hero'))

    graphql.operate({
      operation: { query: UpdateTeamReportHeroDb, variables },
      fetchOptionsOverride: (options) =>
        Object.assign(options, graphqlUploadOptions(getState().token)),
    })

    graphql.on('cache', reportCacheErrors(dispatch, teamId, 'hero'))
  }
}

export const editMemberRoleDb = (
  userId: number,
  roleValue: string | number,
  teamId: number
): StoreThunkAction => {
  return (dispatch, getState) => {
    const role = permissionMapping(roleValue)!
    const variables: MutationsUpdateTeamMemberArgs = { userId, role, teamId }

    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: UpdateTeamMemberDb, variables }),
    })

    dispatch(actions.requestEditTeamMemberDetails())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => dispatch(actions.receiveEditTeamMemberDetails(userId, roleValue, teamId)))
      .catch(
        api.catchApiErrorWith((error) => {
          dispatch(actions.requestEditTeamMemberDetailsError(error.message))
        })
      )
  }
}

export const deleteTeamMemberDb = (userId: number, teamId: number): StoreThunkAction => {
  return (dispatch, getState) => {
    const variables: MutationsRemoveTeamMemberArgs = { userId, teamId }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: RemoveTeamMemberDb, variables }),
    })

    dispatch(actions.requestDeleteTeamMember())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => dispatch(actions.receiveDeleteTeamMember(userId, teamId)))
      .catch(
        api.catchApiErrorWith((error) => {
          dispatch(actions.requestDeleteTeamMemberError(error.message))
        })
      )
  }
}

export const createTeamInvite = (
  teamId: number,
  roleValue: number,
  email: string
): StoreThunkAction => {
  return (dispatch, getState) => {
    const role = permissionMapping(roleValue)!
    const variables: MutationsCreateTeamInviteArgs = { teamId, role, email }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: CreateTeamInvite, variables }),
    })

    dispatch(actions.requestCreateTeamInvite())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => {
        if (json.errors) {
          dispatch(actions.openAccountAlertDialog('Error: ' + json.errors[0].message))
        } else {
          dispatch(actions.receiveCreateTeamInvite(json.data))
          dispatch(getTeamDetails(teamId))
        }
      })
      .catch(
        api.catchApiErrorWith((error) => {
          dispatch(actions.requestCreateTeamInviteError(error.message))
        })
      )
  }
}

export const cancelTeamInvite = (teamId: number, code: string): StoreThunkAction => {
  return (dispatch, getState) => {
    const variables: MutationsCancelTeamInviteArgs = { teamId, code }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: CancelTeamInvite, variables }),
    })

    dispatch(actions.requestCancelTeamInvite())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => {
        if (json.errors) {
          dispatch(actions.openAccountAlertDialog('Error: ' + json.errors[0].message))
        } else {
          dispatch(actions.receiveCancelTeamInvite(json.data))
          dispatch(getTeamDetails(teamId))
        }
      })
      .catch(
        api.catchApiErrorWith((error) => {
          dispatch(actions.requestCancelTeamInviteError(error.message))
        })
      )
  }
}

export const resendTeamInvite = (teamId: number, code: string): StoreThunkAction => {
  return (dispatch, getState) => {
    const variables: MutationsResendTeamInviteArgs = { teamId, code }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: ResendTeamInvite, variables }),
    })

    dispatch(actions.requestResendTeamInvite())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => {
        if (json.errors) {
          dispatch(actions.openAccountAlertDialog('Error: ' + json.errors[0].message))
        } else {
          dispatch(actions.receiveResendTeamInvite(json.data))
          dispatch(getTeamDetails(teamId))
        }
      })
      .catch(
        api.catchApiErrorWith((error) => {
          dispatch(actions.requestResendTeamInviteError(error.message))
        })
      )
  }
}

export const applyTeamInvite = (
  history: RouterHistory,
  code: string,
  token: string
): StoreThunkAction => {
  return (dispatch) => {
    const variables: MutationsApplyTeamInviteArgs = { code }
    const options = Object.assign({}, api.defaultOptions(token), {
      body: JSON.stringify({ query: ApplyTeamInvite, variables }),
    })

    dispatch(actions.requestApplyTeamInvite())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => {
        json.errors
          ? dispatch(
              actions.requestApplyTeamInviteError({ level: 'Error', text: json.errors[0].message })
            )
          : dispatch(actions.receiveApplyTeamInvite(json.data))
        dispatch(api.fetchAuth(history, token))
      })
      .catch(
        api.catchApiErrorWith((error) => {
          dispatch(actions.requestApplyTeamInviteError({ level: 'Error', text: error.message }))
          dispatch(api.fetchAuth(history, token))
        })
      )
  }
}

export const getCreditDetails = (teamId: number): StoreThunkAction => {
  return (dispatch, getState) => {
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: GetCreditDetails }),
    })

    dispatch(actions.requestGetCreditDetails())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => {
        dispatch(actions.receiveGetCreditDetails(teamId, json.data))
      })
      .catch(
        api.catchApiErrorWith((error) => {
          dispatch(actions.requestGetCreditDetailsError(error.message))
        })
      )
  }
}

export const getTeamDetails = (teamId: number): StoreThunkAction => {
  return (dispatch, getState) => {
    const expired = true
    const accepted = false
    const declined = true
    const cancelled = true
    const variables: QueryTeamArgs & MyTeamTypeInvitesArgs = {
      teamId,
      expired,
      accepted,
      declined,
      cancelled,
    }
    const options = Object.assign({}, api.defaultOptions(getState().token), {
      body: JSON.stringify({ query: GetTeamDetails, variables }),
    })

    dispatch(actions.requestTeamDetails())

    fetch(api.graphqlEndpoint, options)
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => {
        dispatch(actions.receiveTeamDetails(teamId, json.data.team))
      })
      .catch(
        api.catchApiErrorWith((error) => {
          dispatch(actions.requestTeamDetailsError(error.message))
        })
      )
  }
}

export const reportCacheErrors =
  (dispatch: StoreThunkDispatch, teamId: number, image: string) =>
  ({
    cacheKey,
    cacheValue: { fetchError, httpError, parseError, graphQLErrors, data },
  }: {
    cacheKey: string
    cacheValue: GraphQLCacheValue<any>
  }) => {
    if (data) {
      return dispatch(actions.receiveUploadImageSuccess(data, teamId, image))
    }
    let errorType = fetchError || httpError || parseError || graphQLErrors
    if (errorType) {
      let consolidate_errors = `Error ${cacheKey}:`
      if (fetchError) {
        consolidate_errors += ` ${fetchError} `
      }
      if (httpError) {
        consolidate_errors += ` ${httpError.status} ${httpError.statusText} `
      }
      if (parseError) {
        consolidate_errors += ` ${parseError} `
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => (consolidate_errors += ` ${message} `))
      }
      dispatch(actions.openAccountAlertDialog('Error: ' + consolidate_errors))
      return dispatch(actions.requestUploadImageError(consolidate_errors, image))
    }
  }
