import * as api from '../../common/lib/api-service'
import { receiveTeamDataV2, requestAppDataError } from '../../common/actions'
import {
  catchApiErrorWith,
  checkGraphqlErrors,
  SimpleResponse,
  simplifyGraphqlResponse
} from "../../common/lib/api-service"
import { StoreThunkAction } from '../../common/lib/store-types'
import {
  CreateTeam,
  CreateTeamArgs,
  CreateTeamResponse,
  CreateTeamResult,
  UpgradeTeamPartner,
  UpgradeTeamPartnerArgs,
  UpgradeTeamPartnerResponse,
  UpgradeTeamPartnerResult,
  UpgradeTeamPro,
  UpgradeTeamProArgs,
  UpgradeTeamProResponse,
  UpgradeTeamProResult,
  UpgradeTeamSupplier,
  UpgradeTeamSupplierArgs,
  UpgradeTeamSupplierResponse,
  UpgradeTeamSupplierResult,
} from './mutations-v2'
import { GetMyTeam, GetMyTeamArgs, GetMyTeamData, GetMyTeamResult } from './queries-v2'

export const getMyTeam = async (
  token: string,
  variables: GetMyTeamArgs
): Promise<SimpleResponse<GetMyTeamResult | undefined>> => {
  const options = api.createGraphqlFetchOptions(token, GetMyTeam, variables)

  try {
    const response = await fetch(api.graphqlEndpointV2, options)
    api.checkStatus(response)
    const json = await checkGraphqlErrors<GetMyTeamData>('myTeam')(response)
    const data = json.data.myTeam
    return simplifyGraphqlResponse(json, data)
  } catch (error) {
    api.catchApiErrorWith()(error)
    return simplifyGraphqlResponse(undefined, undefined, error)
  }
}

export const fetchMyTeamStore = (teamId: string | number): StoreThunkAction => {
  return (dispatch, getState) => {
    const { token } = getState()
    const vars: GetMyTeamArgs = {
      teamId: typeof teamId === 'number' ? teamId : parseInt(teamId, 10),
    }
    const options = api.createGraphqlFetchOptions(token, GetMyTeam, vars)

    fetch(api.graphqlEndpointV2, options)
      .then(api.checkStatus)
      .then(checkGraphqlErrors('myTeam'))
      .then((json) => {
        dispatch(receiveTeamDataV2(json))
      })
      .catch(
        catchApiErrorWith((error) => {
          dispatch(requestAppDataError(error.message))
        })
      )
  }
}

export const createTeam = async (
  token: string,
  variables: CreateTeamArgs
): Promise<SimpleResponse<CreateTeamResult | undefined>> => {
  const options = api.createGraphqlFetchOptions(token, CreateTeam, variables)

  try {
    const response = await fetch(api.graphqlEndpointV2, options)
    api.checkStatus(response)
    const json: CreateTeamResponse = await response.json()
    const data = json?.data?.addTeam
    return simplifyGraphqlResponse(json, data)
  } catch (error) {
    api.catchApiErrorWith()(error)
    return simplifyGraphqlResponse(undefined, undefined, error)
  }
}
export const upgradeProTeam = async (
  token: string,
  variables: UpgradeTeamProArgs
): Promise<SimpleResponse<UpgradeTeamProResult | undefined>> => {
  const options = api.createGraphqlFetchOptions(token, UpgradeTeamPro, variables)

  try {
    const response = await fetch(api.graphqlEndpointV2, options)
    api.checkStatus(response)
    const json: UpgradeTeamProResponse = await response.json()
    const data = json?.data?.team?.upgradeToPro
    return simplifyGraphqlResponse(json, data)
  } catch (error) {
    api.catchApiErrorWith()(error)
    return simplifyGraphqlResponse(undefined, undefined, error)
  }
}

export const upgradeSupplierTeam = async (
  token: string,
  variables: UpgradeTeamSupplierArgs
): Promise<SimpleResponse<UpgradeTeamSupplierResult | undefined>> => {
  const options = api.createGraphqlFetchOptions(token, UpgradeTeamSupplier, variables)

  try {
    const response = await fetch(api.graphqlEndpointV2, options)
    api.checkStatus(response)
    const json: UpgradeTeamSupplierResponse = await response.json()
    const data = json?.data?.team?.upgradeToSupplier
    return simplifyGraphqlResponse(json, data)
  } catch (error) {
    api.catchApiErrorWith()(error)
    return simplifyGraphqlResponse(undefined, undefined, error)
  }
}

export const upgradePartnerTeam = async (
  token: string,
  variables: UpgradeTeamPartnerArgs
): Promise<SimpleResponse<UpgradeTeamPartnerResult | undefined>> => {
  const options = api.createGraphqlFetchOptions(token, UpgradeTeamPartner, variables)

  try {
    const response = await fetch(api.graphqlEndpointV2, options)
    api.checkStatus(response)
    const json: UpgradeTeamPartnerResponse = await response.json()
    const data = json?.data?.team?.upgradeToPartner
    return simplifyGraphqlResponse(json, data)
  } catch (error) {
    api.catchApiErrorWith()(error)
    return simplifyGraphqlResponse(undefined, undefined, error)
  }
}
