import React, { Suspense, lazy, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { auditCodeFromUrl, getParameterByName } from '../lib/util'
import { setAudit } from '../actions'
import { fetchAuditDetails } from '../lib/api-service'
import { segmentPageEvent } from '../lib/addons-config'
import CustomThemeProvider from './CustomThemeProvider'
import LoadingScreen from './loading-screen'
import RedirectOptions from './redirect-non-partner-sites'
import '../styles/global'
import ProRoute from './ProRoute'
import { fetchMyTeamStore } from "../../account/lib/api-v2-service"

const AccountScreen = lazy(() => import('../../account/components/screen'))
const ActScreen = lazy(() => import('../../act/components/Screen'))
const AssessScreen = lazy(() => import('../../assess/components/Screen'))
const AuditScreen = lazy(() => import('../../audit/components/Screen'))
const DashboardScreen = lazy(() => import('../../dashboard/components/Screen'))
const ResultsScreen = lazy(() => import('../../results/components/Screen'))
const RegisterProScreen = lazy(() => import('../../account/components/register-pro'))
const TeamActionsScreen = lazy(() => import('../../team-actions/screen'))
const TeamAuditsScreen = lazy(() => import('../../team-audits/screen'))
const TeamProjectsScreen = lazy(() => import('../../team-projects/screen'))
const TeamOffersScreen = lazy(() => import('../../team-offers/screen'))
const TeamMarketplaceScreen = lazy(() => import('../../team-market/screen'))
const TeamScreen = lazy(() => import('../../account/components/team'))
const TeamUpgradeScreen = lazy(() => import('../../account/components/team-upgrade'))
const TeamUpgradeDetailsScreen = lazy(() => import('../../account/components/team-upgrade-details'))


const AuthenticatedRoutes = (props) => {
  const {
    location,
    audits,
    token,
    dispatch,
    currentAudit,
    isLoaded,
    me: {
      professional: pro,
      teams,
    },
    myTeam,
    site,
  } = props

  const setCurrentAudit = (prevProps = { location: {} }) => {
    const code = auditCodeFromUrl(location)
    let requestAudit = false
    if (!currentAudit && code && token) {
      requestAudit = true
    } else if (location.pathname !== prevProps.location.pathname
      || location.hash !== prevProps.location.hash) {
      segmentPageEvent(location.pathname)
      if (code && (code !== prevProps.currentAudit) && token) {
        requestAudit = true
      }
    }
    if (requestAudit) {
      dispatch(setAudit(code))
      audits[code] || dispatch(fetchAuditDetails(code))
    }
  }

  const setCurrentTeam = () => {
    const currentTeamId = myTeam ? myTeam.id.toString() : undefined
    const path = location.pathname
    const groups = /team\/(\d+)/.exec(path)
    const newTeamId = groups ? groups[1] : undefined
    if (newTeamId && newTeamId !== currentTeamId) {
      dispatch(fetchMyTeamStore(newTeamId))
    }
  }

  useEffect(() => {
    setCurrentAudit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCurrentAudit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (!token) {
      // This can be loaded before the auth is loaded
      return
    }
    setCurrentTeam()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, token])


  if (isLoaded && !site) {
    return (
      <CustomThemeProvider>
        <RedirectOptions />
      </CustomThemeProvider>
    )
  }

  return (<>
    {/* if they came in from the /start flow & have errors, go back to audit */}
    {getParameterByName('completedSurvey') && props.serverValidationErrors &&
      <Redirect to={`/audit/${currentAudit}/`} />
    }

    <LoadingScreen isLoaded={isLoaded}>
      <Suspense fallback={<LoadingScreen isLoaded={false} />}>
        <Switch>
          <Route path="/audit/:auditHash/result" component={ResultsScreen} />
          <Route path="/audit/:auditHash/" component={AuditScreen} />
          <Route path="/assess/:auditHash/" component={AssessScreen} />
          <Route path="/act/plan/:auditHash/" component={ActScreen} />
          <ProRoute path="/team/:teamId/audit/" component={TeamAuditsScreen} pro={pro} />
          <ProRoute path="/team/:teamId/action/" component={TeamActionsScreen} pro={pro} />
          <ProRoute path="/team/:teamId/project/" component={TeamProjectsScreen} pro={pro} />
          <ProRoute path="/team/:teamId/offer/" component={TeamOffersScreen} pro={pro} />
          <ProRoute path="/team/:teamId/marketplace/" component={TeamMarketplaceScreen} pro={pro} />
          <Route path="/account/user/" component={AccountScreen} />
          <Route path="/register-pro" component={RegisterProScreen} />
          <Route path="/register-supplier" component={RegisterProScreen} />
          <ProRoute path="/account/team/:teamId/upgrade/:planId" component={TeamUpgradeDetailsScreen} pro={pro} />
          <ProRoute path="/account/team/:teamId/upgrade" component={TeamUpgradeScreen} pro={pro} />
          <ProRoute path="/account/team/:teamId" component={TeamScreen} pro={pro} />
          <ProRoute path="/account/team"
                    component={() => <Redirect to={`/account/team/${teams[0].id}`} />} pro={pro} />
          <Route path="/:auditHash?" render={(props) => {
            return pro && location.hash.length === 0
              ? <Redirect to={`/team/${teams[0].id}/${teams[0].isSupplier && !teams[0].isPartner ? 'project' : 'audit'}/`} />
              : <DashboardScreen {...props} />
          }} />
        </Switch>
      </Suspense>
    </LoadingScreen>
  </>)
}

const mapStateToProps = ({ myTeam, serverValidationErrors, ...state }) => {
  const isLoaded = state.authCheckReceived && state.appDataReceived && state.auditDetailReceived
  return {
    isLoaded,
    serverValidationErrors,
    myTeam
  }
}

export default connect(mapStateToProps)(AuthenticatedRoutes)
