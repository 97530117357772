import { useHistory } from 'react-router-dom'

/**
 * Pass "enabled" prop to activate on local dev
 * https://stackoverflow.com/questions/34093913/how-to-debug-react-router
 * https://stephencharlesweiss.com/react-router-debugging-the-router
 */
export default function DebugRouter({
  children,
  enabled,
}: React.PropsWithChildren<{ enabled?: boolean }>) {
  const { location, listen } = useHistory()
  if (process.env.NODE_ENV === 'development' && enabled) {
    console.log(
      `[DebugRouter] Initial Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
        location.state
      )}`
    )
    listen((location, action) => {
      console.log(`[DebugRouter] Navigation: ${action}`, location)
    })
  }

  return children
}
