// Main entry point for the Ecologic web-ui client;
// The redux store is created here and passed into the root react component.
// -------------------------------------------------------------------------
import React from 'react'
import ReactDOM from 'react-dom'
import { Auth0Provider } from "./react-auth0-spa"

import { logrocketConfig } from './common/lib/logrocket-config'
import { overrideSegmentAnonymousId } from './common/lib/addons-config'
import './common/lib/google-maps-loader'
import './common/lib/status-script-loader'

import { setupStore } from './common/lib/store'
import Root from './common/components/Root'

import { serviceWorkerInit, serviceWorkerUpdate } from './common/actions'
import * as serviceWorkerRegistration from './service-worker-registration'

// The root DOM element where we will render our react root component
const rootEl = document.getElementById('root')

// Setup the global data store.
const store = setupStore()

logrocketConfig()
overrideSegmentAnonymousId()

// Route the user to the url they requested after login
const onRedirectCallback = appState => {
  const { pathname } = window.location
  if (appState && appState.returnTo && (appState.returnTo !== pathname)) {
    window.location = appState.returnTo
  } else {
    window.history.replaceState({}, document.title, pathname)
  }
}

// Render the root component into the DOM
ReactDOM.render(
  // <React.StrictMode>
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    client_id={process.env.REACT_APP_AUTH0_CLIENTID}
    redirect_uri={window.location.origin}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <Root store={store} />
  </Auth0Provider>
  // </React.StrictMode>
  , rootEl
)


//  TODO: It is an anti-pattern to interact with the store directly in a React component
serviceWorkerRegistration.register({
  onSuccess: () => store.dispatch(serviceWorkerInit()),
  onUpdate: reg => store.dispatch(serviceWorkerUpdate(reg))
})
