import * as React from 'react'
import { FunctionComponent } from 'react'
import { formatBalanceRoundedPercentages } from '../../../common/lib/util'
import { GenericBodyType } from '../../components/generic-text'
import Section from '../../components/section'
import SolarVenn from '../../components/SolarVenn'
import { ElectricityBalance } from '../types'

export const ElectricityBalanceTemplate: FunctionComponent<{
  electricityBalance: ElectricityBalance
}> = ({ electricityBalance }) => {
  const { consumed, produced, offsetted, imported, exported } = electricityBalance.composition

  const genericBody: GenericBodyType = [
    [
      `Your solar panels generate `,
      { bold: true, text: `${produced.toFixed(0)}kWh` },
      ` of electricity each day on average.`,
    ],
  ]

  if (consumed > 0) {
    const fractionGeneratedOnsite = offsetted / consumed
    const fractionImported = imported / consumed
    const [percentGeneratedOnsite, percentImported] = formatBalanceRoundedPercentages([
      fractionGeneratedOnsite,
      fractionImported,
    ])
    genericBody.push([
      `Of the energy you consume, `,
      { bold: true, text: percentGeneratedOnsite },
      ` is generated onsite and `,
      { bold: true, text: percentImported },
      ` is imported from the grid.`,
    ])
  }
  if (produced > 0) {
    const fractionConsumedOnsite = offsetted / produced
    const fractionExported = exported / produced
    const [percentConsumedOnsite, percentExported] = formatBalanceRoundedPercentages([
      fractionConsumedOnsite,
      fractionExported,
    ])
    genericBody.push([
      `Of the energy you generate, `,
      { bold: true, text: percentConsumedOnsite },
      ` is consumed onsite and `,
      { bold: true, text: percentExported },
      ` is exported to the grid.`,
    ])
  }
  return (
    <Section
      title="How your solar panels perform"
      body={[
        'The chart below relates the electricity generated by your solar panels to the electricity you consume.',
        'The overlapping area therefore represents the amount of energy that is generated and consumed onsite.',
        'The non-overlapping areas represent exported or imported electricity.'
      ]}
      type="generic"
      customBody={genericBody}
      hasSuggestions={false}
      pane={<SolarVenn electricityBalance={electricityBalance} />}
    />
  )
}
