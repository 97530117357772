export const UpdateUserDetailsDb = /* GraphQL */`
  mutation UpdateUserDetails($firstName: String!, $lastName: String!, $email: String!) {
    updateUserDetails(firstName: $firstName, lastName: $lastName, email: $email) {
      result {
        __typename
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

export const UpdateTeamDetailsDb = /* GraphQL */`
  mutation UpdateTeamDetails($name: String!, $description: String!, $teamId: Int!) {
    updateTeamDetails(name: $name, description: $description, teamId: $teamId) {
      result {
        __typename
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

export const UpdateTeamReportTextDb = /* GraphQL */`
  mutation UpdateTeamReportText($introText: String!, $trailingText: String!, $teamId: Int!) {
    updateTeamReportText(introText: $introText, trailingText: $trailingText, teamId: $teamId) {
      result {
        __typename
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

export const UpdateTeamReportOptions = /* GraphQL */`
  mutation UpdateTeamReportOptions($sections: [AuditReportSection]!, $showOffers: Boolean!, $showSavingsPayback: Boolean!, $reportDefaultType: AuditReportVersion!, $teamId: Int!) {
    updateTeamReportOptions(sections: $sections, showOffers: $showOffers, showSavingsPayback: $showSavingsPayback, reportDefaultType: $reportDefaultType, teamId: $teamId) {
      result {
        __typename
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

export const AddTeamMemberDb = /* GraphQL */`
  mutation AddTeamMember($email: String!, $role: TeamRoles!, $teamId: Int!) {
    addTeamMember(email: $email, role: $role, teamId: $teamId) {
      result {
        __typename
        ... on AddTeamMemberSuccess {
          id
          userId
          username
        }
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

export const RemoveTeamMemberDb = /* GraphQL */`
  mutation RemoveTeamMember($userId: Int! $teamId: Int!) {
    removeTeamMember(userId: $userId, teamId: $teamId) {
      result {
        __typename
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

export const UpdateTeamMemberDb = /* GraphQL */`
  mutation UpdateTeamMember($userId: Int!, $role: TeamRoles!, $teamId: Int!) {
    updateTeamMember(userId: $userId, role: $role, teamId: $teamId) {
      result {
        __typename
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

// TEAM INVITES

export const CreateTeamInvite = /* GraphQL */`
  mutation CreateTeamInvite($teamId: Int!, $role: TeamRoles!, $email: String!) {
    createTeamInvite(teamId: $teamId, role: $role, email: $email) {
      result {
        __typename
        ... on InviteSuccess {
          code
        }
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

export const CancelTeamInvite = /* GraphQL */`
  mutation CancelTeamInvite($teamId: Int!, $code: String!) {
    cancelTeamInvite(teamId: $teamId, code: $code) {
      result {
        __typename
        ... on InviteSuccess {
          code
        }
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

export const ApplyTeamInvite = /* GraphQL */`
  mutation ApplyTeamInvite($code: String!) {
    applyTeamInvite(code: $code) {
      result {
        __typename
        ... on InviteSuccess {
          code
        }
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

export const ResendTeamInvite = /* GraphQL */`
  mutation ResendTeamInvite($teamId: Int!, $code: String!) {
    resendTeamInvite(teamId: $teamId, code: $code) {
      result {
        __typename
        ... on InviteSuccess {
          code
        }
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

export const UpdateTeamLogoDb = /* GraphQL */`
  mutation UpdateTeamLogo($file: Upload, $teamId: Int!) {
    updateTeamLogo(file: $file, teamId: $teamId) {
      result {
        __typename
        ... on UploadSuccess {
          url
        }
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

export const UpdateTeamReportHeaderDb = /* GraphQL */`
  mutation UpdateTeamReportHeader($file: Upload, $teamId: Int!) {
    updateTeamReportHeader(file: $file, teamId: $teamId) {
      result {
        __typename
        ... on UploadSuccess {
          url
        }
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

export const UpdateTeamReportFooterDb = /* GraphQL */`
  mutation UpdateTeamReportFooter($file: Upload, $teamId: Int!) {
    updateTeamReportFooter(file: $file, teamId: $teamId) {
      result {
        __typename
        ... on UploadSuccess {
          url
        }
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

export const UpdateTeamReportHeroDb = /* GraphQL */`
  mutation UpdateTeamReportHero($file: Upload, $teamId: Int!) {
    updateTeamReportHero(file: $file, teamId: $teamId) {
      result {
        __typename
        ... on UploadSuccess {
          url
        }
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`

export const StripeConfirmPaymentStatus = /* GraphQL */`
  mutation ConfirmCreditPurchaseStatus($productSku: String!, $teamId: Int!, $checkoutSessionId: String!, $status: String!) {
    confirmCreditPurchaseStatus(productSku: $productSku, teamId: $teamId, checkoutSessionId: $checkoutSessionId, status: $status) {
      result {
        __typename
        ... on Error {
          status
          message
          meta
        }
      }
    }
  }
`
