// The root React component for the Ecologic web-ui client;
// The routes are declared here and the router instantialised.
// -----------------------------------------------------------

import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import ErrorBoundary from './ErrorBoundary'
import ScrollToTop from './scroll-to-top'
import App from './App'
import DebugRouter from './DebugRouter'

export const doHttpsRedirect = (url) => {
  if (url.indexOf('localhost') > -1) return false
  return !(url.indexOf('https') > -1)
}

export const doPartnerRedirect = (host) => {
  const invalidPartnerPattern = new RegExp(/www\.(.+)\.ecologicapp(.+)/)
  return invalidPartnerPattern.test(host)
}

class Root extends Component {
  render() {
    // Redirect all www.partner.ecologicapp.com to partner.ecologicapp.com
    if (doPartnerRedirect(window.location.host)) {
      const partner = window.location.host.split('.')[1]
      window.location = `https://${partner}.ecologicapp.com`
    }

    // Redirect all http to https
    const url = window.location.href
    if (doHttpsRedirect(url)) {
      window.location = `https:${url.split(':')[1]}`
    }

    return (
      // Catch unhandled view errors in the entire component tree.
      <ErrorBoundary>
        <Provider store={this.props.store}>
          <Router>
            <DebugRouter>
              <ScrollToTop />
              <Route component={App} />
            </DebugRouter>
          </Router>
        </Provider>
      </ErrorBoundary>
    )
  }
}

export default Root
