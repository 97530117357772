export const siteFragment = /* GraphQL */ `
  fragment SiteFragment on Query {
    site {
      id
      name
      logo
      code
      theme
    }
  }
`
