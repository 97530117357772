// Common api service functions
import { AuditType } from "../../__generated__/graphql-v1"
// ----------------------------
import Logger from './logger'
import * as actions from '../actions'
import { AppData, AuditDetails, AuditSuggestions, AuthCheck } from './queries'
import { getAudits } from '../../team-dashboard/api'
import { identifyUserToAddons } from './addons-config'
import { startNewAudit } from '../../audit/lib/api-service'
import { StoreThunkAction, StoreThunkDispatch } from "./store-types"
import { RouterHistory } from "./util-types"

const graphqlEndpoint = `${process.env.REACT_APP_API_HOST}/graphql`
const graphqlEndpointV2 = `${process.env.REACT_APP_API_HOST}/ecologic/v2/graphql`

const defaultOptions = (token: string): RequestInit => ({
  credentials: 'same-origin',
  cache: 'no-cache',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  },
  method: 'POST',
})

export const fetchAuth = (history: RouterHistory, token: string, completedSurvey = false) => {
  return (dispatch: StoreThunkDispatch) => {
    const options = Object.assign({}, defaultOptions(token), {
      body: JSON.stringify({ query: AuthCheck }),
    })
    dispatch(actions.requestAuthCheck())

    fetch(graphqlEndpoint, options)
      .then(checkStatus)
      .then((response) => response.json())
      .then((json) => {
        dispatch(actions.receiveAuthCheck(json))
        dispatch(fetchAppData(history, completedSurvey))
      })
      .catch(catchApiErrorWith((error) => {
        const explanation = "An error occurred while logging into the app. Our team has been automatically notified but you can contact support@ecologicapp.com if the error persists."
        dispatch(actions.requestAuthCheckError('LOGIN ERROR', explanation))
        Logger.error(error)
      }))
  }
}


export const fetchAppData = (history: RouterHistory, completedSurvey = false): StoreThunkAction => {
  return (dispatch, getState) => {
    const options = Object.assign({}, defaultOptions(getState().token), {
      body: JSON.stringify({ query: AppData }),
    })

    dispatch(actions.requestAppData())

    fetch(graphqlEndpoint, options)
      .then(checkStatus)
      .then((response) => response.json())
      .then((json) => {
        dispatch(actions.receiveAppData(json))
        dispatch(identifyUserToAddons(json.data.me))
        const teamId = getState().me.professional ? getState().me.teams[0].id : null
        if (completedSurvey) {
          // @ts-expect-error
          dispatch(startNewAudit(history, teamId, { prefill: completedSurvey }))
        } else {
          // @ts-expect-error
          dispatch(getAudits(teamId, history))
        }
      })
      .catch(catchApiErrorWith((error) => {
        dispatch(actions.requestAppDataError(error.message))
      }))
  }
}

export const fetchAuditDetails = (code: string): StoreThunkAction => {
  return (dispatch, getState) => {
    const variables = { code }
    const options = Object.assign({}, defaultOptions(getState().token), {
      body: JSON.stringify({ query: AuditDetails, variables }),
    })

    dispatch(actions.requestAuditDetails())

    fetch(graphqlEndpoint, options)
      .then(checkStatus)
      .then((response) => response.json())
      .then((json) => {
        if (json.data.audit && json.data.audit.code) {
          dispatch(actions.receiveAuditDetails(json))
        } else {
          window.location.href = '/'
        }
      })
      .catch(catchApiErrorWith((error) => {
        dispatch(actions.requestAuditDetailsError(error.message))
      }))
  }
}

// An error type to signify that the audit is invalid
class ValidationError extends Error {
  response: Response
  constructor(response: Response) {
    super()
    this.name = 'ValidationError'
    this.response = response
  }
}

// An error type to signify errors relating to the API
class EcologicApiError extends Error {
  response: Response
  constructor(message: string, response: Response) {
    super(message)
    this.name = 'EcologicApiError'
    this.response = response
    this.stack = (new Error()).stack
  }
}

// Create a handler for any errors caught on API calls.
const catchApiErrorWith = (handler?: (e: Error) => unknown, validationHandler?: (e: ValidationError) => unknown) => {
  return (error: unknown) => catchApiError(error, handler, validationHandler)
}

type ErrorExtra = Record<string, string | number | boolean>

function catchApiError(
  error: unknown, // TypeScript defaults catch (e) to unknown type
  handler?: (e: Error) => unknown,
  validationHandler?: (e: ValidationError) => unknown,
  response?: Response,
  requestExtra?: Record<string, any>
) {
  if (error instanceof ValidationError && validationHandler) return validationHandler(error)
  if (error instanceof EcologicApiError) {
    getErrorExtra(error.response, requestExtra).then((extra) => {
      Logger.error(error, {
        extra,
      })
    })
    if (handler) {
      return handler(error)
    }
  }

  const wrappedError = error instanceof Error
    ? error
    : new Error(typeof error === 'string' ? error : '<unknown error>')
  getErrorExtra(response, requestExtra).then((extra) => {
    Logger.error(wrappedError, {
      extra,
    })
  })
}

async function getErrorExtra(response?: Response, requestExtra?: Record<string, any>) {
  const extra: ErrorExtra = {}
  if (requestExtra) {
    Object.entries(requestExtra).forEach(([key, value]) => {
      if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        extra[key] = value
      }
    })
  }
  if (response) {
    extra.requestUrl = response.url
    extra.responseStatus = response.status
    try {
      // Clone the response so other code can also read the body
      extra.responseBody = await response.clone().text()
    } catch (e) {
      extra.responseBodyError = e instanceof Error && e.message
    }
  }
  return extra
}

export const fetchAuditSuggestions = (search = null): StoreThunkAction => {
  return async (dispatch, getState) => {
    const offset = 0
    const limit = 10000
    const variables = { search, offset, limit }
    const options = Object.assign({}, defaultOptions(getState().token), {
      body: JSON.stringify({ query: AuditSuggestions, variables }),
    })

    dispatch(actions.requestAuditSuggestions(search))
    let results: AuditType[] = []
    await fetch(graphqlEndpoint, options)
      .then(checkStatus)
      .then((response) => response.json())
      .then((json) => {
        results = (json.data.allAudits.results).filter((result: AuditType) => result.address || result.contactName)
        dispatch(actions.receiveAuditSuggestions(results))
      })
      .catch(catchApiErrorWith((error) => {
        dispatch(actions.requestAuditSuggestionsError(error.message))
      }))
    return results
  }
}

// Throw an error when the audit cannot be created and handle the error message
const checkAuditCreationStatus = async (response: Response) => {
  // Clone the response so other code can also read the body
  const response_json = await response.clone().json()
  if (response.status === 201) {
    // Created new Audit, returning audit code
    return response_json
  } else if ([404, 410, 402, 403].includes(response.status)) {
    // NOTE: the server may be handling all errors on create audit as 410. Previously errors meant:
    // 404 NOT_FOUND if a team is supplied that doesn't exist or the user is not part of
    // 410 GONE if a team has run out of credits
    // 402 PAYMENT_REQUIRED if a user tries to create a second audit (they need to be a team)
    throw new EcologicApiError(response_json.message || response_json.detail, response)
  } else {
    checkStatus(response)
  }
}

// Throw an error when the response status is not 2xx.
const checkStatus = (response: Response) => {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else if (response.status === 400) {
    throw new ValidationError(response.clone())
  } else {
    throw new EcologicApiError(response.statusText, response)
  }
}

function checkGraphqlErrors<T = {[key: string]: any}>(key: keyof T) {
  return async (response: Response): Promise<VerifiedGraphqlResponse<T>> => {
    let error
    try {
      const body = await response.clone().json()
      const errors = getGraphQlErrors(body)
      if (errors.length) {
        error = errors[0]
      }
      if (!body.data[key]) {
        throw new Error('[caught locally] missing data')
      }
      return body
    } catch (e) {
      throw new EcologicApiError(error || 'error decoding api response', response)
    }
  }
}

/**
 * Only to be used for V1 requests that have a 'result' key.
 * @see checkGraphqlErrors
 */
function checkGraphqlResult<T = Record<string, any>>(key: string) {
  return async (response: Response): Promise<VerifiedGraphqlResponse<V1GraphqlResponse<T>>> => {
    try {
      const body = await checkGraphqlErrors<V1GraphqlResponse<T>>(key)(response)
      const result = body.data[key].result
      if (result && result['__typename'] !== "Error") {
        return body
      } else if (result && result['status'] === 400) {
        throw new ValidationError(response.clone())
      } else {
        throw new EcologicApiError(result.message, response)
      }
    } catch (e) {
      if (e instanceof ValidationError || e instanceof EcologicApiError) {
        throw e
      }
      throw new EcologicApiError('error decoding api response', response)
    }
  }
}

export const graphqlUploadOptions = (token: string, url = graphqlEndpoint) => ({
  url,
  credentials: 'same-origin',
  cache: 'no-cache',
  headers: {
    'Authorization': `Bearer ${token}`,
  },
})
function createGraphqlFetchOptions(token: string, query: string, variables: Record<string, unknown>): RequestInit {
  return {
    ...defaultOptions(token),
    body: JSON.stringify({ query, variables }),
  }
}
export type V1GraphqlResponse<T = Record<string, any>> = {
  [key: string]: {
    result: V1GraphqlResult & T
  }
}
export type V1GraphqlResult = {
  __typename: string
  status: number
  message: string
}
export type GraphqlError = {
  message: string
}
export type GraphqlResponse<T> = {
  data?: T,
  errors?: GraphqlError[]
}
export type VerifiedGraphqlResponse<T> = {
  data: T,
  errors?: GraphqlError[]
}

export type SimpleResponse<T = any> = {
  data?: T
  errors: string[]
  hasErrors: boolean
  successMsg?: string
}
export const simplifyGraphqlResponse = <T>(response?: { errors?: GraphqlError[] }, data?: T, caughtError?: unknown): SimpleResponse<T> => {
  const {
    errors,
    ...rest
  } = response || {}
  // Look for generic errors in the data too
  const genericErrors = getGraphQlErrors(response)

  if (caughtError instanceof Error) {
    genericErrors.push(caughtError.message)
  }
  const hasErrors = genericErrors.length > 0
  return {
    ...rest,
    data,
    errors: genericErrors,
    hasErrors,
  }
}

function getGraphQlErrors(response?: { errors?: GraphqlError[] }): string[] {
  const { errors } = response || {}
  return errors ? errors.map((e) => e.message) : []
}

export {
  catchApiErrorWith,
  catchApiError,
  checkStatus,
  checkAuditCreationStatus,
  graphqlEndpoint,
  graphqlEndpointV2,
  defaultOptions,
  createGraphqlFetchOptions,
  checkGraphqlErrors,
  checkGraphqlResult,
}
