/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import AdapterLink from '../common/lib/adapter-link'
import HeadlineData from './headline-data'
import ListLabelsDialog from "../team-dashboard/labels/list-labels-dialog"

import { getProjects, getActions, getAudits, getTeamsData } from "./api"
import { clearTableState } from "./actions"

import { Grid, Paper, Tabs, Tab } from '@mui/material'
import { useHistory } from "react-router-dom"

const mapStateToProps = ({ me: { teams }, listState, myTeam }) => ({
  teams,
  listState,
  myTeam
})

export const allTeamDataTypes = [
  {
    id: 0,
    key: 'audit',
    label: 'Profiles',
    checkShow: (team) => team.isPro || team.isPartner
  },
  {
    id: 1,
    key: 'action',
    label: 'Actions',
    checkShow: (team) => team.isPro || team.isPartner
  },
  {
    id: 2,
    key: 'project',
    label: 'Projects'
  },
  {
    id: 3,
    key: 'offer',
    label: 'Offers',
    hideHeadlineData: true,
    checkShow: (team) => team.isSupplier
  },
  {
    id: 4,
    key: 'marketplace',
    label: 'Marketplace',
    hideHeadlineData: true,
    checkShow: (team) => team.isPartner
  },
]

const TeamSubNav = props => {
  const { teams, dispatch, pathname, listState, myTeam } = props
  const history = useHistory()
  const teamId = pathname.match(/\d+/g) || null
  // eslint-disable-next-line eqeqeq
  const team = teams.find(team => team.id == teamId)

  const currentScreen = allTeamDataTypes.find(type => new RegExp(`^/team/.+/${type.key}`).test(pathname))
  const currentScreenKey = currentScreen?.key
  const getUrl = (key) => `/team/${teamId}/${key}/`

  const [currentTabId, setCurrentTabId] = useState(currentScreen.id)
  const visibleTeamDataTypes = allTeamDataTypes.filter(t => !t.checkShow || t.checkShow(myTeam || team))
  const isTabVisible = (tabIndex) => visibleTeamDataTypes.some(t => t.id === tabIndex)
  const getTabIndex = () => {
    const currentTabIndex = visibleTeamDataTypes.findIndex(t => t.id === currentTabId)
    return currentTabIndex >= 0 ? currentTabIndex : 0
  }
  

  useEffect(() => {
    // on component mount
    dispatch(getTeamsData())
    if (listState && !listState.auditList) {
      dispatch(getAudits(team.id))
    }
    if (listState && !listState.projectList) {
      dispatch(getProjects(team.id))
    }
    if (listState && !listState.actionList) {
      dispatch(getActions(team.id))
    }
  }, [])

  useEffect(() => {
    if (isTabVisible(currentScreen.id)) {
      setCurrentTabId(currentScreen.id) 
    } else {
      const url = getUrl(visibleTeamDataTypes[0].key)
      console.log(`On invalid tab, redirecting to ${url}`, { currentTabId, tabs: visibleTeamDataTypes.map(({ id, key }) => ({ id, key })) })
      history.replace(url)
    }
  }, [pathname])

  useEffect(() => {
    if (listState?.currentTeamId !== team.id) {
      dispatch(clearTableState())
      dispatch(getAudits(team.id))
      dispatch(getProjects(team.id))
      dispatch(getActions(team.id))
    }
  }, [team.id])

  const handleChange = (event, newValue) => {
    setCurrentTabId(newValue)
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Tabs
              value={getTabIndex()}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              variant='fullWidth'
            >
              {visibleTeamDataTypes.map(({ key, hideHeadlineData, checkShow, ...rest }) => (
                <Tab {...rest} key={key} component={AdapterLink} to={getUrl(key)} />
              ))}
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {!currentScreen.hideHeadlineData &&
            <HeadlineData team={team} currentScreen={currentScreenKey} />
          }
        </Grid>
      </Grid>
      <ListLabelsDialog />
    </React.Fragment>)
}
export default connect(mapStateToProps)(TeamSubNav)
