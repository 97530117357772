import React from 'react'
import { makeStyles } from 'tss-react/mui'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share'
import { segmentEvent } from '../../lib/addons-config'

const useStyles = makeStyles()({
  icon: {
    cursor: 'pointer',
    '&:hover:not(:active)': {
      opacity: 0.75,
    },
  },
})

export default function SharingButtons(props) {
  const { url, message, iconSize, round, theme, dispatch } = props
  const { classes } = useStyles()
  const size = iconSize || 42
  const { borderRadius } = theme.shape
  const className = classes.icon
  const iconProps = { size, borderRadius, round }
  const subject = "Ecologic - Free Tailored Energy and Carbon Advice"

  return (
    <React.Fragment>
      <div id="facebooksharebutton" onClick={() => dispatch(segmentEvent('facebookShareButton'))} >
        <FacebookShareButton className={className} url={url} quote={message}>
          <FacebookIcon {...iconProps} />
        </FacebookShareButton>
      </div>
      <div id="linkedinsharebutton" onClick={() => dispatch(segmentEvent('linkedinShareButton'))}>
        <LinkedinShareButton className={className} url={url}>
          <LinkedinIcon {...iconProps} />
        </LinkedinShareButton>
      </div>
      <div id="twitterShareButton" onClick={() => dispatch(segmentEvent('twitterShareButton'))}>
        <TwitterShareButton className={className} url={url} title={message}>
          <TwitterIcon {...iconProps} />
        </TwitterShareButton>
      </div>
      <div id="whatsappShareButton" onClick={() => dispatch(segmentEvent('whatsappShareButton'))}>
        <WhatsappShareButton className={className} url={url} title={message} windowWidth={624} windowHeight={590}>
          <WhatsappIcon {...iconProps} />
        </WhatsappShareButton>
      </div>
      <div id="emailShareButton" onClick={() => dispatch(segmentEvent('emailShareButton'))}>
        <EmailShareButton className={className} url={url} subject={subject} body={message}>
          <EmailIcon {...iconProps} />
        </EmailShareButton>
      </div>
    </React.Fragment>
  )
}
