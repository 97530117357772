/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { clearSWStatus } from '../actions'
import { saveAuditIfReady } from '../../audit/lib/api-service'
import { fetchAuth } from '../lib/api-service'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '../../react-auth0-spa'
import { Button } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import CloseIcon from '@mui/icons-material/Close'

const ServiceWorkerProvider = (props) => {
  const {
    dispatch,
    serviceWorkerInitialised,
    serviceWorkerUpdated,
    serviceWorkerRegistration,
    token,
  } = props
  const { isOnline } = useAuth0()
  const history = useHistory()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [waitingWorker, setWaitingWorker] = useState(null)
  const [offlineSnackKey, setOffLineSnackKey] = useState(null)
  const [isRegistrationSuccessful, setRegistrationSuccessful] = useState(false)

  useEffect(() => {
    if (!('serviceWorker' in navigator)) {
      enqueueSnackbar(
        'Some features of this website are not available as your browser does not support them.',
        {
          action: (key) => (
            <Button color="inherit" onClick={() => closeSnackbar(key)}>
              <CloseIcon /> Dismiss
            </Button>
          ),
          variant: 'warning',
        }
      )
    }

    // This allows the control of prompting the user to install the app
    window.addEventListener('beforeinstallprompt', (event) => {
      console.log('beforeinstallprompt', event)
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event
    })
  }, [])

  useEffect(() => {
    if (serviceWorkerUpdated) {
      updateAvailable(serviceWorkerRegistration)
    }
  }, [serviceWorkerUpdated, serviceWorkerRegistration])

  useEffect(() => {
    if (serviceWorkerInitialised) {
      registrationSuccessful()
      setRegistrationSuccessful(true)
    }
  }, [serviceWorkerInitialised])

  useEffect(() => {
    // On registration, dispatch to get app data again to ensure response is cached.
    // Without this, the appDetails response may not be cached on the first load as the SW registrations occurs after.
    // This may cause issues where someone loads the app in an offline state on their subsequent load.
    if (isRegistrationSuccessful && token) {
      dispatch(fetchAuth(history, token))
    }
  }, [token, isRegistrationSuccessful])

  useEffect(() => {
    if (isOnline !== undefined) {
      handleChangedConnection()
    }
  }, [isOnline])

  const handleChangedConnection = async () => {
    if (!isOnline) {
      const key = enqueueSnackbar('Site currently offline. Limited functionality is available.', {
        variant: 'error',
        persist: true,
        action: (key) => (
          <Button color="inherit" onClick={() => closeSnackbar(key)} startIcon={<CloseIcon />}>
            Dismiss
          </Button>
        ),
      })
      setOffLineSnackKey(key)
    } else {
      if (offlineSnackKey) {
        closeSnackbar(offlineSnackKey)
        setOffLineSnackKey(null)
      }
      patchAuditAnswers()
    }
  }

  const patchAuditAnswers = () => {
    // This is to prevent any issues where answers are added offline and a user doesn't patch again prior to running simulation
    const auditScreen = RegExp('^/audit/.+/step/').test(window.location.pathname)
    if (auditScreen) {
      dispatch(saveAuditIfReady())
    }
  }

  const updateAvailable = (registration) => {
    setWaitingWorker(registration.waiting)
  }

  useEffect(() => {
    const reloadPage = () => {
      dispatch(clearSWStatus('updated')) // This may be redundant given the window reloads in this function
      waitingWorker?.postMessage({ type: 'SKIP_WAITING' })
      window.location.reload()
    }
    const reloadAction = (key, waitingWorker) => (
      <React.Fragment>
        <Button color="inherit" size="small" onClick={() => reloadPage()} startIcon={<RefreshIcon />}>
          Reload
        </Button>
        <Button color="inherit" size="small" onClick={() => closeSnackbar(key)} startIcon={<CloseIcon />}>
          Dismiss
        </Button>
      </React.Fragment>
    )
    if (waitingWorker) {
      enqueueSnackbar('New content for this website is available.', {
        variant: 'info',
        persist: true,
        action: reloadAction,
      })
    }
  }, [waitingWorker])

  const registrationSuccessful = () => {
    dispatch(clearSWStatus('initialised'))
  }

  return null
}

const mapStateToProps = (state) => {
  const serviceWorkerInitialised = state.serviceWorkerInitialised
  const serviceWorkerUpdated = state.serviceWorkerUpdated
  const serviceWorkerRegistration = state.serviceWorkerRegistration
  const token = state.token
  return {
    serviceWorkerInitialised,
    serviceWorkerUpdated,
    serviceWorkerRegistration,
    token,
  }
}

export default connect(mapStateToProps)(ServiceWorkerProvider)
