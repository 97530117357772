// Normalizes API responses to facilitate immutable state transitions.
// The basic idea is to generate a normalized state map for each entity type
// as recommended by Dan Abramov.  Here we also parse nested data for answers
// whose values contain multiple child values that we want to treat as we do
// any other answer.
// --------------------------------------------------------------------------

import { normalize, schema } from 'normalizr'
import { isEmpty } from 'underscore'
import { generateAnswerId } from './multiset-sections'

export function normalizeProcess(process) {
  // Add unique ids to questions.
  parseQuestions(process)

  // Add stepCode to sections.
  parseSections(process)

  // Add order values to the steps.
  parseSteps(process)

  const question = new schema.Entity('questions')

  const section = new schema.Entity('sections', {
    questions: [question]
  }, { idAttribute: 'code' })

  const step = new schema.Entity('steps', {
    sections: [section]
  }, { idAttribute: 'code' })

  const processSchema = [step]

  return normalize(process.steps, processSchema)
}

export function normalizeAudit(audit, sections, knownUser = true, savedAnswers = {}) {
  const defaults = parseDefaults(audit, sections)
  let answers

  if (knownUser || isEmpty(savedAnswers)) {
    answers = parseAnswers(audit, sections)
  } else {
    answers = savedAnswers
  }

  const parsedAudit = {
    answers,
    defaults
  }

  const answer = new schema.Entity('answers')

  const def = new schema.Entity('defaults')

  const auditSchema = new schema.Object({
    answers: new schema.Array(answer),
    defaults: new schema.Array(def)
  })

  return normalize(parsedAudit, auditSchema)
}

// Add a unique id attribute to answers, comprising section code
// and question code.  Convert the nested objects containing the
// collections of answers and defaults to arrays.

// For multi-set sections, only add a single answer, referenced by the section
// code alone.
export function parseAnswers(audit, sections) {
  let answers = []

  // Add multiset answers into the answers array.
  parseMultisets(answers, audit, sections)

  for (let sectionCode in audit.answers) {
    // Add an answer for every answer in the section unless it's a multiset.
    const isMultiset = sections[sectionCode] && sections[sectionCode].isSectionSet

    if (!isMultiset) {
      for (let answerCode in audit.answers[sectionCode]) {
        let id = `${sectionCode}:${answerCode}`
        // Add to the flattened array.
        answers.push({
          id,
          code: answerCode,
          sectionCode: sectionCode,
          value: audit.answers[sectionCode][answerCode]
        })
      }
    }
  }

  return answers
}

// Parse all multiset sections, adding an answer for each section, and then
// normalizing and adding an answer for each question in the nested data
// structure.
function parseMultisets(answers, audit, sections) {
  const multisetSections = Object.keys(sections).filter((sectionCode) => {
    return sections[sectionCode].isSectionSet
  })

  // Add an answer for each individual answer that is part of a multiset.
  for (let sectionCode of multisetSections) {
    let multisetAnswer = {
      id: sectionCode,
      code: sectionCode,
      sectionCode: sectionCode,
      value: audit.answers[sectionCode] || null,
      isMultiset: true,
      isNestedMultiset: !!sections[sectionCode].isSectionSetArray,
      multisetAnswers: []
    }

    if (multisetAnswer.value) {
      for (const [index, multisetItem] of multisetAnswer.value.entries()) {
        // Local function for adding each individual item for one- and two-
        // dimensional multiset answers to the answers lookup map.
        const addAnswer = (multisetItem, questionCode, secondIndex) => {
          let answerId = generateAnswerId(
            sectionCode,
            index,
            questionCode,
            secondIndex
          )

          answers.push({
            id: answerId,
            code: questionCode,
            sectionCode: sectionCode,
            value: multisetItem[questionCode],
            isChildOfMultiset: true
          })

          multisetAnswer.multisetAnswers.push(answerId)
        }

        // If the value of multisetItem is an Array, we are handling a 2D
        // multiset answer.
        if (Array.isArray(multisetItem)) {
          multisetItem.forEach((nestedItem, secondIndex) => {
            for (let questionCode in nestedItem) {
              addAnswer(nestedItem, questionCode, secondIndex)
            }
          })
          // Otherwise, we are handling a 'regular' one dimensional multiset
          // answer.
        } else {
          for (let questionCode in multisetItem) {
            addAnswer(multisetItem, questionCode)
          }
        }
      }
    }
    answers.push(multisetAnswer)
  }
  return answers
}

function parseDefaultsForNestedMultisets(sectionCode, auditDefaults) {
  let defaults = []
  for (const [index, multisetItem] of auditDefaults[sectionCode].entries()) {
    for (let answerSetIndex in multisetItem) {
      for (let answerCode in multisetItem[answerSetIndex]) {
        const id = `${sectionCode}[${index}]:[${answerSetIndex}]:${answerCode}`
        // Add to the flattened array.
        defaults.push({
          id,
          questionCode: answerCode,
          sectionCode: sectionCode,
          value: multisetItem[answerSetIndex][answerCode],
          isChildOfMultiset: true,
        })
      }
    }
  }
  return defaults
}

// Add a unique id attribute to defaults, comprising section code
// and question code.  Convert the nested objects containing the
// collections of answers and defaults to arrays.
function parseDefaults(audit, sections) {
  let defaults = []

  for (let sectionCode in audit.defaults) {
    // Add an answer for every answer in the section unless it's a multiset.
    const isMultiset = sections[sectionCode] && sections[sectionCode].isSectionSet
    if (isMultiset) {
      // First handle nested multisets, like walls
      const isNestedMultiset = audit.defaults[sectionCode] && sections[sectionCode]['isSectionSetArray']
      if (isNestedMultiset) {
        const nestedMultisetDefaults = parseDefaultsForNestedMultisets(sectionCode, audit.defaults)
        defaults.push(...nestedMultisetDefaults)
      } else {
        // Handle basic multisets
        for (const [index, multisetItem] of audit.defaults[sectionCode].entries()) {
          for (let answerCode in multisetItem) {
            let id = `${sectionCode}[${index}]:${answerCode}`
            // Add to the flattened array.
            defaults.push({
              id,
              code: answerCode,
              sectionCode: sectionCode,
              value: multisetItem[answerCode],
              isChildOfMultiset: true
            })
          }
        }
      }
    } else {
      for (let answerCode in audit.defaults[sectionCode]) {
        let id = `${sectionCode}:${answerCode}`
        // Add to the flattened array.
        defaults.push({
          id,
          code: answerCode,
          sectionCode: sectionCode,
          value: audit.defaults[sectionCode][answerCode]
        })
      }
    }
  }

  return defaults
}

// Add a unique id attribute to questions, comprising section code and question
// code — in place.
export function parseQuestions(process) {
  for (let step of process.steps) {
    for (let section of step.sections) {
      for (let question of section.questions) {
        question.id = `${section.code}:${question.code}`
        // Add section code so question can be denormalized.
        question.sectionCode = section.code
        // Choices for Boolean are not in the process schema.
        addChoicesForBoolean(question)
      }
    }
  }
}

// If the question is a Boolean data type, add the implied choices.
function addChoicesForBoolean(question) {
  if (question.widget === 'radios' && question.dataType === 'Boolean') {
    question.choices = [
      {
        text: "No",
        value: false
      },
      {
        text: "Yes",
        value: true
      }
    ]
  }
}

// Add a back reference to sections so we can find which step it's in.
export function parseSections(process) {
  for (let step of process.steps) {
    for (let section of step.sections) {
      section.stepCode = step.code
    }
  }
}

export function parseSteps(process) {
  process.steps.forEach((step, index) => {
    process.steps[index].order = index
  })

  return process
}

export function parseWallAnswersFromDefaults(wallDefaults) {
  let newAnswers = {}
  let multisetAnswers = []
  for (const [index, multisetItem] of wallDefaults.entries()) {
    const addAnswer = (multisetItem, questionCode, secondIndex) => {
      let answerId = generateAnswerId(
        "walls",
        index,
        questionCode,
        secondIndex
      )

      newAnswers[answerId] = {
        id: answerId,
        questionCode: questionCode,
        sectionCode: "walls",
        value: multisetItem[questionCode],
        isChildOfMultiset: true,
      }

      multisetAnswers.push(answerId)
    }

    multisetItem.forEach((nestedItem, secondIndex) => {
      for (let questionCode in nestedItem) {
        addAnswer(nestedItem, questionCode, secondIndex)
      }
    })
  }
  return {
    newAnswers,
    multisetAnswers
  }
}

export function defaultsLackPositionData(wallDefaults) {
  const firstWallPositionData = wallDefaults[0][0]['position']
  return (firstWallPositionData === undefined || firstWallPositionData.length < 2)
}
