import React from 'react'

const style = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}

const VerticalLayout = ({ children }) => (
  <div {...{ style }}>
    {children}
  </div>
)

export default VerticalLayout
