import Logger from './logger'

const scriptLoader = () => {
  return new Promise((res, rej) => {
    const script = document.createElement('script')
    script.src = 'https://jq0r7752ptrf.statuspage.io/embed/script.js'
    script.async = true
    script.id = 'statuspage'
    script.type = 'text/javascript'
    script.onError = rej
    script.onload = res
    script.addEventListener('error', rej)
    script.addEventListener('load', res)
    document.body.appendChild(script)
  })
}

const loadStatusPageScript = () => {
  scriptLoader().catch(
    error => {
      Logger.warn(error)
    }
  )
}

loadStatusPageScript()
