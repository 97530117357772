import React, { useState, useEffect, Fragment } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useAuth0 } from "../../react-auth0-spa"
import {
  Paper,
  Typography,
  CircularProgress,
} from '@mui/material'
import classNames from 'classnames'

// A global loading indicator
// --------------------------

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
  },
  timedOut: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    width: '75%',
  },
  hidden: {
    visibility: 'hidden',
  },
}))

export const Loading = ({ offlineWithSW }) => {
  const { classes } = useStyles()
  const [timedOut, setTimedOut] = useState(false)
  const timeoutDuration = offlineWithSW ? 5000 : 30000
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimedOut(true)
    }, timeoutDuration)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.container}>
      <CircularProgress />
      <Paper className={classNames(classes.timedOut, { [classes.hidden]: !timedOut })}>
        {offlineWithSW ?
          (<Typography>
            You appear to be currently offline. Please reconnect to the internet to view this content.
          </Typography>)
          :
          (<Typography>
            This seems to be taking longer than usual.  If it usually takes a while, you can continue to wait, otherwise it might help to reload the page.
          </Typography>)
        }
      </Paper>
    </div>
  )
}

const LoadingScreen = ({ isLoaded, children, }) => {
  const { isOnline } = useAuth0()
  const offlineWithSW = !isOnline && ('serviceWorker' in navigator) && navigator.serviceWorker.controller
  return (
    <Fragment>
      {isLoaded
        ? children
        : <Loading offlineWithSW={offlineWithSW} />
      }
    </Fragment>
  )
}

export default LoadingScreen
