// The reducer
// ------------

import * as actions from '../actions'
import * as credentialsActions from '../actions/team-credentials-actions'

import {
  enhanceTeam
} from '../../audit/lib/enhance-app-data'

const AccountReducer = (me, action) => {
  if (me === undefined)
    return {}

  switch (action.type) {
    case actions.PROFILE_SAVE_BUTTON_ACTIVE: {
      return {
        ...me,
        profileSaveButtonActive: action.currentStatus,
      }
    }
    case actions.OPEN_CHECKOUT_DIALOG: {
      return {
        ...me,
        openCheckoutDialog: true,
      }
    }
    case actions.CLOSE_CHECKOUT_DIALOG: {
      return {
        ...me,
        openCheckoutDialog: false,
      }
    }
    case actions.OPEN_ACCOUNT_ALERT_DIALOG: {
      return {
        ...me,
        openAccountAlertDialog: true,
        accountAlertMessage: action.message,
      }
    }
    case actions.CLOSE_ACCOUNT_ALERT_DIALOG: {
      return {
        ...me,
        openAccountAlertDialog: false,
        accountAlertMessage: null,
      }
    }
    case actions.SELECTED_CREDITS_TO_BUY: {
      return {
        ...me,
        selectedCreditsToBuy: action.creditSelection,
      }
    }
    case actions.UPDATE_USER_DETAILS: {
      return {
        ...me,
        me: {
          ...me.me,
          firstName: action.userDetails.firstName,
          lastName: action.userDetails.lastName,
          email: action.userDetails.email,
        },
      }
    }
    case actions.RECEIVE_UPLOAD_IMAGE_SUCCESS: {
      if (action.image === "logo") {
        return {
          ...me,
          teams: me.me.teams.map(team => {
            if (team.id.toString() !== action.teamId.toString()) return team
            return {
              ...team,
              logo: action.data.updateTeamLogo.result.url,
            }
          }),
        }
      }
      if (action.image === "header") {
        return {
          ...me,
          teams: me.teams.map(team => {
            if (team.id.toString() !== action.teamId.toString()) return team
            return {
              ...team,
              reportHeader: action.data.updateTeamReportHeader.result.url,
            }
          }),
        }
      }
      if (action.image === "footer") {
        return {
          ...me,
          teams: me.teams.map(team => {
            if (team.id.toString() !== action.teamId.toString()) return team
            return {
              ...team,
              reportFooter: action.data.updateTeamReportFooter.result.url,
            }
          }),
        }
      }
      if (action.image === "hero") {
        return {
          ...me,
          teams: me.teams.map(team => {
            if (team.id.toString() !== action.teamId.toString()) return team
            return {
              ...team,
              reportHero: action.data.updateTeamReportHero.result.url,
            }
          }),
        }
      }
      return { me }
    }
    case actions.RECEIVE_UPDATE_REPORT_TEXT_SUCCESS: {
      return {
        ...me,
        teams: me.teams.map(team => {
          if (team.id.toString() !== action.teamId.toString()) return team
          return {
            ...team,
            reportIntro: action.reportIntro,
            reportTrailing: action.reportTrailing,
          }
        }),
      }
    }
    case actions.RECEIVE_UPDATE_TEAM_REPORT_OPTIONS: {
      return {
        ...me,
        teams: me.teams.map(team => {
          if (team.id.toString() !== action.teamId.toString()) return team
          return {
            ...team,
            reportSections: action.values || [],
            reportIncludeOffers: action.showOffers,
            reportIncludeSavingsPayback: action.showSavingsPayback,
            reportDefaultType: (action.reportDefaultType).toLowerCase(),
          }
        }),
      }
    }
    case actions.RECEIVE_EDIT_TEAM_MEMBER_DETAILS: {
      const originalMember = me.teams
        .flatMap(team => team.members)
        .find(member => member.id.toString() === action.userId.toString())
      const memberToEdit = {
        ...originalMember,
        role: action.roleValue.toString()
      }
      const teamToUpdate = me.teams.find(team => team.id === action.teamId.toString())
      const newMembers = teamToUpdate.members.map(
        member => member.id === memberToEdit.id ? memberToEdit : member
      )
      return {
        ...me,
        teams: me.teams.map(team => {
          if (team.id.toString() !== action.teamId.toString()) return team
          return {
            ...team,
            members: newMembers,
          }
        }),
      }
    }
    case actions.RECEIVE_DELETE_TEAM_MEMBER: {
      const teamToUpdate = me.teams.find(team => team.id === action.teamId)
      const filteredTeam = teamToUpdate.members.filter(
        m => m.id !== action.userId
      )
      return {
        ...me,
        teams: me.teams.map(team => {
          if (team.id.toString() !== action.teamId.toString()) return team
          return {
            ...team,
            members: filteredTeam,
          }
        }),
      }
    }
    case actions.RECEIVE_ADD_TEAM_MEMBER: {
      const teamToUpdate = me.teams.find(team => team.id === action.teamId)
      const newMember = [
        {
          id: action.newMemberId,
          role: action.role,
          username: action.username,
          displayName: action.email,
        },
      ]
      Array.prototype.push.apply(newMember, teamToUpdate.members)
      return {
        ...me,
        teams: me.teams.map(team => {
          if (team.id.toString() !== action.teamId.toString()) return team
          return {
            ...team,
            members: newMember,
          }
        }),
      }
    }
    case actions.RECEIVE_TEAM_DETAILS: {
      return {
        ...me,
        teams: me.teams.map(team => {
          if (team.id.toString() !== action.teamId.toString()) return team
          return {
            ...enhanceTeam(me.id, team, action.data),
          }
        }),
      }
    }
    case actions.UPDATE_TEAM_DETAILS: {
      const updatesToTeam = {
        name: action.teamDetails.teamName,
        description: action.teamDetails.teamDescription,
      }
      return Object.assign({}, me, {
        teams: [
          ...me.teams.slice(0, action.teamIndex),
          Object.assign({}, me.teams[action.teamIndex], updatesToTeam),
          ...me.teams.slice(action.teamIndex + 1),
        ],
      })
    }
    case credentialsActions.RECEIVE_TEAM_CREDENTIALS: {
      return {
        ...me,
        teams: me.teams.map(team => {
          if (team.id.toString() !== action.teamId.toString()) return team
          return {
            ...team,
            credentials: action.credentials,
          }
        }),
      }
    }
    default: {
      return me
    }
  }
}
export default AccountReducer
