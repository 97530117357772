// Normalizes API responses to facilitate immutable state transitions.
// -------------------------------------------------------------------

import { normalize, schema } from 'normalizr'

export function normalizeAssessmentItems (assessmentItems) {
  const assessmentItem = new schema.Entity('assessmentItems')

  const assessmentItemsSchema = [assessmentItem]

  return normalize(assessmentItems, assessmentItemsSchema)
}
