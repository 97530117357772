import icon from '../../common/icons/gas'
import { TemplateType } from "./types"

export const Gas: TemplateType = {
  icon,
  comparison: {
    title: 'How your gas consumption compares',
    body: [
      'The chart below provides a comparison of your gas consumption and an indication of your overall savings potential.',
      'The ‘typical’ property has building features and appliances that are typical to your local area while the ‘efficient’ property has an affordable suite of energy saving measures installed.',
      'Both scenarios have the same building area, occupants and climate as your building to provide a fair comparison.',
    ],
  },
  composition: {
    title: 'How you use gas',
    body: [
      'The chart below provides a breakdown of how you currently use gas in terms of individual appliances.',
      'The larger slices represent the appliances that cost the most energy and money for your unique situation.',
      'The larger slices can also represent your best opportunity for reducing your bill.',
    ],
  },
  units: 'MJ',
  period: 'day',
  multiplier: 1,
}
