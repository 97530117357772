import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import { acknowledgeMessages } from '../actions'
import { MessageType, StoreThunkDispatch, StoreType } from '../lib/store-types'

type Props = {
  messages: MessageType[]
  dispatch: StoreThunkDispatch
}

const GlobalMessageDialog = ({ messages, dispatch }: Props) => (
  <React.Fragment>
    {messages && messages.length > 0 && (
      <Dialog open={true} style={{ zIndex: 2000 }} onClose={() => dispatch(acknowledgeMessages())}>
        <DialogContent style={{ marginTop: 24 }}>
          {messages.map((m, i) => (
            <DialogContentText key={i}>{getMessageText(m)}</DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dispatch(acknowledgeMessages())} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )}
  </React.Fragment>
)

function getMessageText(message: MessageType): string {
  if (typeof message === 'string') {
    return message
  }
  if (typeof message === 'object' && 'level' in message) {
    return `${message.level}: ${message.text}`
  }
  return JSON.stringify(message, undefined, 2)
}

const mapStateToProps = ({ messages }: StoreType) => ({ messages })

export default connect(mapStateToProps)(GlobalMessageDialog)
