// The Act Items for a given audit are the list of AuditActions.
// Each AuditAction has its parent Action nested, and any Offers
// nested inside that. Suppliers are nested inside Offers.
// Actions & Suppliers aren't used at top level (yet), so we keep them nested.

import { normalize, schema } from 'normalizr'

export function normalizeActItems(actItems) {
  const offer = new schema.Entity('offers')
  const auditAction = new schema.Entity('auditActions', { action: { offers: [offer] } })
  const actItemsSchema = [auditAction]

  return normalize(actItems, actItemsSchema)
}
