export const OPEN_ASSIGN_AUDIT_DIALOG = 'team-audits/OPEN_ASSIGN_AUDIT_DIALOG'
export const CLOSE_ASSIGN_AUDIT_DIALOG = 'team-audits/CLOSE_ASSIGN_AUDIT_DIALOG'
export const REQUEST_ASSIGN_AUDIT = 'team-audits/REQUEST_ASSIGN_AUDIT'
export const RECEIVE_ASSIGN_AUDIT = 'team-audits/RECEIVE_ASSIGN_AUDIT'
export const REQUEST_ASSIGN_AUDIT_ERROR = 'team-audits/REQUEST_ASSIGN_AUDIT_ERROR'
export const REQUEST_AUDIT_PDF = 'team-audits/REQUEST_AUDIT_PDF'
export const RECEIVE_AUDIT_PDF = 'team-audits/RECEIVE_AUDIT_PDF'
export const REQUEST_AUDIT_PDF_ERROR = 'team-audits/REQUEST_AUDIT_PDF_ERROR'
export const UPDATE_AUDIT_OWNER = 'team-audits/UPDATE_AUDIT_OWNER'
export const SAVE_LIST_COLUMN_STATE = 'team-audits/SAVE_LIST_COLUMN_STATE'

export const openAssignAuditDialog = (code) => ({
  type: OPEN_ASSIGN_AUDIT_DIALOG,
  code,
})
export const closeAssignAuditDialog = () => ({ type: CLOSE_ASSIGN_AUDIT_DIALOG })

export const requestAssignAudit = () => ({ type: REQUEST_ASSIGN_AUDIT })
export const receiveAssignAudit = () => ({ type: RECEIVE_ASSIGN_AUDIT })
export const requestAssignAuditError = (error) => ({
  type: REQUEST_ASSIGN_AUDIT_ERROR,
  error,
})

export const requestAuditPdf = (auditCode) => ({ type: REQUEST_AUDIT_PDF, auditCode })
export const receiveAuditPdf = (auditCode) => ({ type: RECEIVE_AUDIT_PDF, auditCode })
export const requestAuditPdfError = (auditCode, error) => ({
  type: REQUEST_AUDIT_PDF_ERROR,
  auditCode,
  error,
})

export const updateAuditOwner = (auditCode, ownerId) => ({
  type: UPDATE_AUDIT_OWNER,
  auditCode,
  ownerId,
})

export const saveListColumnState = (columns) => ({
  type: SAVE_LIST_COLUMN_STATE,
  columns
})

