// Redux Action types
// ------------------

export const REQUEST_ASSESSMENT_ITEMS = 'REQUEST_ASSESSMENT_ITEMS'
export const RECEIVE_ASSESSMENT_ITEMS = 'RECEIVE_ASSESSMENT_ITEMS'
export const PURGE_ASSESSMENT_ITEMS = 'PURGE_ASSESSMENT_ITEMS'
export const REQUEST_ASSESSMENT_ITEMS_ERROR = 'REQUEST_ASSESSMENT_ITEMS_ERROR'
export const REQUEST_UPDATE_ASSESSMENT_ITEM = 'REQUEST_UPDATE_ASSESSMENT_ITEM'
export const RECEIVE_UPDATE_ASSESSMENT_ITEM = 'RECEIVE_UPDATE_ASSESSMENT_ITEM'
export const REQUEST_UPDATE_ASSESSMENT_ITEM_ERROR = 'REQUEST_UPDATE_ASSESSMENT_ITEM_ERROR'
export const REQUEST_ASSESSMENT_ITEM = 'REQUEST_ASSESSMENT_ITEM'
export const RECEIVE_ASSESSMENT_ITEM = 'RECEIVE_ASSESSMENT_ITEM'
export const REQUEST_ASSESSMENT_ITEM_ERROR = 'REQUEST_ASSESSMENT_ITEM_ERROR'
export const TOGGLE_ASSESSMENT_ITEM_SELECTED = 'TOGGLE_ASSESSMENT_ITEM_SELECTED'
export const CHANGE_ASSESSMENT_ITEM = 'CHANGE_ASSESSMENT_ITEM'
export const REQUEST_CREATE_ASSESSMENT_ITEM = 'REQUEST_CREATE_ASSESSMENT_ITEM'
export const RECEIVE_CREATE_ASSESSMENT_ITEM = 'RECEIVE_CREATE_ASSESSMENT_ITEM'
export const REQUEST_CREATE_ASSESSMENT_ITEM_ERROR = 'REQUEST_CREATE_ASSESSMENT_ITEM_ERROR'
export const REQUEST_DELETE_ASSESSMENT_ITEM = 'REQUEST_DELETE_ASSESSMENT_ITEM'
export const RECEIVE_DELETE_ASSESSMENT_ITEM = 'RECEIVE_DELETE_ASSESSMENT_ITEM'
export const REQUEST_DELETE_ASSESSMENT_ITEM_ERROR = 'REQUEST_DELETE_ASSESSMENT_ITEM_ERROR'

// Redux Action creators
// ---------------------

export const requestAssessmentItems = () => {
  return {
    type: REQUEST_ASSESSMENT_ITEMS
  }
}

export const receiveAssessmentItems = assessmentItems => {
  return {
    type: RECEIVE_ASSESSMENT_ITEMS,
    assessmentItems
  }
}

export const purgeAssessmentItems = () => {
  return {
    type: PURGE_ASSESSMENT_ITEMS
  }
}

export const requestAssessmentItemsError = error => {
  return {
    type: REQUEST_ASSESSMENT_ITEMS_ERROR,
    error
  }
}

export const requestUpdateAssessmentItem = assessmentItem => {
  return {
    type: REQUEST_UPDATE_ASSESSMENT_ITEM,
    assessmentItem
  }
}

export const receiveUpdateAssessmentItem = assessmentItem => {
  return {
    type: RECEIVE_UPDATE_ASSESSMENT_ITEM,
    assessmentItem
  }
}

export const requestUpdateAssessmentItemError = error => {
  return {
    type: REQUEST_UPDATE_ASSESSMENT_ITEM_ERROR,
    error
  }
}

export const requestAssessmentItem = (hash, id) => {
  return {
    type: REQUEST_ASSESSMENT_ITEM,
    hash,
    id
  }
}

export const receiveAssessmentItem = assessmentItem => {
  return {
    type: RECEIVE_ASSESSMENT_ITEM,
    assessmentItem
  }
}

export const requestAssessmentItemError = error => {
  return {
    type: REQUEST_ASSESSMENT_ITEM_ERROR,
    error
  }
}

export const toggleAssessmentItemSelected = id => {
  return {
    type: TOGGLE_ASSESSMENT_ITEM_SELECTED,
    id
  }
}

export const changeAssessmentItem = formData => {
  return {
    type: CHANGE_ASSESSMENT_ITEM,
    formData
  }
}

export const requestCreateAssessmentItem = assessmentItem => {
  return {
    type: REQUEST_CREATE_ASSESSMENT_ITEM,
    assessmentItem
  }
}

export const receiveCreateAssessmentItem = assessmentItem => {
  return {
    type: RECEIVE_CREATE_ASSESSMENT_ITEM,
    assessmentItem
  }
}

export const requestCreateAssessmentItemError = error => {
  return {
    type: REQUEST_CREATE_ASSESSMENT_ITEM_ERROR,
    error
  }
}

export const requestDeleteAssessmentItem = id => {
  return {
    type: REQUEST_DELETE_ASSESSMENT_ITEM,
    id
  }
}

export const receiveDeleteAssessmentItem = id => {
  return {
    type: RECEIVE_DELETE_ASSESSMENT_ITEM,
    id
  }
}

export const requestDeleteAssessmentItemError = error => {
  return {
    type: REQUEST_DELETE_ASSESSMENT_ITEM_ERROR,
    error
  }
}
