import React, { Fragment, FunctionComponent } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Grid, Typography } from '@mui/material'

const useStyles = makeStyles()({
  label: {
    fontWeight: 550,
  },
})

type TextNodeType =
  | string
  | {
      bold?: boolean
      text: string
    }
export type GenericBodyType = TextNodeType[][]
type Props = {
  body: GenericBodyType
}

const GenericText: FunctionComponent<Props> = ({ body }) => {
  const { classes } = useStyles()
  return (
    <Fragment>
      {body.map((nodes, i) => (
        <Grid item xs={12} sm={6} md={4} key={i}>
          {nodes.map((node, i) => {
            const text = typeof node === 'string' ? node : node.text
            const bold = typeof node === 'string' ? false : node?.bold
            return (
              <Typography
                key={`${i}-${text}`}
                display="inline"
                className={bold ? classes.label : ''}
              >
                {text}
              </Typography>
            )
          })}
        </Grid>
      ))}
    </Fragment>
  )
}

export default GenericText
