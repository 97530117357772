// Given an options object with target and patch defaults data,
// merge the patch defaults into the target defaults down to the question level.
export function mergeDefaults(options = {}) {
  // The new defaults object
  let updates = {}
  // A list of all the section codes which are keys on the defaults object
  let sectionKeys = Object.keys(options.patch)

  // Iterate through the sections with defaults needing an update.
  for (let sectionKey of sectionKeys) {
    let patchSection = options.patch[sectionKey]

    // Iterate through all defaults needing an update.
    if (patchSection instanceof Array) {
      for (const [index, multisetItem] of patchSection.entries()) {
        let defaultKey
        if (multisetItem instanceof Array) {
          for (let i = 0; i < multisetItem.length; i++) {
            for (let innerQuestionKey in multisetItem[i]) {
              defaultKey = `${sectionKey}[${index}]:[${i}]:${innerQuestionKey}`
              updates[defaultKey] = {
                value: patchSection[index][i][innerQuestionKey],
                id: defaultKey,
                code: innerQuestionKey,
                isChildOfMultiset: true,
                sectionCode: sectionKey,
              }
            }
          }
        } else {
          for (let questionKey in multisetItem) {
            defaultKey = `${sectionKey}[${index}]:${questionKey}`
            updates[defaultKey] = {
              ...options.defaultsStore[defaultKey],
              value: patchSection[index][questionKey]
            }
          }
        }
      }
    } else {
      let questionKeys = Object.keys(patchSection)
      for (let questionKey of questionKeys) {
        let defaultKey = `${sectionKey}:${questionKey}`
        updates[defaultKey] = {
          ...options.defaultsStore[defaultKey],
          value: patchSection[questionKey]
        }
      }
    }
  }

  return {
    ...options.defaultsStore,
    ...updates
  }
}
