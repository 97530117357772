import React from "react"
import { connect } from 'react-redux'
import { findWhere, filter } from "underscore"
import { Link as RouterLink } from 'react-router-dom'
import Logger from '../../common/lib/logger'
import { closeListLabelDialog } from "../actions"
import AddIcon from "@mui/icons-material/Add"
import ClearIcon from '@mui/icons-material/Clear'
import { deleteLabel, applyLabel } from "../api"

import {
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  Typography,
} from '@mui/material'

const linkProps = {
  component: RouterLink,
  color: 'primary',
  underline: 'always'
}

const mapStateToProps = (state) => {
  const listLabelDialogOpen = state.listLabelDialogOpen || false
  const listLabelEditContentType = state.listLabelEditContentType || null
  const listLabelEditId = state.listLabelEditId || null
  const currentTeamId = state.listState?.currentTeamId
  let teamId = currentTeamId
  let loaded = false

  let selectedItem = null
  let selectedLabels = null
  let availableLabels = null
  let identifier = null
  let contentIndexName = null
  try {
    if (listLabelEditContentType && listLabelEditId && state.listState && state.listState[`${listLabelEditContentType}List`]) {
      const codeOrId = listLabelEditContentType === "audit" ? "code" : "id"
      selectedItem = findWhere((state.listState[`${listLabelEditContentType}List`][`${listLabelEditContentType}ListResults`]), { [codeOrId]: listLabelEditId })

      if (selectedItem) {
        selectedLabels = selectedItem && selectedItem["labels"]
        switch (listLabelEditContentType) {
          case "audit":
            identifier = selectedItem.address
            teamId = selectedItem['team']['id']
            contentIndexName = "audit"
            break
          case "action":
            identifier = `${selectedItem.action.title} for ${selectedItem.audit.address}`
            contentIndexName = "auditAction"
            break
          case "project":
            identifier = `${selectedItem.offer.title} for ${selectedItem.street}`
            contentIndexName = "quoteRequest"
            break
          default:
            Logger.warn("Cannot find content type on list label")
        }
      }
      availableLabels = selectedLabels && findWhere((state.me.teams), { id: teamId })["labels"][contentIndexName]
      loaded = true
    }
  } catch (e) {
    loaded = false
  }


  return {
    loaded,
    listLabelDialogOpen,
    listLabelEditContentType,
    identifier,
    listLabelEditId,
    selectedLabels,
    availableLabels,
    currentTeamId
  }
}

const ListLabelsDialog = ({
  loaded,
  listLabelDialogOpen,
  listLabelEditContentType,
  identifier,
  listLabelEditId,
  selectedLabels,
  availableLabels,
  currentTeamId,
  dispatch
}) => {
  if (!loaded) return null
  const filteredAvailableLabels = filter(availableLabels, function (obj) { return !findWhere(selectedLabels, obj) })
  const closeDialogue = () => {
    dispatch(closeListLabelDialog())
  }
  const handleLabelDelete = (labelId) => {
    dispatch(deleteLabel(listLabelEditContentType, listLabelEditId, labelId))
  }
  const handleLabelAdd = (labelId) => {
    dispatch(applyLabel(listLabelEditContentType, listLabelEditId, labelId))
  }
  return (
    <Dialog
      open={listLabelDialogOpen}
      onClose={closeDialogue}
      maxWidth='md'
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Assign labels to {listLabelEditContentType === 'audit' ? 'profile' : listLabelEditContentType}
        <Typography>{identifier}</Typography>
      </DialogTitle>
      <DialogContent>
        {selectedLabels && selectedLabels.length > 0 && <React.Fragment>
          <DialogContentText>
            Selected
          </DialogContentText>
          <div style={{ marginBottom: "20px" }}>
            {selectedLabels.map(label => (
              <Chip
                key={label.id}
                size="small"
                style={{ backgroundColor: label.colour, color: "white", margin: "2px" }}
                label={label.name}
                clickable
                deleteIcon={<ClearIcon style={{ color: "white" }} />}
                onDelete={() => handleLabelDelete(label.id)}
                onClick={() => handleLabelDelete(label.id)}
              />
            ))}
          </div>
        </React.Fragment>}
        {filteredAvailableLabels && filteredAvailableLabels.length > 0 && <React.Fragment>
          <DialogContentText>
            Available to Add
          </DialogContentText>
          <div style={{ marginBottom: "20px" }}>
            {filteredAvailableLabels.map(label => (
              <Chip
                key={label.id}
                size="small"
                style={{ backgroundColor: label.colour, color: "white", margin: "2px" }}
                label={label.name}
                clickable
                deleteIcon={<AddIcon style={{ color: "white" }} />}
                onDelete={() => handleLabelAdd(label.id)}
                onClick={() => handleLabelAdd(label.id)}
              />
            ))}
          </div>
        </React.Fragment>}
        {(!selectedLabels || selectedLabels.length === 0) && (!filteredAvailableLabels || filteredAvailableLabels.length === 0) && <React.Fragment>
          <DialogContentText>
            No labels available to add.
          </DialogContentText>
        </React.Fragment>}
        <DialogContentText>
          Return to <Link {...linkProps} onClick={closeDialogue} to={`/account/team/${currentTeamId}`}>your team profile page</Link> to create and edit your team labels.
          </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialogue} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog >
  )
}

export default connect(mapStateToProps)(ListLabelsDialog)
