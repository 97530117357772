// Handles calls to the Ecologic API
// ---------------------------------

import { debounce } from 'underscore'
import Logger from '../../common/lib/logger'
import * as actions from '../actions/index'
import { createPatch } from './answers'
import * as api from '../../common/lib/api-service'
import { clearAudits } from "../../team-dashboard/actions"
import { segmentEvent } from '../../common/lib/addons-config'
import { setAudit } from '../../common/actions'
import { isCompletedSurveyPath, pathSurveyComplete, prefixStartSurvey } from "../../common/lib/util"

export const processOptions = (token) => ({
  credentials: 'same-origin',
  headers: {
    'Authorization': `Bearer ${token}`
  },
})

// Fetch the process for the current audit.
export const fetchProcess = (code) => {
  return (dispatch, getState) => {
    const processEndpoint = `${process.env.REACT_APP_API_HOST}/!api/v1/audit/${code}/process`

    // Dispatch an action to mark the process as requested.
    dispatch(actions.requestProcess(code))

    // Begin the GET to the process endpoint.
    fetch(processEndpoint, processOptions(getState().token))
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => {
        // Dispatch an action to receive the process json.
        dispatch(actions.receiveProcess(json))
        parseAuditIfReady(getState(), dispatch)

        // if validation errors exist at this early point
        // it should only be due to reloading after registration
        // from /start flow, but sections didn't exist, so
        // re-trigger simulation to properly format the errors
        if (getState().serverValidationErrors) {
          dispatch(startSimulation())
        }
      })
      // Dispatch an error action for API errors thrown by api#checkStatus.
      .catch(api.catchApiErrorWith((error) => {
        dispatch(actions.requestProcessError(error.message))
      }))
  }
}

// Fetch the data for the current audit.
export const fetchAudit = (code) => {
  return (dispatch, getState) => {
    const auditEndpoint = `${process.env.REACT_APP_API_HOST}/!api/v1/audit/${code}`

    // Dispatch an action to mark the audit as requested.
    dispatch(actions.requestAudit())

    // Begin the GET to the process endpoint.
    fetch(auditEndpoint, processOptions(getState().token))
      .then(api.checkStatus)
      .then((response) => response.json())
      .then((json) => {
        // Dispatch an action to receive the audit json.
        dispatch(actions.receiveAudit(json))
        parseAuditIfReady(getState(), dispatch)
        return json.defaults && json.defaults["walls"]
      })
      .then((wallDefaults) => {
        if (wallDefaults && wallDefaults[0]) dispatch(actions.saveWallDefaults([wallDefaults[0]]))
      })
      // Dispatch an error action for API errors thrown by api#checkStatus.
      .catch(api.catchApiErrorWith((error) => {
        dispatch(actions.requestAuditError(error.message))
      }))
  }
}

export const startNewAudit = (history, teamId = null, { prefill = false, silent = false, ...restOpts } = {}) => {
  return (dispatch, getState) => {
    const auditEndpoint = `${process.env.REACT_APP_API_HOST}/!api/v1/audit`

    const options = Object.assign({}, processOptions(getState().token), {
      body: JSON.stringify({ "team_id": teamId }),
      method: 'POST'
    })

    dispatch(actions.requestNewAudit())

    fetch(auditEndpoint, options)
      .then(api.checkAuditCreationStatus)
      .then((json) => {
        dispatch(actions.receiveNewAudit(json.code))
        dispatch(setAudit(json.code))
        if (!prefill && !silent) {
          // Redirect the user to the new audit screen.
          history.push(`/audit/${json.code}/step/location`)
          segmentEvent('startSurvey', {
            auditHash: json.code
          })
        } else {
          dispatch(saveAudit(0, {
            prefill,
            history,
            silent,
            ...restOpts
          }))
        }
        // Clear audits from pro dashboard list
        dispatch(clearAudits())
      })
      .catch(api.catchApiErrorWith(
        (error) => {
          if (error.message === "No audit credit available.") {
            dispatch(actions.requestNewAuditError({
              level: "No credits are available", text: "Please navigate to your team's profile page to purchase more credits"
            }))
          } else {
            dispatch(actions.requestNewAuditError({ level: "Error", text: `${error.message}` }))
            // if this is a completed survey, then redirect to root url
            if (isCompletedSurveyPath()) {
              window.location.replace(window.location.pathname)
            }
          }
        }
      ))
  }
}

// Send a patch of the current audit.
export const saveAudit = (attempts = 0, {
  prefill = false,
  history = null,
  silent = false,
  retryGetAudits
} = {}) => {
  return (dispatch, getState) => {
    if (!getState().currentAudit) return
    const patchEndpoint = `${process.env.REACT_APP_API_HOST}/!api/v1/audit/${getState().currentAudit}`
    const patch = createPatch(getState().answers)

    // Dispatch an action to mark the patch as requested.
    dispatch(actions.patchAudit(patch))

    const options = Object.assign({}, processOptions(getState().token), { method: 'PATCH', body: JSON.stringify(patch) })
    let response

    // Begin the PATCH to the process endpoint.
    fetch(patchEndpoint, options)
      .then(api.checkStatus)
      .then((res) => { 
        response = res.clone() // Needed to access in error handler
        return res.json()
      })
      .then((json) => {
        // If prefilled then run simulation
        if (prefill) {
          dispatch(segmentEvent('runSimulation', {
            auditHash: getState().currentAudit
          }))
          dispatch(startSimulation(history, prefill))
        } else {
          // A successful PATCH returns an update to the default answers for the
          // audit.  Dispatch two actions: one to signify successful persistence
          // of the audit and a second to signify an update to the defaults.
          dispatch(actions.patchAuditSuccess(patch))
          dispatch(actions.clearServerValidationError())
          dispatch(actions.updateDefaults(json))
        }
        if (silent && retryGetAudits) {
          retryGetAudits()
        }
        return json.defaults && json.defaults["walls"]
      })
      .then((wallDefaults) => {
        // If location has been updated, save wall defaults as answers
        if (!prefill && "location" in patch && wallDefaults) {
          dispatch(actions.saveWallDefaults([wallDefaults[0]]))
        }
      })
      .then(() => {
        if (!prefill && getState().answers.shouldMakeNewPatchRequest && attempts < 10) {
          const updatedAttempts = attempts + 1
          saveAuditIfReady(updatedAttempts)
        }
        if (attempts === 10) {
          Logger.warn('Patch request attempts reached threshold')
        }
        dispatch(actions.unsetAnswerErrors())
      })
      // Dispatch an error action for API errors thrown by #checkStatus.
      .catch(error => api.catchApiError(
        error,
        (error) => dispatch(actions.patchAuditError(error.message)),
        (validationError) => {
          validationError.response.json().then(json => {
            dispatch(actions.serverValidationError(json.meta))
            dispatch(actions.setAnswerErrors(json.meta))
          })
        },
        response,
        options
      ))
  }
}

// Make a POST request to trigger the initialization of the simulation.
export const startSimulation = (history, prefill) => {
  return (dispatch, getState) => {
    const auditHash = getState().currentAudit
    // Redirect the user to the waiting/results screen, unless they came from the start-survey endpoint
    const resultsURL = prefill === prefixStartSurvey ? pathSurveyComplete : `/audit/${auditHash}/result`
    const postEndpoint = `${process.env.REACT_APP_API_HOST}/!api/v1/audit/${auditHash}/simulate`

    const options = Object.assign({}, processOptions(getState().token), { method: 'POST' })

    // Dispatch an action to mark the audit as posted.
    dispatch(actions.postAudit(auditHash))

    // Begin the POST request.
    fetch(postEndpoint, options)
      .then(api.checkStatus)
      .then(() => {
        // Dispatch an action to mark the simulation as started.
        dispatch(actions.simulationStarted(auditHash))
      })
      .then(() => {
        history.replace(resultsURL)
      })
      .catch(api.catchApiErrorWith(
        (error) => dispatch(actions.startSimulationError(error.message)),
        (validationError) => {
          return validationError.response.json().then(json => {
            dispatch(actions.serverValidationError(json.meta))
            dispatch(actions.setAnswerErrors(json.meta))
            dispatch(actions.resumeAudit())
          })
        }
      ))
  }
}

// If the process has been received, dispatch receiveAudit.
export function parseAuditIfReady(state, dispatch) {
  if (state.processReceived && state.auditReceived && !state.auditParsed) {
    dispatch(actions.parseAudit(state.audit))
  }
}

// A debounced function which dispatches #saveAudit only as often as
// SAVE_INTERVAL
const debouncedSaveAudit = debounce((dispatch, attempts) => {
  dispatch(saveAudit(attempts))
}, process.env.REACT_APP_SAVE_INTERVAL)

// A redux thunk to call the debounced save
export function saveAuditIfReady(attempts = 0) {
  return (dispatch) => {
    debouncedSaveAudit(dispatch, attempts)
  }
}
