import React from 'react'
import ViewListIcon from '@mui/icons-material/ViewList'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import AdapterLink from '../../lib/adapter-link'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'

const getTeamLink = (team) => {
  const startPage = team.isSupplier && !team.isPartner ? 'project' : 'audit'
  return `/team/${team.id}/${startPage}/`
}

const AuditListSelector = ({ teams, classes }) => (
  <PopupState variant='popover'>
    {popupState => (
      <React.Fragment>
        <IconButton
          className={classes.iconButton}
          {...bindTrigger(popupState)}
          aria-label='Team Audits'
          size="large">
          <Tooltip title="Audits-Actions-Projects" aria-label="Audits-Actions-Projects">
            <ViewListIcon />
          </Tooltip>
        </IconButton>
        <Menu {...bindMenu(popupState)}>
          {teams.map(team => (
            <MenuItem key={team.id}
              component={AdapterLink}
              to={getTeamLink(team)}
              onClick={popupState.close}
            >
              {team.name}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    )}
  </PopupState>
)

export default AuditListSelector
