// The reducer for the assessment items branch of the global state
// ---------------------------------------------------------------

import { findWhere, omit } from 'underscore'

import * as actions from '../actions'
import {
  getAssessmentItemCollection,
  toggleItem,
  withDefaultStatus,
  flattenAssessmentItems
} from '../lib/assessment-items'

const assessmentItems = (state, action) => {
  if (state === undefined)
    return {}

  switch (action.type) {
    case actions.TOGGLE_ASSESSMENT_ITEM_SELECTED: {
      const item = state[action.id]
      const parentCode = item.parent_code
      const collection =
        getAssessmentItemCollection(state, parentCode)
      const itemToDeselect = findWhere(collection, { status: 'selected' })
      let newState = state

      // Toggle the item.
      newState = toggleItem(newState, item)

      if (Boolean(parentCode) &&
        item.status === '' &&
        itemToDeselect
      ) {
        // Deselect the currently selected item in the collection —
        // if one exists.
        newState = toggleItem(newState, itemToDeselect)
      }
      return newState
    }
    case actions.RECEIVE_UPDATE_ASSESSMENT_ITEM: {
      const updatedItem = Object.assign({}, action.assessmentItem, { status: action.assessmentItem.status.toLowerCase() })

      // TODO: REMOVE THIS WHEN BACKEND IS FIXED
      delete updatedItem.payback_ratio
      delete updatedItem.payback_colour

      return {
        ...state,
        [updatedItem.id]: {
          ...state[updatedItem.id],
          ...updatedItem,
          changed: false,
          changedFields: []
        }
      }
    }
    case actions.CHANGE_ASSESSMENT_ITEM:
      return {
        ...state,
        [action.formData.id]: {
          ...state[action.formData.id],
          ...action.formData,
          changed: true
        }
      }
    case actions.RECEIVE_ASSESSMENT_ITEM: {
      const receivedItem = flattenAssessmentItems(action.assessmentItem)[0]
      return {
        ...state,
        [receivedItem.id]: {
          ...state[receivedItem.id],
          ...withDefaultStatus(receivedItem),
          hydrated: true
        }
      }
    }
    case actions.RECEIVE_CREATE_ASSESSMENT_ITEM:
      return {
        ...state,
        [action.assessmentItem.id]: withDefaultStatus(action.assessmentItem)
      }
    case actions.RECEIVE_DELETE_ASSESSMENT_ITEM:
      return omit(state, action.id)
    default:
      return state
  }
}

export default assessmentItems
