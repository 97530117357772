import React, { useState, useEffect, useContext } from "react"
import createAuth0Client from "@auth0/auth0-spa-js"

// Custom claims sent in ID tokens by Auth0 must use a unique namespace, recommended to be URL
// This doesn't affect partner sites - it is to create a unique claim, rather than have any meaning in the URL
// see https://auth0.com/docs/tokens/create-namespaced-custom-claims
export const AUTH0_ID_CLAIM_NAMESPACE = "https://www.ecologicapp.com"

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [isOnline, setIsOnline] = useState()

  const initAuth0 = async () => {
    const auth0FromHook = await createAuth0Client(initOptions)
    setAuth0(auth0FromHook)

    if (window.location.search.includes("code=")) {
      const { appState } = await auth0FromHook.handleRedirectCallback()
      onRedirectCallback({ ...appState })
    }

    const isAuthenticated = await auth0FromHook.isAuthenticated()

    setIsAuthenticated(isAuthenticated)

    if (isAuthenticated) {
      const user = await auth0FromHook.getUser()
      setUser(user)
    }
    setLoading(false)
  }

  const handleConnectionChange = () => {
    setIsOnline(navigator.onLine)
  }

  useEffect(() => {
    window.addEventListener('online', handleConnectionChange)
    window.addEventListener('offline', handleConnectionChange)

    handleConnectionChange()

    return () => {
      window.removeEventListener('online', handleConnectionChange)
      window.removeEventListener('offline', handleConnectionChange)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // If auth0 hasn't been initiated and user then comes online, initiate
    if (!auth0Client && isOnline) {
      initAuth0()
    }
    // eslint-disable-next-line
  }, [isOnline])

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        handleRedirectCallback,
        isOnline,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p, { returnTo: window.location.origin })
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
