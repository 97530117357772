import React from 'react'
import { withStyles } from 'tss-react/mui'
import MenuIcon from '@mui/icons-material/Menu'
import Logo from './logo'
import ProControls from './pro-controls'
import AccountMenu from './account-menu'
import { toggleMobileNav, toggleTabletNav } from '../../actions'
import shareableResults from '../../lib/shareable-results'

import { AppBar, IconButton, Slide, Toolbar } from '@mui/material'

class Header extends React.Component {
  render() {
    const {
      me: { teams, professional: pro },
      myTeam,
      audits,
      audit,
      auditSelectorOpen,
      dispatch,
      classes,
    } = this.props

    const auditScreen = RegExp('^/audit/.+/step/').test(window.location.pathname)
    const teamAuditScreen = RegExp('^/team/.+/audit').test(window.location.pathname)

    const hasResults = shareableResults(audit)

    return (
      <React.Fragment>
        <AppBar color="default" className={classes.appbar}>
          <Toolbar disableGutters>
            {!auditSelectorOpen && (
              <Slide direction="right" in={auditScreen} mountOnEnter unmountOnExit>
                {/* Audit Nav hamburger */}
                <div>
                  <IconButton
                    sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
                    onClick={() => this.props.dispatch(toggleMobileNav())}
                    size="large"
                  >
                    <MenuIcon />
                  </IconButton>
                  <IconButton
                    sx={{ display: { xs: 'none', sm: 'inline-flex', md: 'none' } }}
                    onClick={() => this.props.dispatch(toggleTabletNav())}
                    size="large"
                  >
                    <MenuIcon />
                  </IconButton>
                </div>
              </Slide>
            )}

            {!auditSelectorOpen && <Logo pro={pro} teamAuditScreen={teamAuditScreen} />}

            {pro && (
              <ProControls
                audit={audit}
                audits={audits}
                auditSelectorOpen={auditSelectorOpen}
                teams={teams}
                myTeam={myTeam}
                dispatch={dispatch}
              />
            )}

            {!auditSelectorOpen && (
              <AccountMenu teams={teams} pro={pro} hasResults={hasResults} dispatch={dispatch} />
            )}
          </Toolbar>
        </AppBar>
        <div className={classes.toolbarHeight} />
      </React.Fragment>
    )
  }
}

const styles = (theme) => ({
  appbar: {
    position: 'relative',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  '@media (min-height:700px)': {
    appbar: {
      position: 'fixed',
    },
    toolbarHeight: {
      height: '72px',
    },
  },
})
export default withStyles(Header, styles)
