import { alpha } from '@mui/material'
import { grey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

const themeOptions = {
  palette: {
    default: {
      ...grey,
      main: grey[300],
      dark: grey[400],
    },
  },
}
// @ts-expect-error
const theme = createTheme(themeOptions)

/**
 * @see https://mui.com/guides/migration-v4/#button
 */
export const v4DefaultButtons = {
  ...themeOptions,
  components: {
    // Change default button style from "primary" to "default"
    MuiButtonGroup: {
      defaultProps: {
        color: 'default',
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'default',
      },
      // Re-add "default" button style: https://codesandbox.io/s/mimic-v4-button-default-color-bklx8?file=/src/Demo.tsx
      variants: [
        {
          props: { variant: 'contained', color: 'default' },
          style: {
            color: theme.palette.getContrastText(grey[300]),
          },
        },
        {
          props: { variant: 'outlined', color: 'default' },
          style: {
            color: theme.palette.text.primary,
            borderColor:
              theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
            '&.Mui-disabled': {
              border: `1px solid ${theme.palette.action.disabledBackground}`,
            },
            '&:hover': {
              borderColor:
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
            },
          },
        },
        {
          props: { variant: 'text', color: 'default' },
          style: {
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
            },
          },
        },
      ],
    },
  },
}
