import LogRocket from 'logrocket'
import { configureStore, Store } from "@reduxjs/toolkit"
import { debounce } from 'underscore'
import safeLocalStorage from './local-storage'
import Logger from './logger'
import { imageSignatureStripperMiddleware } from './store-image-middleware'
import reducer from '../reducers/index'
import { isCompletedSurveyPath, isStartAuditPath } from './util'

const localStorageQuotaErrorNames = [
  'QUOTA_EXCEEDED_ERR', // Chrome
  'NS_ERROR_DOM_QUOTA_REACHED', // FF
  'QuotaExceededError', // Chrome / IR
  'W3CException_DOM_QUOTA_EXCEEDED_ERR', // IE
]

export const AUDIT_ANSWERS_LS_KEY = 'ecologicAudit'

// Save the current state to local storage.
export function saveToLocalStorage(store: Store) {
  if (isStartAuditPath() || isCompletedSurveyPath()) {
    const answers = store.getState().answers
    const stringifiedAnswers = JSON.stringify(answers)
    try {
      safeLocalStorage.setItem(AUDIT_ANSWERS_LS_KEY, stringifiedAnswers)
    } catch (e) {
      if (e instanceof Error) {
        if (localStorageQuotaErrorNames.includes(e.name)) {
          Logger.warn('User localStorage may be out of space; cannot set item')
        } else {
          Logger.error(e)
        }
      } else {
        console.error(e)
      }
    }
  }
}

// A debounced version of the save to local storage function to avoid saving
// too often.
const debouncedSaveToLocalStorage = debounce(
  saveToLocalStorage,
  process.env.REACT_APP_LOCAL_STORAGE_INTERVAL
)

// Setup the redux store.
export function setupStore(initialState?: any) {
  if (window.localStorage && process.env.REACT_APP_LOCAL_STORAGE_ENABLED === 'true') {
    if (isStartAuditPath() || isCompletedSurveyPath()) {
      const locallyStoredState = safeLocalStorage.getItem(AUDIT_ANSWERS_LS_KEY)
      const splashSeenValue = /^\/start[^/]*$/.test(window.location.pathname)
        ? false
        : safeLocalStorage.getItem('splashSeen')
      if (locallyStoredState && JSON.parse(locallyStoredState)) {
        initialState = {
          currentStep: 'location',
          answers: JSON.parse(locallyStoredState),
          splashSeen: splashSeenValue,
        }
        // Mark the state as preloaded.
        initialState.preloaded = true
      } else initialState = undefined
    }
  }

  // Create the store.
  let store = configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
    // Provide the state from local storage or, if none exists, null
    // as an initial state.
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      imageSignatureStripperMiddleware,
      LogRocket.reduxMiddleware({
        stateSanitizer: function (state) {
          return {
            ...state,
            token: undefined,
          }
        },
        actionSanitizer: function (action) {
          if (action.type === 'SAVE_TOKEN_SUB_TO_STATE') {
            return null
          }
          return action
        },
      }),
    ],
  })

  // Store the redux state to local storage if support exists.
  if (window.localStorage && process.env.REACT_APP_LOCAL_STORAGE_ENABLED === 'true') {
    store.subscribe(() => debouncedSaveToLocalStorage(store))
  }

  return store
}
