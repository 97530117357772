import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth0 } from "../../../react-auth0-spa"
import { withStyles } from 'tss-react/mui'
import PersonIcon from '@mui/icons-material/Person'
import MenuIcon from '@mui/icons-material/Menu'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { showShareBar } from '../../actions'

import { IconButton, Menu, MenuItem, Divider } from '@mui/material'

const styles = () => ({
  icon: {
    marginLeft: 'auto',
  },
})


const AccountMenu = ({ teams, pro, hasResults, dispatch, classes }) => {
  const { isAuthenticated, logout } = useAuth0()

  return (
    <PopupState variant='popover'>
      {popupState => {
        const shareClick = () => {
          dispatch(showShareBar())
          popupState.close()
        }

        return (
          <React.Fragment>
            <IconButton
              {...bindTrigger(popupState)}
              className={pro ? null : classes.icon}
              aria-label='Account'
              size="large">
              {isAuthenticated ?
                <PersonIcon />
                :
                <MenuIcon />
              }
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              {isAuthenticated &&
                <MenuItem
                  component={Link}
                  to={`/account/user/`}
                  onClick={popupState.close}
                >
                My Profile
                  <Divider light />
              </MenuItem>
              }


              {pro && teams.map(team => (
                <MenuItem key={team.id}
                  component={Link}
                  to={`/account/team/${team.id}/`}
                  onClick={popupState.close}
                >
                  {team.name}
                </MenuItem>
              ))}

              {hasResults && <Divider light />}

              {hasResults &&
                <MenuItem
                  component='a'
                  onClick={shareClick}
                >
                  Share
              </MenuItem>
              }

              <MenuItem
                component='a'
                className='launchIntercom'
                onClick={() => {
                  popupState.close()
                }}
              >
                Help
              </MenuItem>

              {(pro || hasResults) && <Divider light />}

              {isAuthenticated ?
                <MenuItem
                component='a'
                onClick={() => {
                  sessionStorage.clear()
                  logout()
                }}
                >
                  Logout
                </MenuItem>
                :
                <MenuItem
                  component={Link}
                  to={`/`}
                  onClick={popupState.close}
                >
                  Login
                </MenuItem>
              }
            </Menu>
          </React.Fragment>
        )
      }}
    </PopupState>
  )
}

export default withStyles(AccountMenu, styles)
