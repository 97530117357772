import { addAnswer, removeAnswer, changeMultisetAnswer } from '../actions/index'

const idSplitterRegex = /^(.*):(.*)$/
const multisetIdSplitterRegex =
  /^([-_\w\d]*)(?:\[(\d+)\])?:(?:\[(\d+)\])?:?([_\w\d]*)$/

// Generate a multiset answer id.
export function generateAnswerId(
  sectionCode, index, questionCode, secondIndex
) {
  if (secondIndex !== undefined && secondIndex !== null) {
    return `${sectionCode}[${index}]:[${secondIndex}]:${questionCode}`
  } else {
    return `${sectionCode}[${index}]:${questionCode}`
  }
}

// Parse a multiset answer id into an object containing section code, index and
// question code.
export function parseAnswerId(answerId) {
  const match = answerId.match(multisetIdSplitterRegex)

  return {
    sectionCode: match[1],
    index: match[2] ? parseInt(match[2], 10) : null,
    secondIndex: match[3] ? parseInt(match[3], 10) : null,
    questionCode: match[4]
  }
}

// Add or remove sets from a multiset.
export function addOrRemoveSets(
  leadId,
  leadChoice,
  leadNewQuantity,
  multisetQuestions,
  questions,
  dispatch
) {
  let totalSets = 0
  const setsForThisChoice = multisetQuestions.sets[leadChoice] || []
  const currentQuantity = setsForThisChoice.length
  const sectionCode = leadId.match(idSplitterRegex)[1]
  let startIndex = null

  for (let leadValue in multisetQuestions.sets) {
    totalSets += multisetQuestions.sets[leadValue].length
  }

  // `delta` represents how many sets to add or remove.
  const delta = leadNewQuantity - currentQuantity

  if (delta === 0) {
    return
  } else if (delta > 0) {
    addSet(sectionCode, leadChoice, totalSets, delta, questions, dispatch)
  } else {
    startIndex = currentQuantity - 1
    removeSet(
      sectionCode,
      leadChoice,
      startIndex,
      Math.abs(delta),
      multisetQuestions,
      dispatch
    )
  }

  // Dispatch an action to change the answer of the entire multiset.
  dispatch(changeMultisetAnswer(sectionCode))
}

// Given the section code, the value for the lead question,the current quantity
// of sets for this code, the amount to increase this by and the questions
// that need to be inserted for each new set, dispatch addAnswer actions for
// each new question.
export function addSet(
  sectionCode,
  leadValue,
  indexToAddAfter,
  quantityToAdd,
  questions,
  dispatch,
  topLevelIndex) {
  for (let i = indexToAddAfter; i < indexToAddAfter + quantityToAdd; i++) {
    for (let question of questions) {
      let answerId = null

      // If no top-level index is given, generate a 1D answer id.
      if (topLevelIndex === undefined) {
        answerId = generateAnswerId(sectionCode, i, question.code)
        // If one is given, generate a 2D answer id.
      } else {
        answerId = generateAnswerId(
          sectionCode,
          topLevelIndex,
          question.code,
          i
        )
      }

      let newAnswerValue = null

      // The only level one question in a multiset is the lead question, and
      // for the lead question we need to set the value.  This value is only
      // added for 1D multisets, i.e. if the topLevelIndex isn't given.
      if (question.level === 1 && topLevelIndex === undefined) {
        newAnswerValue = leadValue
      }

      dispatch(addAnswer(answerId, sectionCode, question.code, newAnswerValue))
    }
  }
}

export function duplicateFloorSet(
  sectionCode,
  questions,
  dispatch,
  setToDuplicate,
  storeys
) {

  for (let floor = 1; floor < storeys; floor++) {
    for (let i = 0; i < setToDuplicate.length; i++) {
      for (let question of questions) {
        let answerId = null

        //   If set is a zone, generate a 1D answer id.
        if (sectionCode === 'zone-hvac') {
          answerId = generateAnswerId(sectionCode, i, question.code)
          // If not (ie a wall set), generate a 2D answer id.
        } else {
          answerId = generateAnswerId(
            sectionCode,
            floor,
            question.code,
            i
          )
        }

        let newAnswerValue = (setToDuplicate[i]).find((answer) => {
          return answer.code === question.code
        }).displayedResponse || null

        if (question.code === 'floor_index') {
          newAnswerValue = floor
        }

        dispatch(addAnswer(answerId, sectionCode, question.code, newAnswerValue))
      }
    }
  }
}

// Given the section code, the current quantity of sets for this code,
// the amount to decrease this by and the questions for the multiset, dispatch
// removeAnswer actions for each question.
export function removeSet(
  sectionCode,
  leadValue,
  startIndex,
  quantityToRemove,
  multisetQuestions,
  dispatch,
  topLevelIndex
) {

  let questionsForSet = null

  if (topLevelIndex !== undefined) {
    questionsForSet = multisetQuestions[topLevelIndex].sets
  } else if (leadValue !== undefined) {
    questionsForSet = multisetQuestions.sets[leadValue]
  } else {
    questionsForSet = multisetQuestions.sets
  }

  for (let i = startIndex; i > startIndex - quantityToRemove; i--) {
    const setToBeRemoved = questionsForSet[i]

    for (let question of setToBeRemoved) {
      dispatch(removeAnswer(question.id))
    }
  }
}

// Given a section code and the state, generate a multiset answer.
export function generateMultisetAnswer(
  sectionId,
  questionCodes,
  answers,
  isSectionSetArray // TODO: what is this for?
) {

  const multiset = answers[sectionId]
  let answer = []

  for (let id of multiset.multisetAnswers) {
    const parsedId = parseAnswerId(id)
    const multisetAnswer = answers[id]

    // If we are handling a nested multiset, set up the top-level arrays before
    // adding the arrays of question sets.
    if (multiset.isNestedMultiset) {
      answer[parsedId.index] = answer[parsedId.index] || []
      answer[parsedId.index][parsedId.secondIndex] =
        answer[parsedId.index][parsedId.secondIndex] || {}
      answer[parsedId.index][parsedId.secondIndex][parsedId.questionCode] =
        multisetAnswer.value
      // Otherwise, add the answer values to the simple multiset answer sets.
    } else {
      answer[parsedId.index] = answer[parsedId.index] || {}
      answer[parsedId.index][parsedId.questionCode] = multisetAnswer.value
    }
  }

  return answer
}

// Shift other answers' indices down and regenerate their ids.  This is called
// after an answer has been removed from a set.  No shifting will be done if
// there are any answers remaning in the set.
export function shiftSetsDownIfNeeded(answerId, answers) {
  return shiftSetsIfNeeded(answerId, answers, -1)
}

// Shift other answers' indices up and regerate their ids.  This is called
// before any new answers have been added for a set.  No shifting will be done
// if the newly added answer id hasn't been used yet.
export function shiftSetsUpIfNeeded(answerId, answers) {
  return shiftSetsIfNeeded(answerId, answers, 1)
}

// Shift other answers' based on the id for the answer that has been removed
// or will be added.
function shiftSetsIfNeeded(answerId, answers, delta) {
  const parsedId = parseAnswerId(answerId)
  const sectionCode = parsedId.sectionCode
  const multisetAnswers = answers[parsedId.sectionCode].multisetAnswers
  const isNested = answers[parsedId.sectionCode].isNestedMultiset
  let indexKey = parsedId.secondIndex !== null ? 'secondIndex' : 'index'
  let newAnswers = {
    ...answers
  }
  let answersToShift = []
  let shiftTopLevelIndices = isNested && delta === -1 ? true : false

  // If we are adding to an index that doesn't already exist, we don't need
  // to shift at all.
  if (answers[answerId] === undefined && delta === 1) {
    return answers
  }

  for (let id of multisetAnswers) {
    const p = parseAnswerId(id)
    const setIsNotEmpty = p.index === parsedId.index
      && p.secondIndex === parsedId.secondIndex
    const isNestedWithSameTopLevelIndex = isNested && parsedId.index === p.index

    if (delta === -1 && isNestedWithSameTopLevelIndex) {
      shiftTopLevelIndices = false
    }

    // If there are still other answers remaining in the set for the given
    // answer id, there are still answers to be deleted; return the same
    // answers as given.
    if (setIsNotEmpty && delta === -1) {
      return answers
      // If we're not dealing with a nested multiset or this answer has the same
      // top-level index as the given answer, proceed with examining whether
      // the index should be shifted based on its relation to the given answer's
      // index.
    } else if (!isNested || isNestedWithSameTopLevelIndex) {
      let shiftThisAnswer = null
      const thisIndex = p[indexKey]
      const givenIndex = parsedId[indexKey]

      if (delta === -1) shiftThisAnswer = thisIndex > givenIndex
      else if (delta === 1) shiftThisAnswer = thisIndex >= givenIndex

      if (shiftThisAnswer) answersToShift.push(id)
    }
  }

  // Shift the ids of the collected multiset answers.
  ShiftIds(sectionCode, answersToShift, newAnswers, indexKey, delta)

  // If we are shifting 2D indices down and we've removed the last set,
  // shift the remaining top-level indices down too.
  if (shiftTopLevelIndices) {
    answersToShift = []
    indexKey = 'index'

    for (let id of multisetAnswers) {
      const p = parseAnswerId(id)

      if (p.index > parsedId.index) {
        answersToShift.push(id)
      }
    }

    ShiftIds(sectionCode, answersToShift, newAnswers, indexKey, delta)
  }

  return newAnswers
}

// Shift the indices for each id in the given array of answer ids.  The lookup
// map `multisetAnswers` on the overall answer for the multiset needs to be
// updated with the new ids.
function ShiftIds(sectionCode, answersToShift, newAnswers, indexKey, delta) {
  const multisetAnswer = newAnswers[sectionCode]
  let answersToInsert = []
  let newMultisetAnswers = [
    ...multisetAnswer.multisetAnswers
  ]

  for (let id of answersToShift) {
    const p = parseAnswerId(id)
    const newIndex = p[indexKey] + delta
    let indexOfAnswerId = newMultisetAnswers.indexOf(id)
    let newId = id
    let newAnswer = {
      ...newAnswers[id]
    }

    // Remove the answer from the answers map and from the multiset answer
    // lookup map.
    delete newAnswers[id]
    newMultisetAnswers.splice(indexOfAnswerId, 1)

    if (indexKey === 'index') {
      newId = generateAnswerId(
        p.sectionCode,
        newIndex,
        p.questionCode,
        p.secondIndex
      )
    } else if (indexKey === 'secondIndex') {
      newId = generateAnswerId(p.sectionCode, p.index, p.questionCode, newIndex)
    }

    newAnswer.id = newId
    answersToInsert.push(newAnswer)
    newMultisetAnswers.push(newId)
  }

  // Re-insert the answers for the multiset section into the answers map.
  for (let answer of answersToInsert) {
    newAnswers[answer.id] = answer
  }

  // Re-assign the multiset answers lookup map to the multiset answer
  newAnswers[multisetAnswer.id] = {
    ...newAnswers[multisetAnswer.id],
    multisetAnswers: newMultisetAnswers
  }
}
