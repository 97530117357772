// Step navigation bar; builds URLs from given route parameters and uses
// react-router to generate spa links.
// ---------------------------------------------------------------------

import React from 'react'
import AdapterLink from '../../common/lib/adapter-link'
import { connect } from 'react-redux'
import { stepUrl, filterSteps } from '../lib/steps'
import LoadingScreen from '../../common/components/loading-screen'
import { List, ListItem, ListItemIcon, Icon, Typography } from '@mui/material'

const StepNav = ({ auditHash, steps, currentStep, knownUser, isLoaded }) => (
  <LoadingScreen isLoaded={isLoaded}>
    <List>
        {Object.keys(steps).map((stepKey) => {

          const url = stepUrl(auditHash, steps[stepKey].code, knownUser)
          const isCurrentStep = steps[stepKey].code === currentStep
          const iconType = isCurrentStep
            ? 'radio_button_checked'
            : 'radio_button_unchecked'

          return (
            <ListItem button key={stepKey}
              component={AdapterLink}
              to={url}
            >
              <ListItemIcon>
                <Icon>{iconType}</Icon>
              </ListItemIcon>
              <Typography>
                {steps[stepKey].name}
              </Typography>
            </ListItem>
          )
        })}
      </List>
  </LoadingScreen>
)

const mapStateToProps = (state) => {
  // Get a list of step codes for the nav links and URLs.
  const knownUser = !!state.me?.id
  const currentStep = state.currentStep
  const isLoaded = !!(state.processReceived && state.token && state.auditReceived && state.auditParsed)
  const steps = isLoaded ? filterSteps(state, state.processReceived && state.steps) : {}
  return {
    steps,
    currentStep,
    knownUser,
    isLoaded
  }
}

export default connect(mapStateToProps)(StepNav)
